import React from 'react';
import Tabs from '../Tabs';
import i18n from '../../translate';
import { CreditCardForm, CashForm, WiretransferForm } from './components';
import { Button } from '../';
import { COLORS } from '../vars/palette';
import Modal from '../Modal/Modal';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PaymentContext } from './context';
import { PaymentService } from '../../services';
import { Total, Label } from '../styles';
import translate from '../../translate';

const ButtonSideIcon = styled.span`
  background-color: rgb(62, 149, 223);
  flex: 1;
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-size: 22px;
`;

const ButtonText = styled.span`
  margin-right: 5px;
  display: flex;
  flex: 6;
  height: 48px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const PayButton = styled(Button)`
  width: 100%;
  padding: 0;
  margin: 0px;
  border-radius: 0px;
  display: flex;
  flex-direction: row;
  height: 48px;
`;

const getAmount = (details = []) => {
  const amount = details.reduce((acc, item) => acc + parseFloat(item.amount), 0);
  return parseFloat(amount).toFixed(2);
};

class PaymentForm extends React.PureComponent {
  state = {
    paymentMethod: 'CC',
    creditCard: {},
    cashPaymentDetail: {},
    wireTransferPaymentDetail: {},
    isPaying: false
  };

  onTabChanged = tabName => {
    this.setState({
      paymentMethod: tabName,
      creditCard: {},
      cashPaymentDetail: {},
      wireTransferPaymentDetail: {}
    });
  };

  setPaymentMethodDetail = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  pay = async () => {
    const { details = [], contract = undefined, fullName } = this.props;
    const { creditCard, cashPaymentDetail, wireTransferPaymentDetail, paymentMethod } = this.state;
    const amount = getAmount(details);
    let contractInfo = {};

    if (contract) {
      const { contractId } = contract;
      contractInfo = { entityId: contractId, entityType: 'CONTRACT', contract };
    }

    const paymentPayload = {
      ...this.props.additional,
      ...contractInfo,
      fullName,
      amount,
      paymentMethod,
      details,
      creditCard
    };
    await this.setState({ isPaying: true });
    const payment = await PaymentService.pay(paymentPayload);
    await this.setState({ isPaying: false });

    this.props.onPay(payment);
  };

  render() {
    const { isPaying, creditCard } = this.state;
    const { show = false, close, details = [] } = this.props;
    const amount = getAmount(details) || 0;
    return (
      <Modal
        className="modal-sm"
        show={show}
        close={close}
        title={'Make a payment'}
        modalBodyStyles={{ paddingTop: ' 20px', paddingBottom: '25px', backgroundColor: COLORS.PALE_GRAY_TWO }}
      >
        {show && (
          <PaymentContext.Provider
            value={{
              setPaymentMethodDetail: this.setPaymentMethodDetail.bind(this),
              pay: this.pay.bind(this)
            }}
          >
            <div className="col-12 px-0 px-md-3">
              <div>
                <Tabs onTabChanged={this.onTabChanged} contentStyle={{ backgroundColor: COLORS.PALE_GRAY_TWO }}>
                  <Tabs.Item name="CC" title={i18n.t('creditCard')} content={<CreditCardForm />} />
                  {/* <Tabs.Item name="CAS" title={i18n.t('cash')} content={<CashForm />} />
                  <Tabs.Item name="WT" title={i18n.t('wireTransfer')} content={<WiretransferForm />} /> */}
                </Tabs>
              </div>
            </div>

            <div className="col-12 " style={{ paddingRight: ' 20px' }}>
              <Total className="my-3 mx-3">
                <Label>{translate.t('total')}</Label>
                <p style={{ textAlign: 'end' }}>{translate.l('currency', amount)}</p>
              </Total>
            </div>

            <div className="col-12" style={{ marginTop: 10, padding: '0px 45px', textAlign: 'center' }}>
              {!isPaying &&
              amount > 0 &&
              creditCard.cardHolderName &&
              creditCard.cardNumber &&
              creditCard.expirationMonth &&
              creditCard.expirationYear &&
              creditCard.cvc ? (
                <PayButton primary>
                  <ButtonSideIcon>
                    <i className="fa fa-money fa-fw" />
                  </ButtonSideIcon>

                  <ButtonText style={{ marginRight: 5 }} onClick={e => !isPaying && amount > 0 && this.pay(e)}>
                    {i18n.t('pay')}
                  </ButtonText>
                </PayButton>
              ) : (
                <PayButton>
                  <ButtonSideIcon>
                    <i className="fa fa-money fa-fw" />
                  </ButtonSideIcon>

                  <ButtonText style={{ marginRight: 5 }}>{i18n.t('pay')}</ButtonText>
                </PayButton>
              )}
            </div>
          </PaymentContext.Provider>
        )}
      </Modal>
    );
  }
}

/** */

PaymentForm.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  onPay: PropTypes.func,
  contractId: PropTypes.number,
  details: PropTypes.array
};

export default PaymentForm;
