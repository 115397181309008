import React from 'react'
import helpers from '../Helpers'
import {string, array, oneOfType, bool} from 'prop-types'
import styled from 'styled-components'

const ExportButton = styled.a`
  font-family: 'roboto-regular';
  font-size: 16px;
  color: black;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-decoration: none;
`


class CSVLink extends React.Component {

  constructor(props) {
    super(props)
    this.buildURI= this.buildURI.bind(this)
  }

  buildURI() {
    return helpers.buildURI(...arguments)
  }

  handleLegacy(evt, data, headers, separator, filename) {
    if (window.navigator.msSaveOrOpenBlob) {
      evt.preventDefault()

      let blob = new Blob([helpers.toCSV(data, headers, separator)])
      window.navigator.msSaveBlob(blob, filename)

      return false
    }
  }
  render(){
    const {
      data, 
      headers, 
      separator, 
      filename, 
      uFEFF, 
      children , 
      ...rest
    } = this.props
    
    return (
      <ExportButton download={filename} 
        {...rest}              
        ref={link => (this.link = link)}
        href={this.buildURI(data, uFEFF, headers, separator)}
        onClick={evt => this.handleLegacy(evt, data, headers, separator, filename)}>
        {children}
      </ExportButton>
    )
  }
}
CSVLink.propTypes = {
  data: oneOfType([string, array]).isRequired,
  headers: array,
  target: string,
  separator: string,
  filename: string,
  uFEFF: bool
}
  
CSVLink.defaultProps = {
  separator: ',',
  filename: 'report.csv',
  uFEFF: true
}

export default CSVLink
