import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import styled from 'styled-components';
import PersonalInfoForm from './PersonalInfoForm';
import { COLORS } from '../../reusable-components/vars/palette';
import { Button, Select } from '../../reusable-components/';
import AddressForm from './AddressForm';
import PhoneForm from './PhoneForm';
import EmailForm from './EmailForm';
import BeneficiaryForm from './BeneficiaryForm';
import { PersonService, InvitationService } from '../../services';
import { Subtitle, BiColorContainer } from '../../reusable-components/styles';
import { withLoader } from '../../reusable-components';

const PersonalInfoContainer = styled.div`
  width: 100%;
  & > div {
    margin: 8px;
  }
`;

const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  color: #ef5350;
  margin: 5px;
`;

const RemoveButton = styled.i`
  cursor: pointer;
`;

const personHasData = person => {
  return (
    person.firstName ||
    person.secondName ||
    person.lastName ||
    person.nationalityId ||
    person.occupationId ||
    person.livingCountryId ||
    person.identificationTypeId ||
    person.identificationNumber ||
    person.preferredLanguageId ||
    person.birthdate ||
    person.civilStatusId ||
    person.anniversaryDate ||
    person.addresses.some(a => addressHasData(a)) ||
    person.emails.some(e => emailHasData(e)) ||
    person.phones.some(p => phoneHasData(p))
  );
};
const addressHasData = address => {
  return (
    address.addressTypeId || address.street1 || address.city || address.state || address.countryId || address.zipCode
  );
};

const emailHasData = email => {
  return email.emailTypeId || email.value;
};

const phoneHasData = phone => {
  return phone.contactPhoneTypeId || phone.number;
};

const setIdentification = person => {
  if (person && person.identifications && person.identifications.length) {
    const id = person.identifications[0];
    person.identificationNumber = id.value;
    person.identificationTypeId = id.identificationTypeId;
  }
  return person;
};

class MembersForm extends Component {
  state = {
    addressTypes: [],
    countries: [],
    emailTypes: [],
    occupations: [],
    civilStatuses: [],
    identificationTypes: [],
    languages: [],
    relationshipTypes: [],
    phoneTypes: []
  };

  async componentDidMount() {
    const addressTypes = await PersonService.getAddressTypes();
    const countries = await PersonService.getCountries();
    const emailTypes = await PersonService.getEmailTypes();
    const occupations = await PersonService.getOccupations();
    const civilStatuses = await PersonService.getCivilStatuses();
    const identificationTypes = await PersonService.getIdentificationTypes();
    const languages = await PersonService.getLanguages();
    const relationshipTypes = await PersonService.getRelationshipTypes();
    const phoneTypes = await PersonService.getPhoneTypes();
    this.setState({
      addressTypes,
      countries,
      emailTypes,
      occupations,
      civilStatuses,
      identificationTypes,
      languages,
      relationshipTypes,
      phoneTypes
    });
  }

  getProspects = async (criteria, callback) => {
    try {
      if (criteria.length < 2) {
        callback([]);
        return;
      }
      const { people } = this.props.context.contract;
      let prospects = await InvitationService.prospectsByRoom(criteria);
      prospects = prospects.reduce((acc, prospect) => {
        if (!people.some(person => person.personId === prospect.personId)) {
          prospect.person = setIdentification(prospect.person);
          prospect.couple = setIdentification(prospect.couple);
          acc.push(prospect);
        }
        return acc;
      }, []);
      callback(prospects);
    } catch (e) {
      callback([]);
    }
  };
  setDefaultInfo = person => {
    return {
      ...person,
      addresses: person.addresses && person.addresses.length > 0 ? person.addresses : [{}],
      emails: person.emails && person.emails.length > 0 ? person.emails : [{}],
      phones: person.phones && person.phones.length > 0 ? person.phones : [{}]
    };
  };
  selectProspect = (value, ref) => {
    if (!value.personId) return;
    const people = this.props.context.contract.people;
    if (people && people.length === 1 && !personHasData(people[0])) {
      people.pop();
    }
    people.push(this.setDefaultInfo(value.person));
    if (value.couple) {
      people.push(this.setDefaultInfo(value.couple));
    }
    this.props.context.handlePropertyChange('people', people);
  };
  promoteToOwner = (selectedIndex, person) => {
    let people = this.props.context.contract.people;
    people = people.filter((e, index) => index !== selectedIndex);
    people.unshift(person);
    this.props.context.handlePropertyChange('people', people);
  };

  render() {
    const { context } = this.props;
    const {
      addressTypes,
      countries,
      emailTypes,
      occupations,
      civilStatuses,
      identificationTypes,
      languages,
      relationshipTypes,
      phoneTypes
    } = this.state;
    const contract = context.contract;
    const { people, beneficiaries } = contract;

    return (
      <Fragment>
        <Select
          placeholder={translate.t('searchWelcomeByRoom')}
          getOptionValue={p => p.prospectId}
          getOptionLabel={p =>
            `${p.roomNumber ? p.roomNumber + ' -' : ''} ${(p.person || {}).firstName || ''} ${(p.person || {})
              .lastName || ''} ${
              p.contracts && p.contracts.length ? '(' + p.contracts.map(c => c.number).join(', ') + ')' : ''
            }`
          }
          value=""
          isClearable={true}
          onChange={this.selectProspect}
          loadOptions={this.getProspects}
        />
        <BiColorContainer>
          {people.map((p, index) => {
            return (
              <div
                key={index}
                style={{
                  border: `1px solid ${COLORS.PALE_GRAY}`,
                  padding: '10px',
                  margin: '15px 0px'
                }}
              >
                <div
                  style={{
                    fontFamily: 'roboto-regular',
                    fontSize: 23,
                    margin: 10
                  }}
                >
                  {index === 0 ? translate.t('owner') : translate.t('coOwner')}
                  {index > 0 && (
                    <Button onClick={e => this.promoteToOwner(index, p)}>{translate.t('promoteToOwner')}</Button>
                  )}
                </div>

                <div style={{ display: 'flex' }}>
                  <PersonalInfoContainer>
                    <PersonalInfoForm
                      key={p.personId || index}
                      person={p}
                      addMember={this.addMember}
                      index={index}
                      countries={countries}
                      occupations={occupations}
                      civilStatuses={civilStatuses}
                      identificationTypes={identificationTypes}
                      languages={languages}
                      context={context}
                    />
                  </PersonalInfoContainer>
                  <RemoveButtonContainer>
                    {people.length > 1 && (
                      <RemoveButton
                        className="fa fa-trash"
                        style={{ cursor: 'pointer' }}
                        onClick={() => context.removeItem('people', index)}
                        type="button"
                      />
                    )}
                  </RemoveButtonContainer>
                </div>
                <div style={{ padding: '20px 0px 10px 10px' }}>
                  <Subtitle>
                    <div>
                      <i className="fa fa-angle-right" />
                      {` ${translate.t('addresses')}`}
                    </div>
                  </Subtitle>
                  <BiColorContainer>
                    {(p.addresses || []).map((a, addressIndex) => {
                      return (
                        <div className="row pt-2 ml-0" key={addressIndex}>
                          <div className={`px-0 ${p.addresses.length > 1 ? 'col-11' : 'col-12'}`}>
                            <AddressForm
                              parentIndex={index}
                              address={a}
                              index={addressIndex}
                              addressTypes={addressTypes}
                              countries={countries}
                              context={context}
                            />
                          </div>
                          {p.addresses.length > 1 && (
                            <div className="px-0 col-1 d-flex justify-content-center">
                              <RemoveButtonContainer>
                                <RemoveButton
                                  className="fa fa-trash"
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => context.removeChildItem('people', index, 'addresses', addressIndex)}
                                  type="button"
                                />
                              </RemoveButtonContainer>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </BiColorContainer>

                  <Button
                    style={{ opacity: 0.5 }}
                    backgroundColor={COLORS.MINT_GREEN}
                    textColor={COLORS.CHARCOAL_GRAY}
                    type="button"
                    onClick={() => context.addChildItem('people', index, 'addresses')}
                  >
                    {`${translate.t('add')} ${translate.t('address')}`}
                  </Button>
                </div>
                <div className="row no-gutters px-2">
                  <div className="col-md-6">
                    <Subtitle>
                      <div>
                        <i className="fa fa-angle-right" />
                        {` ${translate.t('phones')}`}
                      </div>
                    </Subtitle>
                    <BiColorContainer>
                      {(p.phones || []).map((phone, phoneIndex) => {
                        return (
                          <div key={phoneIndex} className="row pt-2 ml-0">
                            <div className={`px-0 ${p.phones.length > 1 ? 'col-11' : 'col-12'}`}>
                              <PhoneForm
                                parentIndex={index}
                                phone={phone}
                                index={phoneIndex}
                                phoneTypes={phoneTypes}
                                context={context}
                              />
                            </div>
                            {p.phones.length > 1 && (
                              <div className="px-0 col-1 d-flex justify-content-center">
                                <RemoveButtonContainer>
                                  <RemoveButton
                                    className="fa fa-trash"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => context.removeChildItem('people', index, 'phones', phoneIndex)}
                                    type="button"
                                  />
                                </RemoveButtonContainer>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </BiColorContainer>
                    <Button
                      style={{ opacity: 0.5 }}
                      backgroundColor={COLORS.MINT_GREEN}
                      textColor={COLORS.CHARCOAL_GRAY}
                      type="button"
                      onClick={() => context.addChildItem('people', index, 'phones')}
                    >
                      {`${translate.t('add')} ${translate.t('phone')}`}
                    </Button>
                  </div>
                  <div className="col-md-6">
                    <Subtitle>
                      <div>
                        <i className="fa fa-angle-right" />
                        {` ${translate.t('emails')}`}
                      </div>
                    </Subtitle>
                    <BiColorContainer>
                      {(p.emails || []).map((email, emailIndex) => {
                        return (
                          <div key={emailIndex} className="row pt-2 ml-0">
                            <div className={`px-0 ${p.emails.length > 1 ? 'col-11' : 'col-12'}`}>
                              <EmailForm
                                parentIndex={index}
                                email={email}
                                index={emailIndex}
                                emailTypes={emailTypes}
                                context={context}
                              />
                            </div>
                            {p.emails.length > 1 && (
                              <div className="px-0 col-1 d-flex justify-content-center">
                                <RemoveButtonContainer>
                                  <RemoveButton
                                    className="fa fa-trash"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => context.removeChildItem('people', index, 'emails', emailIndex)}
                                    type="button"
                                  />
                                </RemoveButtonContainer>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </BiColorContainer>
                    <Button
                      style={{ opacity: 0.5 }}
                      backgroundColor={COLORS.MINT_GREEN}
                      textColor={COLORS.CHARCOAL_GRAY}
                      type="button"
                      onClick={() => context.addChildItem('people', index, 'emails')}
                    >
                      {`${translate.t('add')} ${translate.t('email')}`}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </BiColorContainer>
        <Button
          style={{ opacity: 0.5, marginLeft: 0 }}
          backgroundColor={COLORS.MINT_GREEN}
          textColor={COLORS.CHARCOAL_GRAY}
          type="button"
          onClick={() => context.addItem('people')}
        >
          {`${translate.t('add')} ${translate.t('member')}`}
        </Button>
        <Subtitle>
          <div>
            <i className="fa fa-angle-right" />
            {` ${translate.t('beneficiaries')}`}
          </div>
        </Subtitle>
        <BiColorContainer className="mb-3">
          {beneficiaries.map((b, index) => {
            return (
              <div key={index} className="row pt-2 ml-0">
                <div className="col-11">
                  <BeneficiaryForm
                    beneficiary={b}
                    index={index}
                    relationshipTypes={relationshipTypes}
                    context={context}
                  />
                </div>
                <div className="px-0 col-1 d-flex justify-content-center">
                  <RemoveButtonContainer>
                    <RemoveButton
                      className="fa fa-trash"
                      style={{ cursor: 'pointer' }}
                      onClick={() => context.removeItem('beneficiaries', index)}
                      type="button"
                    />
                  </RemoveButtonContainer>
                </div>
              </div>
            );
          })}
        </BiColorContainer>
        <Button
          style={{ opacity: 0.5, marginBottom: '20px' }}
          backgroundColor={COLORS.MINT_GREEN}
          textColor={COLORS.CHARCOAL_GRAY}
          type="button"
          onClick={() => context.addItem('beneficiaries')}
        >
          {`${translate.t('add')} ${translate.t('beneficiaries')}`}
        </Button>
      </Fragment>
    );
  }
}
export default withLoader(MembersForm);
