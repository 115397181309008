import React, { Component } from 'react';
import styled from 'styled-components';
import shortid from 'shortid';
import { TextInput, Button, Dropdown, NumberInput, Table } from '../../reusable-components';
import { PaymentForm } from '../../reusable-components/payments';
import { COLORS } from '../../reusable-components/vars/palette';
import { CatalogService } from '../../services';
import { Subtitle } from '../../reusable-components/styles';
import Toast from '../../reusable-components/common/Toast';
import translate from '../../translate';

const Control = styled.div`
  margin-top: 5px;
  display: ${props => (props.hide ? 'none' : 'block')};
`;

const RemoveButton = styled.i`
  cursor: pointer;
  color: red;
`;

const tableFields = [
  {
    header: translate.t('concept'),
    column: 'productName',
    size: '40%'
  },
  {
    header: translate.t('description'),
    column: 'description',
    size: '20%'
  },
  {
    header: translate.t('amount'),
    column: 'amount',
    size: '20%',
    type: 'money'
  }
];

class WelcomePayment extends Component {
  state = {
    fullName: '',
    clientTypeId: 0,
    identificationNumber: '',
    productTypeId: 0,
    amount: 1,
    details: [],
    description: '',
    clientTypes: [],
    productTypes: [],
    open: false
  };

  close = () => {
    this.setState({ open: false });
  };

  onPay = newPayment => {
    if (newPayment.id) {
      this.setState({
        fullName: '',
        clientTypeId: this.state.clientTypes[0].id,
        identificationNumber: '',
        productTypeId: this.state.productTypes[0].id,
        amount: 1,
        details: [],
        description: '',
        open: false
      });
      Toast.success(
        `${translate.t('authorizedTransaction')} - ${translate.t('confirmationNumber')}: ${
          newPayment.authorization
        } - ${translate.t('referenceNumber')}: ${newPayment.reference}`,
        { autoClose: false, position: Toast.POSITION.BOTTOM_RIGHT }
      );
    } else {
      Toast.error(`${translate.t('declinedTransaction')} - ${translate.t(newPayment.errorCode)}`, {
        position: Toast.POSITION.BOTTOM_RIGHT
      });
    }
  };

  handleChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  addPaymentDetail = () => {
    const { productTypeId, amount, description, productTypes } = this.state;
    if (!productTypeId) {
      alert('Select concept type');
      return;
    }
    if (!amount) {
      alert('An amount is required');
      return;
    }
    let details = [...this.state.details];
    details.push({
      ...this.state.details,
      ...{
        id: shortid.generate(),
        productTypeId,
        amount,
        description,
        productName: this.state.productTypes.find(pt => pt.id === productTypeId).name
      }
    });
    this.setState({ details, amount: 0, description: '', productTypeId: productTypes[0].id });
  };

  removeItem = id => {
    let details = [...this.state.details];
    const detail = details.find(pd => pd.id === id);
    const index = details.indexOf(detail);
    details.splice(index, 1);
    this.setState({ details });
  };

  pay = () => {
    this.setState({ open: true });
  };

  renderDeleteColum = () => {
    return {
      header: '',
      column: item => <RemoveButton className="fa fa-trash" type="button" onClick={() => this.removeItem(item.id)} />,
      size: '20%'
    };
  };
  setPaymentType = paymentDetails => {
    let details = [...paymentDetails];
    let { paymentTypes } = this.state;
    for (let paymetDetail of details) {
      paymetDetail.paymentType = paymentTypes.find(pt => pt.paymentTypeId === paymetDetail.paymentTypeId);
    }
    return details;
  };

  async componentDidMount() {
    const clientTypes = await CatalogService.getByType('CLIENT_TYPE');
    const productTypes = await CatalogService.getByType('PRODUCT_TYPE');

    this.setState({
      clientTypes,
      productTypes,
      clientTypeId: clientTypes[0].id,
      productTypeId: productTypes[0].id
    });
  }

  isCompleted = paymentDetail => {
    return true;
  };

  render() {
    let {
      productTypes,
      clientTypes,
      clientTypeId,
      fullName,
      identificationNumber,
      productTypeId,
      amount,
      description,
      details
    } = this.state;
    let fields = [...tableFields];
    fields.push(this.renderDeleteColum());

    return (
      <div className="container mt-5">
        <div className="col-md-10">
          <Subtitle> {translate.t('welcomePayment')}</Subtitle>
        </div>

        <div className="row mt-3">
          <div className="col-md-4 col-sm-10">
            <Control>
              <Dropdown
                addSelectElement={false}
                label={translate.t('type')}
                items={clientTypes}
                optionValue="id"
                optionText="name"
                name="clientTypeId"
                value={clientTypeId || (clientTypes[0] || {}).id}
                onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
              />
            </Control>
          </div>
          <div className="col-md-4 col-sm-10">
            <TextInput
              name="fullName"
              label={translate.t('fullName')}
              placeholder={translate.t('fullName')}
              value={fullName}
              onChange={e => this.setState({ [e.target.name]: e.target.value })}
            />
          </div>
          <div className="col-md-4 col-sm-10">
            <Control>
              <TextInput
                name="identificationNumber"
                label={translate.t('idNo')}
                placeholder={translate.t('idNo')}
                value={identificationNumber}
                onChange={e => this.setState({ [e.target.name]: e.target.value })}
              />
            </Control>
          </div>
        </div>
        <Subtitle> </Subtitle>
        <div className="row mt-3">
          <div className="col-md-3 col-sm-10">
            <Control>
              <Dropdown
                addSelectElement={false}
                label={translate.t('conceptType')}
                items={productTypes}
                optionValue="id"
                optionText="name"
                name="productTypeId"
                value={productTypeId || (productTypes[0] || {}).id}
                onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
              />
            </Control>
          </div>

          <div className="col-md-2 col-sm-10">
            <Control>
              <NumberInput
                name="amount"
                min="1"
                max="999999999"
                label={translate.t('amount')}
                value={amount}
                onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
              />
            </Control>
          </div>
          <div className="col-md-6 col-sm-10">
            <Control>
              <TextInput
                name="description"
                label={translate.t('description')}
                placeholder={translate.t('description')}
                value={description}
                onChange={e => this.setState({ [e.target.name]: e.target.value })}
              />
            </Control>
          </div>
          <div className="col-md-1">
            <Control>
              <Button
                backgroundColor={COLORS.MINT_GREEN}
                textColor="black"
                type="button"
                onClick={this.addPaymentDetail}
              >
                {translate.t('add')}
              </Button>
            </Control>
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-12">
            <Table
              tableFields={fields}
              data={details}
              totalItems={details.length}
              removeItem={this.removeItem}
              itemsPerPage={20}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            {/* <Button primary="true" disabled={!paymentDetails.length} onClick={this.pay}> */}
            {clientTypeId && fullName && identificationNumber && details.length > 0 ? (
              <Button primary onClick={this.pay}>{translate.t('pay')}</Button>
            ) : (
              <Button>{translate.t('pay')}</Button>
            )}
          </div>
        </div>

        <PaymentForm
          fullName={this.state.fullName}
          additional={{
            clientTypeId: this.state.clientTypeId,
            identificationNumber: this.state.identificationNumber
          }}
          show={this.state.open}
          close={this.close}
          onPay={this.onPay}
          details={this.state.details}
        />
      </div>
    );
  }
}

export default WelcomePayment;
