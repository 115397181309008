import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class Toast extends React.Component {
  
  static error(msg, opts) {
    toast.error(msg, opts);
  }

  static success(msg, opts) {
    toast.success(msg, opts);
  }
}

Object.defineProperty(Toast, 'POSITION', {
  value: toast.POSITION,
  writable: false,
  enumerable: true,
  configurable: false
});

export default Toast;
