import ColorRequest from './colorRequest';

class UserService {
  static getLoggedUser() {
    return ColorRequest.getLoggedUser();
  }

  static setLoggedUser(user) {
    localStorage.setItem('loggedUser', JSON.stringify(user));
  }

  static isAllowed(feature) {
    const user = this.getLoggedUser();
    // return user && user.scope.includes(feature);
    return user;
  }

  static async isAuthenticated() {
    try {
      const { data: resp } = await ColorRequest.get('/users/token/validate');
      return resp.isValid;
    } catch (e) {
      return false;
    }
  }

  static async authenticate(username, password) {
    try {
      const body = { username, password };

      const { data } = await ColorRequest.post('/users/auth', body);

      if (data.authenticated) {
        this.setLoggedUser(data.user);
      }
      return data;
    } catch (e) {
      return { authenticated: false, message: 'Invalid credentials' };
    }
  }

  static async unauthenticate() {
    try {
      await ColorRequest.patch('/users/unauth', {});
    } catch (e) {}
    this.loggedUser = null;
    localStorage.removeItem('loggedUser');
  }
}

export default UserService;
