import styled from 'styled-components'
import { COLORS } from '../../../vars/palette'

const InputLabel = styled.label`
  font-family: 'exo-light';
  font-size: 11px;
  font-style: normal;
  font-stretch: normal;
  line-height: 15px;
  letter-spacing: 0.02em;
  color: ${COLORS.INPUT_LABEL};
`

export default InputLabel