import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { COLORS } from '../../reusable-components/vars/palette';
import translate from '../../translate';
import FinancingView from './FinancingView';
import { Table } from '../../reusable-components';
import { SpanLink } from '../../reusable-components/styles';

const Container = styled.div`
  background-color: #edf2f6;
  margin: 25px;

  & h2 {
    font-family: 'exo-semibold';
    color: #394a51;
    font-size: 24px;
  }

  th > span {
    font-family: 'exo-regular';
  }

  & th > span {
    font-size: 14px;
  }

  & th {
    padding: 10px 0;
  }

  & thead > tr {
    height: auto;
  }

  & td {
    color: black;
  }
`;

const ContractTitle = styled.div`
  & > div {
    flex: 1;
  }
  font-family: 'exo-extralight';
  font-size: 36px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.HEADER_COLOR};
  display: flex;
`;

const ContractNumber = styled.div`
  font-family: 'exo-semibold';
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #394a51;

  &.smaller {
    font-size: 24px;
  }

  @media (max-width: 767.98px) {
    font-size: 24px;

    &.smaller {
      font-size: 16px;
    }
  }
`;

const CloseButton = styled.span`
  user-select: none;
  font-size: 22px;
  text-align: end;
  cursor: pointer;
`;

const OwnerDetails = styled.div`
  font-family: 'exo-regular';
  margin-top: 30px;
`;

const OwnerProp = styled.div`
  font-size: 20px;
  color: #394a51;
  margin: 2px;

  @media (max-width: 767.98px) {
    font-size: 18px;
  }
`;
const OwnerPropValue = styled.div`
  font-size: 14px;
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  margin: 2px;
`;

const ContactDetail = styled.div`
  background-color: #ffffff;
  font-family: 'exo-regular';
  margin-top: 24px;
  padding: 15px;
`;
const ContactType = styled.div`
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  font-size: 13px;
  padding-top: 5px;
`;

const ContactTypeTitle = styled(ContactType)`
  border-bottom: 1px solid ${COLORS.PALE_GRAY};
  margin-bottom: 10px;
  padding: 5px 0;
`;

const PriceInfo = styled.div`
  font-family: 'exo-regular';
  font-size: 15px;
  color: black;
  margin-top: 15px;
`;

const PriceTitle = styled.div`
  font-family: 'exo-regular';
  font-size: 12px;
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  margin-top: 5px;
`;
const ProductInfo = styled.div`
  font-family: 'exo-regular';
  font-size: 20px;

  @media (max-width: 767.98px) {
    font-size: 18px;
  }
`;
const ProductTitle = styled.div`
  font-family: 'exo-regular';
  font-size: 14px;
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  margin-top: 5px;
`;

const Volume = styled.div`
  font-weight: 600;
  font-size: 36px;
  color: #394a51;
  font-family: 'exo-semibold';
  padding: 15px 0px;

  @media (max-width: 767.98px) {
    font-size: 24px;
  }
`;

const EMPTY = '-----';

const certificatesTableFields = [
  { header: translate.t('folioNumber'), column: 'folioNumber', size: '20%' },
  { header: translate.t('serialNumber'), column: 'serialCode', size: '45%' },
  { header: translate.t('vigency'), column: 'months', size: '35%' }
];

const promissoryNotesTableFields = [
  { header: translate.t('dueDate'), column: 'dueDate', size: '40%' },
  { header: translate.t('amount'), column: 'amount', size: '35%', type: 'money' },
  { header: translate.t('type'), column: 'type', size: '35%' }
];

class ContractQuickView extends Component {
  render() {
    const { onClose, contract, onShowContractDetail } = this.props;
    const { owner = {}, promissoryNotes = [], certificates = [] } = contract;
    const nationalityCountry = owner.country || {};
    const livingCountry = owner.livingCountry || {};
    const phones = owner.phones || [];
    const emails = owner.emails || [];
    const contractChanges = contract.contractChanges;
    const change = contractChanges && contractChanges.length && contractChanges[0];
    const product = change
      ? change.contractProducts && change.contractProducts.length && change.contractProducts[0]
      : {};
    const price = change ? change.contractPrices && change.contractPrices.length && change.contractPrices[0] : {};
    const roomType = product.roomType || {};
    const membershipType = product.membershipType || {};
    const productType = membershipType.product || {};
    return (
      <Fragment>
        <Container>
          <ContractTitle>
            <div>{translate.t('contract')}</div>
            <CloseButton onClick={onClose}>X</CloseButton>
          </ContractTitle>
          <ContractNumber>
            {contract.number + (contract.serial ? '-' + contract.serial : '')} |
            {` ${translate.l('dateFormats.date', moment(contract.creationDate).toDate())}`}
          </ContractNumber>
          <ContractNumber className="smaller"> {contract.status.name}</ContractNumber>
          <SpanLink className="d-none d-md-block mt-2" onClick={() => onShowContractDetail(contract)}>
            {translate.t('goToDetail')}
          </SpanLink>
          <OwnerDetails className="row">
            <div className="col-6">
              <OwnerProp>{(owner.firstName || ' ').trim() + ' ' + (owner.lastName || ' ').trim()}</OwnerProp>
              <OwnerPropValue>{translate.t('member')}</OwnerPropValue>
            </div>
            <div className="col-6">
              <OwnerProp>
                {(owner.birthdate && translate.l('dateFormats.date', moment(owner.birthdate).toDate())) || EMPTY}
              </OwnerProp>
              <OwnerPropValue>{translate.t('birthDay')}</OwnerPropValue>
            </div>
            <div className="col-6" style={{ marginTop: '20px' }}>
              <OwnerProp>{nationalityCountry.nationality || nationalityCountry.name || EMPTY}</OwnerProp>
              <OwnerPropValue>{translate.t('nationality')}</OwnerPropValue>
            </div>
            <div className="col-6" style={{ marginTop: '20px' }}>
              <OwnerProp>{livingCountry.name || EMPTY}</OwnerProp>
              <OwnerPropValue>{translate.t('resident')}</OwnerPropValue>
            </div>
          </OwnerDetails>

          <ContactDetail>
            <div style={{ border: `1px #${COLORS.PALE_GRAY} solid`, padding: '15px' }}>
              <ContactTypeTitle>{translate.t('emails')}</ContactTypeTitle>
              <div className="row">
                {emails.map(e => {
                  return (
                    <div key={e.emailId} className="col-auto mb-3">
                      <div style={{ fontFamily: 'exo-italic' }}>{e.value}</div>
                      <ContactType>{e && e.emailType && e.emailType.name}</ContactType>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                border: '1px #dee7ee solid',
                padding: '15px'
              }}
            >
              <ContactTypeTitle>{translate.t('phones')}</ContactTypeTitle>
              <div className="row">
                {phones.map(p => {
                  return (
                    <div key={p.contactPhoneId} className="col-auto mb-3">
                      <div styled={{ color: '#394a51', fontFamily: 'exo-italic' }}>{p.number}</div>
                      <ContactType>{p.contactPhoneType && p.contactPhoneType.name}</ContactType>
                    </div>
                  );
                })}
              </div>
            </div>
          </ContactDetail>
        </Container>

        <div
          style={{
            backgroundColor: COLORS.PALE_GRAY
          }}
          className="row mx-2"
        >
          <div className="col-auto my-3">
            <ProductInfo>{productType.name || EMPTY}</ProductInfo>
            <ProductTitle>{translate.t('type')}</ProductTitle>
          </div>
          <div className="col-auto my-3">
            <ProductInfo>{membershipType.name || EMPTY}</ProductInfo>
            <ProductTitle>{translate.t('season')}</ProductTitle>
          </div>
          <div className="col-auto my-3">
            <ProductInfo>{roomType.name || EMPTY}</ProductInfo>
            <ProductTitle>{translate.t('unity')}</ProductTitle>
          </div>
          <div className="col-auto my-3">
            <ProductInfo>{product.preferredRates || EMPTY}</ProductInfo>
            <ProductTitle>{translate.t('weekP')}</ProductTitle>
          </div>
          <div className="col-auto my-3">
            <ProductInfo>{product.membershipWeeks || EMPTY}</ProductInfo>
            <ProductTitle>{translate.t('nightsG')}</ProductTitle>
          </div>
        </div>
        <Container>
          <Volume>
            {translate.t('price')} - {price.volume || EMPTY}
          </Volume>
          <div className="row">
            <div className="col-6">
              <PriceInfo>{price.financedAmount || EMPTY}</PriceInfo>
              <PriceTitle>{translate.t('financingAmount')}</PriceTitle>
            </div>
            <div className="col-6">
              <PriceInfo>{price.paidToday || EMPTY}</PriceInfo>
              <PriceTitle>
                {translate.t('totalPay')} ({((price.paidToday / price.volume) * 100).toFixed(2)} %)
              </PriceTitle>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col">
              <FinancingView contract={contract} />
            </div>
          </div>
          {promissoryNotes &&
            promissoryNotes.length > 0 && (
              <div className="row mb-2">
                <div className="col">
                  <h2 className="mb-3">{translate.t('adp')}</h2>
                  <Table
                    tableFields={promissoryNotesTableFields}
                    data={promissoryNotes}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            )}
          {certificates &&
            certificates.length > 0 && (
              <div className="row">
                <div className="col">
                  <h2 className="mb-3">{translate.t('certificates')}</h2>
                  <Table
                    tableFields={certificatesTableFields}
                    data={certificates}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            )}
        </Container>
      </Fragment>
    );
  }
}

export default ContractQuickView;
