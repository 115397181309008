import React from 'react';
import translate from '../../../translate';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';
import { Button } from '../../common/Buttons';

const StepsContainer = styled.div`
  display: flex;
  margin-bottom: 15px;

  & > div:not(:last-child) {
    margin-right: -15px;

    @media (max-width: 575.98px) {
      margin-right: -25px;
    }
  }
`;

const Step = styled.div`
  &.div {
    background-color: ${props => props.backgroundcolor};
  }
  cursor: pointer;
  height: 56px;
  display: flex;
  font-size: 24px;
`;

const StepText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundcolor};
  font-family: 'exo-light';
  width: 100%;
`;

const StepDecorationStart = styled.div`
  width: 0px;
  height: 0px;
  border-top: 28px solid ${props => props.backgroundcolor};
  border-left: 28px solid transparent;
  border-bottom: 28px solid ${props => props.backgroundcolor};
  // border-right: 5px solid ${props => props.backgroundcolor};
`;

const StepDecorationEnd = styled.div`
  width: 0px;
  height: 0px;
  border-top: 28px solid transparent;
  border-left: 28px solid ${props => props.backgroundcolor};
  border-bottom: 28px solid transparent;
`;

const StepNumber = styled.div`
  border: solid 3px ${props => props.backgroundcolor};
  border-radius: 50%;
  font-size: 15px;
  font-family: 'exo-regular';
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;

  &.completed {
    color: white;
    line-height: 16px;
  }
`;

class WizardHeader extends React.Component {
  selectStep = stepIndex => {
    const { steps } = this.props;
    if (stepIndex === steps.length) {
      return;
    }
    this.props.selectStep(stepIndex);
  };

  render() {
    const { steps, selectedIndex } = this.props;
    const shownSteps = steps.filter(s => !s.hidden);
    const isLastStepCurrent = selectedIndex === shownSteps.length - 1;
    return (
      <StepsContainer className="row no-gutters justify-content-center">
        {shownSteps.map((step, index) => {
          const isActive = selectedIndex === index;
          const bc = isActive ? '#e1e3e4' : COLORS.PALE_GRAY_TWO;
          const completedBackground = index < selectedIndex ? COLORS.MINT_GREEN : undefined;
          const isLastStep = index === shownSteps.length - 1;
          return (
            <Step key={index} onClick={() => this.selectStep(index)} className="col-auto mb-2">
              {index !== 0 && <StepDecorationStart backgroundcolor={completedBackground || bc} />}
              <StepText backgroundcolor={completedBackground || bc} style={{ paddingLeft: '5px' }}>
                <span className="d-none d-sm-inline-block" style={{ maxWidth: '100px' }}>
                  {step.title}
                </span>
                {completedBackground && (
                  <div
                    style={{
                      marginLeft: 5,
                      color: 'white'
                    }}
                    className="fa fa-check completed"
                    backgroundcolor={completedBackground}
                  />
                )}

                {!completedBackground && (
                  <StepNumber className="mr-2" backgroundcolor={isActive ? COLORS.AQUA_BLUE : COLORS.PALE_GRAY}>
                    {index + 1}
                  </StepNumber>
                )}
              </StepText>
              {(!isLastStepCurrent || !isLastStep) && <StepDecorationEnd backgroundcolor={completedBackground || bc} />}
            </Step>
          );
        })}
        {!isLastStepCurrent && (
          <Step className="col-auto mb-1">
            <StepDecorationStart
              backgroundcolor={shownSteps.length === selectedIndex ? COLORS.MINT_GREEN : COLORS.PALE_GRAY_TWO}
            />
            <StepText
              backgroundcolor={shownSteps.length === selectedIndex ? COLORS.MINT_GREEN : COLORS.PALE_GRAY_TWO}
              style={{ paddingRight: '15px' }}
            >
              {selectedIndex < shownSteps.length && (
                <div>
                  <StepNumber
                    className="d-xl-none"
                    backgroundcolor={COLORS.AQUA_BLUE}
                    onClick={() => this.selectStep(selectedIndex + 1)}
                  >
                    <i className="fa fa-chevron-right pl-1" />
                  </StepNumber>
                  <Button
                    className="d-none d-xl-inline-block"
                    backgroundColor={COLORS.MINT_GREEN}
                    textColor="black"
                    type="button"
                    onClick={() => this.selectStep(selectedIndex + 1)}
                  >
                    {translate.t('next')}
                  </Button>
                </div>
              )}
            </StepText>
          </Step>
        )}
      </StepsContainer>
    );
  }
}

export default WizardHeader;
