import React, { Component } from 'react';
import MembersForm from '../components/MembersForm';
import ProducPriceForm from '../components/ProductPriceForm';
import SpecialProvision from '../components/SpecialProvision';
import { Wizard, Toast } from '../../reusable-components';
import shortid from 'shortid';
import { ContractService } from '../../services';
import translate from '../../translate';
import moment from 'moment';
import ContractDetailView from '../components/ContractDetailView';
import ConfirmationCheck from '../components/ConfirmationCheck';
import { withLoader } from '../../reusable-components';
import Verify from '../components/Verify';

const ContractFormContext = React.createContext();
const MEMBER_STEP = 0;
const PRODUCT_PRICE_STEP = 1;
const SPECIAL_PROVISIONS_STEP = 2;
const STEPS = [
  { title: translate.t('members'), content: MembersForm },
  {
    title: `${translate.t('product')} & ${translate.t('price')}`,
    content: ProducPriceForm
  },
  { title: translate.t('specialProvision'), content: SpecialProvision },
  { title: translate.t('review'), content: ContractDetailView },
  { title: translate.t('verify'), content: Verify, hidden: true },

  { title: '', content: ConfirmationCheck, hidden: true }
];
const CALCULABLE_KEYS = ['closingCost', 'membershipPrice', 'requiredDPPercentage', 'requiredDownpayment', 'payments'];
class ContractForm extends Component {
  submitButton;
  stepForm;
  constructor(props) {
    super(props);
    this.state = {
      contractId: props.match.params.contractId,
      contract: {
        people: [{ addresses: [{}], emails: [{}], phones: [{}] }],
        beneficiaries: []
      }
    };
  }

  async componentDidMount() {
    const { contractId } = this.state;
    if (!contractId) {
      return;
    }
    const isUpdatable = await ContractService.isUpdatable(contractId);

    if (!isUpdatable) {
      return this.props.history.push(`/contracts/${contractId}`);
    }

    let contract = await ContractService.getById(contractId);

    contract.people.map(person => {
      if (person.identifications && person.identifications.length) {
        const id = person.identifications[0];
        person.identificationNumber = id.value;
        person.identificationTypeId = id.identificationTypeId;
      }
      return person;
    });

    const { contractChanges = [], promissoryNotes, contractLoans = [] } = contract;
    const { contractProducts = [], contractPrices = [] } = contractChanges[0] || {};

    const {
      membershipType,
      roomTypeId,
      contractSeasonId,
      membershipTypeId,
      membershipWeeks,
      preferredRates,
      contractProductId,
      annualFee,
      annualFeeDate,
      expirationDate
    } = contractProducts[0] || {};
    const { requiredDownpayment, volume, closingCost, paidToday } = contractPrices[0] || {};
    let expirationYears = null;
    if (contract.creationDate && expirationDate) {
      expirationYears = moment(expirationDate).diff(moment(contract.creationDate), 'years');
    }

    const contractLoan = contractLoans[0] || {};
    contract = {
      ...contract,
      productId: (membershipType || {}).productId,
      roomTypeId,
      contractSeasonId,
      membershipTypeId,
      membershipWeeks,
      preferredRates,
      requiredDownpayment,
      membershipPrice: volume,
      paidToday,
      closingCost,
      paidTodayPercentage: Math.round((Number(paidToday) / Number(volume)) * 100),
      requiredDPPercentage: Math.round((Number(requiredDownpayment) / Number(volume)) * 100),
      loanInterest: contractLoan.interest,
      loanTermQuantity: contractLoan.term,
      originalLoanAmount: contractLoan.originalLoanAmount,
      loanFirstDueDate: contractLoan.firstDueDate,
      promissoryNotes,
      contractProductId,
      annualFee,
      annualFeeDate,
      expirationDate,
      expirationYears
    };
    this.setState({
      contract
    });
  }

  newItemWithArray(arrayName) {
    return {
      uiid: shortid.generate(),
      owners: [{ uiid: shortid.generate() }]
    };
  }

  newItem() {
    return { uiid: shortid.generate() };
  }

  handleArrayChange = (arrayName, index, property, value) => {
    this.setState(prevState => {
      let items = prevState.contract[arrayName];
      let item = items[index];
      item[property] = value;
      items[index] = item;
      return {
        ...prevState,
        contract: {
          ...prevState.contract,
          [arrayName]: items
        }
      };
    });
  };

  handleArrayChildChange = (parentArrayName, parentIndex, arrayChild, childIndex, property, value) => {
    this.setState(prevState => {
      let parentItems = prevState.contract[parentArrayName];
      let parentItem = parentItems[parentIndex];
      let childs = parentItem[arrayChild];
      let item = childs[childIndex];
      item[property] = value;
      childs[childIndex] = item;
      parentItem[arrayChild] = childs;
      parentItems[parentIndex] = parentItem;

      return {
        ...prevState,
        contract: {
          ...prevState.contract,
          [parentArrayName]: parentItems
        }
      };
    });
  };

  handlePropertyChange = async (property, value) => {
    await this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        [property]: value
      }
    }));
    this.calculatePrices(property, value);
  };

  addItem = arrayName => {
    this.setState(prevState => ({
      ...prevState,
      contract: {
        ...prevState.contract,
        [arrayName]: [
          ...(prevState.contract[arrayName] || []),
          arrayName === ('certificates' || 'people') ? this.newItemWithArray(arrayName) : this.newItem()
        ]
      }
    }));
  };

  addChildItem = (parentArrayName, parentIndex, childArrayName) => {
    this.setState(prevState => {
      let parentArray = prevState.contract[parentArrayName];
      let parent = parentArray[parentIndex];
      let array = parent[childArrayName];

      array = array || [];
      array.push(this.newItem());
      parent[childArrayName] = array;
      parentArray[parentIndex] = parent;

      return {
        ...prevState,
        contract: {
          ...prevState.contract,
          [parentArrayName]: parentArray
        }
      };
    });
  };

  removeItem = (arrayName, index) => {
    if (arrayName === 'people' && this.state.contract.people.length === 2) {
      const owner = this.state.contract.people[0];
      owner.addresses = !owner.addresses || owner.addresses.length < 1 ? [{}] : owner.addresses;
      owner.phones = !owner.phones || owner.phones.length < 1 ? [{}] : owner.phones;
      owner.emails = !owner.emails || owner.emails.length < 1 ? [{}] : owner.emails;
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          people: [owner]
        }
      }));
    } else {
      this.setState(prevState => {
        const array = prevState.contract[arrayName];
        array.splice(index, 1);
        return {
          ...prevState,
          contract: {
            ...prevState.contract,
            [arrayName]: array
          }
        };
      });
    }
  };

  removeChildItem = (parentArrayName, parentIndex, arrayName, index) => {
    this.setState(prevState => {
      let parentArray = prevState.contract[parentArrayName];
      let parent = parentArray[parentIndex];
      parent[arrayName].splice(index, 1);
      parentArray[parentIndex] = parent;
      return {
        ...prevState,
        contract: {
          ...prevState.contract,
          [parentArrayName]: parentArray
        }
      };
    });
  };

  calculatePrices = (key, value) => {
    if (CALCULABLE_KEYS.some(s => s === key)) {
      const prices = CALCULABLE_KEYS.reduce((acc, k) => {
        let newValue = (k === key ? value : this.state.contract[k]) || 0;
        acc[k] = Array.isArray(newValue) ? newValue : parseFloat(newValue);
        return acc;
      }, {});

      const paidToday = ((key === 'payments' ? value : prices.payments) || []).reduce(
        (acc, p) =>
          acc +
          p.paymentDetails.reduce(
            (pdAcc, pd) => (pd.paymentType.acronym !== 'CCOST' ? pdAcc + parseFloat(pd.amount) : pdAcc),
            0
          ),
        0
      );
      let { requiredDownpayment, membershipPrice } = prices;
      const paidTodayPercentage = membershipPrice > 0 ? Math.round((paidToday / membershipPrice) * 100) : 0;
      const totalADP = requiredDownpayment - paidToday;

      const totalFinanced =
        paidToday > requiredDownpayment ? membershipPrice - paidToday : membershipPrice - requiredDownpayment;

      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          paidToday,
          paidTodayPercentage,
          totalADP,
          totalFinanced
        }
      }));
    }
  };

  onStepChange = async (prevStepIndex, stepIndex, next) => {
    if (prevStepIndex === stepIndex) return;

    if (prevStepIndex === STEPS.length - 1) return;

    if (stepIndex < prevStepIndex) next();
    const isValidForm = this.stepForm.reportValidity();
    if (!isValidForm) {
      return;
    }

    const { contractId, contract } = this.state;
    if (MEMBER_STEP === prevStepIndex && !contractId) {
      return this.createContract(contract, next);
    } else if (MEMBER_STEP === prevStepIndex && contractId) {
      this.updateContract(contract);
      return next();
    } else if (PRODUCT_PRICE_STEP === prevStepIndex) {
      return this.changeContractProductPrice(contract, next);
    } else if (SPECIAL_PROVISIONS_STEP === prevStepIndex) {
      await this.updateAdditionalInfo(contract);
      return next();
    }
    next();
  };

  createContract = async (contract, next) => {
    const resp = await ContractService.createContract(contract);
    if (resp.contractId > 0 && !this.state.contractId) {
      Toast.success(`${translate.t('draftContractCreatedMSG')} ${resp.number}`, { autoClose: false });
      this.setState(prevState => ({
        ...prevState,
        contract: {
          ...prevState.contract,
          contractId: resp.contractId,
          number: resp.number
        },
        contractId: resp.contractId
      }));
      next();
    }
  };

  updateContract = async contract => {
    await ContractService.updateContract(contract);
  };

  changeContractProductPrice = async (contract, next) => {
    const {
      productId,
      roomTypeId,
      contractSeasonId,
      membershipTypeId,
      membershipWeeks,
      preferredRates,
      loanInterest,
      loanTermQuantity,
      originalLoanAmount,
      loanFirstDueDate,
      membershipPrice,
      closingCost,
      requiredDownpayment,
      paidToday,
      promissoryNotes,
      totalADP,
      totalFinanced,
      annualFee,
      annualFeeDate,
      expirationYears
    } = contract;

    const expirationDate = moment(contract.creationDate || new Date())
      .add(expirationYears, 'years')
      .format('YYYY-MM-DD');

    const change = {
      product: {
        productId,
        roomTypeId,
        contractSeasonId,
        membershipTypeId,
        membershipWeeks,
        preferredRates,
        annualFee,
        annualFeeDate,
        expirationDate
      },
      price: {
        volume: parseFloat(membershipPrice),
        closingCost: parseFloat(closingCost),
        requiredDownpayment: parseFloat(requiredDownpayment),
        paidToday: parseFloat(paidToday)
      },
      loan: {
        term: parseInt(loanTermQuantity) || 1,
        originalLoanAmount: parseFloat(originalLoanAmount) || 0,
        firstDueDate: loanFirstDueDate || moment().format('YYYY-MM-DD'),
        interest: parseFloat(loanInterest) || 0
      }
    };

    if (totalADP > 0) {
      change.promissoryNotes = promissoryNotes;
    } else {
      delete change.promissoryNotes;
    }
    if (totalFinanced > 0) {
      change.loan = { ...change.loan, originalLoanAmount: totalFinanced };
    } else {
      delete change.loan;
    }
    const req = await ContractService.changeContractProductPrice(contract.contractId, change);
    const msg = req.success ? '' : req.response.data.message;

    if (msg === 'CONTRACT_ADP_UNCOMPLETED' || msg === 'CONTRACT_LOAN_UNCOMPLETED') {
      Toast.error(translate.t(msg));
    } else {
      next();
    }
  };

  updateAdditionalInfo = async contract => {
    const { gifts = [], participants = [], chargeBacks = [], annexes = [], certificates = [] } = contract;
    gifts.map(g => (g.quantity = g.quantity || g.contractGift.quantity));
    participants.map(p => (p.positionId = p.positionId || p.contractParticipant.positionId));
    const req = { gifts, participants, chargeBacks, annexes, certificates };
    await ContractService.updateAdditionalInfo(contract.contractId, req);
  };

  sign = async (contractId, people) => {
    const signatures = people.map(p => {
      return { contractPersonId: p.contractPerson.id, signature: p.contractPerson.signature };
    });
    await ContractService.sign(contractId, signatures);
  };
  saveCurrentStep = e => e.preventDefault();

  render() {
    const { contract } = this.state;

    return (
      <ContractFormContext.Provider
        value={{
          contract,
          handleArrayChange: this.handleArrayChange,
          handlePropertyChange: this.handlePropertyChange,
          addItem: this.addItem,
          removeItem: this.removeItem,
          handleArrayChildChange: this.handleArrayChildChange,
          addChildItem: this.addChildItem,
          removeChildItem: this.removeChildItem,
          printContract: this.printContract,
          updateContractStatus: this.updateContractStatus,
          history: this.props.history
        }}
      >
        <ContractFormContext.Consumer>
          {context => (
            <form onSubmit={this.saveCurrentStep} ref={b => (this.stepForm = b)}>
              <Wizard steps={STEPS} style={{ margin: '20px 0px' }} selectStep={this.onStepChange} context={context} />
            </form>
          )}
        </ContractFormContext.Consumer>
      </ContractFormContext.Provider>
    );
  }
}

export default withLoader(ContractForm);
