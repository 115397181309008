import React, { Component } from 'react';
import ContractDetailView from '../components/ContractDetailView';
import { withLoader } from '../../reusable-components';

class Detail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contract: {}
    };
  }

  async componentDidMount() {}

  render() {
    return (
      <ContractDetailView
        context={{ contractId: this.props.match.params.contractId, history: this.props.history }}
        showSignatureForm={false}
      />
    );
  }
}

export default withLoader(Detail);
