import React from 'react';
import styled from 'styled-components';
import Label from './InputLabel';
import { COLORS } from '../../../vars/palette';

const Wrapper = styled.div`
  background-color: #fff;
  padding: 3px 9px;
  border: 2px solid ${COLORS.INPUT_BORDER};
  position: relative;
  &:focus-within {
    border: 2px solid ${COLORS.AQUA_BLUE};
  }

  & .label-container {
    display: block;
    height: 13px;
    margin-bottom: 5px;
  }
`;

const InputWrapper = WrappedComponent =>
  class extends React.Component {
    render() {
      const { label, style } = this.props;
      return (
        <Wrapper style={style}>
          <span className="label-container">{label && <Label>{label}</Label>}</span>
          <WrappedComponent {...this.props} />
        </Wrapper>
      );
    }
  };

export default InputWrapper;
