export default {
  WELCOME_DASHBOARD: 'WELCOME_DASHBOARD',
  WELCOME_CONTRACT: 'WELCOME_CONTRACT',
  WELCOME_COLLECT: 'WELCOME_COLLECT',
  WELCOME_REPORT: 'WELCOME_REPORT',
  WELCOME_ADMIN: 'WELCOME_ADMIN',
  CONTRACTS_SEARCH: 'CONTRACTS_SEARCH',
  CONTRACTS_CREATE: 'CONTRACTS_CREATE',
  COLLECT_CREATE: 'COLLECT_CREATE'
};
