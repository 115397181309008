import ColorRequest from './colorRequest';

class CatalogService {
  static async getByType(type) {
    try {
      const { data: resp } = await ColorRequest.get(`/catalogs/${type}`);
      return resp;
    } catch (e) {
      return e;
    }
  }
}

export default CatalogService;
