import React, { Component, Fragment } from 'react';
import PrintableContract from '../components/PrintableContract';
import ReactToPrint from 'react-to-print';
import { ContractService } from '../../services';
import { Button } from '../../reusable-components';
import translate from '../../translate';
import Checkbox from '../../reusable-components/common/Inputs/Checkbox';

const TEMPLATE_NAME = 'Printable Contract';

class PrintableContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: props.match.params.contractId,
      contract: {},
      template: {},
      isManualSignature: false,
      printDialogOpened: false
    };
  }

  async componentDidMount() {
    const { contractId } = this.state;
    if (!contractId) {
      return;
    }

    const contract = await ContractService.getById(contractId);
    if ((contract.status || {}).acronym !== 'VRF') {
      return this.props.history.push(`/contracts/${contract.contractId}/update`);
    }
    const template =
      contract && (await ContractService.getContractDocumentTemplate(TEMPLATE_NAME, contract.languageId));

    this.setState({ contract, template });
  }

  handleChangeSignature = (personId, signature) => {
    const contract = { ...this.state.contract };
    contract.people.find(p => p.personId === personId).contractPerson.signature = signature;

    this.setState({ contract });
  };

  handleChangeManualSignature = value => {
    this.setState({ isManualSignature: value });
  };

  handleAfterPrint = () => {
    this.setState({ printDialogOpened: true });
  };

  handleSubmit = async () => {
    const { contract, isManualSignature, printDialogOpened, contractId } = this.state;
    const people = contract.people;
    let signatures = [];

    if (!isManualSignature) {
      const signMissing = people.filter(p => !p.contractPerson.signature).length;

      if (signMissing) {
        alert('Complete all signatures');
        return;
      }

      signatures = people.map(p => {
        return {
          personId: p.personId,
          contractPersonId: p.contractPerson.id,
          signature: p.contractPerson.signature
        };
      });
    } else if (!printDialogOpened) {
      alert('Please print the contract before submitting');
    }

    const resp = await ContractService.sign(contractId, signatures);

    if (resp) {
      alert('Something went wrong signing the contract');
    } else {
      this.props.history.push(`/contracts/${contractId}`);
    }
  };

  render() {
    const { contract, template, isManualSignature } = this.state;
    return (
      <Fragment>
        <PrintableContract
          contract={contract}
          template={template}
          ref={el => (this.componentRef = el)}
          onChangeSignature={this.handleChangeSignature}
        />

        <div className="row m-5 px-3">
          <div className="col-12 d-flex justify-content-between">
            <div className="d-inline-block" style={{ minHeight: '30px' }}>
              <Checkbox
                isChecked={isManualSignature}
                onCheck={value => this.handleChangeManualSignature(value)}
                labelText={translate.t('manualSignature')}
                labelStyle={{ fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif' }}
              />
              {isManualSignature && (
                <ReactToPrint
                  trigger={() => (
                    <Button type="button" className="ml-3">
                      <i className="fa fa-print mr-2" />
                      {translate.t('print')}
                    </Button>
                  )}
                  content={() => this.componentRef}
                  onAfterPrint={() => this.handleAfterPrint()}
                  pageStyle="size: auto; margin: 1.5cm 0;"
                />
              )}
            </div>
            <Button primary={true} onClick={() => this.handleSubmit()}>
              {translate.t('submit')}
            </Button>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default PrintableContainer;
