import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, Select } from '../../reusable-components';

class EmailForm extends Component {
  render() {
    const { email, parentIndex, index, context, emailTypes } = this.props;
    return (
      <Fragment>
        <div className="row no-gutters">
          <div className="col-6 pb-2 pr-2">
            <Select
              placeholder={translate.t('emailType')}
              options={emailTypes}
              isClearable={true}
              getOptionValue={et => et.emailTypeId}
              getOptionLabel={et => et.name}
              value={emailTypes.find(et => et.emailTypeId === email.emailTypeId)}
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'emails', index, 'emailTypeId', e.emailTypeId)
              }
              required
            />
          </div>
          <div className="col-6 pb-2">
            <TextInput
              label={translate.t('email')}
              autoComplete="e-mail"
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'emails', index, 'value', e.target.value)
              }
              type="email"
              placeholder={translate.t('email')}
              value={email.value}
              required
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default EmailForm;
