import styled from 'styled-components'
import { COLORS } from '../vars/palette'

const Title = styled.h1`
  font-family: 'exo-light';
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  margin-left: 5px;
  color: ${COLORS.HEADER_COLOR};
`

export default Title