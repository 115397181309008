import ColorRequest from './colorRequest';

class PersonService {
  static async getAddressTypes() {
    try {
      const { data: resp } = await ColorRequest.get('/people/addressType');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getCivilStatuses() {
    try {
      const { data: resp } = await ColorRequest.get('/people/civilStatuses');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getCountries() {
    try {
      const { data: resp } = await ColorRequest.get('/people/countries');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getEmailTypes() {
    try {
      const { data: resp } = await ColorRequest.get('/people/emailTypes');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getIdentificationTypes() {
    try {
      const { data: resp } = await ColorRequest.get('/people/identificationTypes');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getPhoneTypes() {
    try {
      const { data: resp } = await ColorRequest.get('/people/phoneTypes');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getOccupations() {
    try {
      const { data: resp } = await ColorRequest.get('/people/occupations');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getRelationshipTypes() {
    try {
      const { data: resp } = await ColorRequest.get('/people/relationshipTypes');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getLanguages() {
    try {
      const { data: resp } = await ColorRequest.get('/people/languages');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getPositions() {
    try {
      const { data: resp } = await ColorRequest.get('/people/positions');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getEmployees(criteria) {
    try {
      const { data: resp } = await ColorRequest.get(`/people/employees?criteria=${criteria}`);
      return resp;
    } catch (e) {
      return e;
    }
  }
}

export default PersonService;
