import React from 'react';
import { InputWrapper, Input } from './base';

class TextInput extends React.Component {
  onBlur = e => {
    const { onBlur, onChange } = this.props;
    const { value } = e.target;
    e.target.value = value.trim();
    onBlur && onBlur(e);
    onChange && onChange(e);
  };
  render() {
    const { pattern = '.*[^ ].*', type = 'text', value, onChange, maxLength, autoComplete } = this.props;
    return (
      <Input
        autoComplete={autoComplete || 'off'}
        type={type}
        maxLength={maxLength || 250}
        pattern={pattern}
        {...{
          ...this.props,
          ...(onChange ? { value: value === undefined ? '' : value } : {})
        }}
        onBlur={this.onBlur}
        style={this.props.inputStyle}
      />
    );
  }
}

export default InputWrapper(TextInput);
