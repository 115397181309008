import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Modal } from '../../reusable-components';
import { Table, TextInput, Button, DatePicker, Dropdown } from '../../reusable-components';
import PaymentQuickView from '../components/PaymentQuickView';
import { PaymentService, CatalogService } from '../../services';
import { COLORS } from '../../reusable-components/vars/palette';
import translate from '../../translate';
import Toast from '../../reusable-components/common/Toast';

const tableFields = [
  {
    header: translate.t('confNumber'),
    column: 'authorization',
    size: '10%',
    report: true,
    sort: 'authorization'
  },
  {
    header: translate.t('fullName'),
    column: 'fullName',
    size: '20%',
    maxWidth: 150,
    report: true,
    sort: 'fullName'
  },
  {
    header: translate.t('amount'),
    column: 'total',
    size: '10%',

    report: true,
    sort: 'total'
  },
  {
    header: translate.t('status'),
    column: 'status.name',
    size: '10%',
    report: true,
    sort: 'status',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: translate.t('date'),
    column: payment => translate.l('dateFormats.date', moment(payment.date).toDate()),
    size: '10%',
    report: true,
    sort: 'date',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: translate.t('agent'),
    column: 'user.fullName',
    size: '10%',
    report: true,
    sort: 'agent',
    hideOnSplit: true,
    hideOnSmallScreens: true
  }
];

const Title = styled.div`
  margin-top: 10px;
  font-family: exo-light;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.HEADER_COLOR};
`;

const FiltersContainer = styled.div`
  background-color: ${COLORS.PALE_GRAY_TWO};
  padding: 15px;
  margin-bottom: 25px;
`;

const Control = styled.div`
  padding: 5px !important;
  display: ${props => (props.hide ? 'none' : 'block')};
`;

const getPaymentColor = payment => {
  if (payment.status === 'APPROVED') {
    return 'green';
  } else if (payment.status === 'DECLINED') {
    return 'red';
  } else if (payment.status === 'CANCELED') {
    return 'orange';
  } else {
    return COLORS.PALE_GRAY_TWO;
  }
};

class Collect extends Component {
  constructor(props) {
    super(props);

    this.baseState = {
      payments: { data: [] },
      splitView: false,
      tableFields: tableFields,
      selectedPayment: {},
      filters: {
        authorization: ''
      }
    };

    this.state = {
      ...this.baseState,
      payments: { data: [] },
      paymentTypes: [],
      paymentMethods: [],
      statuses: [],
      cancellationReference: ''
    };
  }

  canSearch = () => {
    return Object.keys(this.state.filters)
      .filter(k => k !== 'itemsPerPage' && k !== 'pageNumber' && k !== 'dateType')
      .some(k => this.state.filters[k]);
  };

  onPaginateSearch = pageNumber => {
    this.searchPayment(pageNumber);
  };

  search = () => {
    this.searchPayment(1);
  };

  searchPayment = async (pageNumber, sort = '', direction = '') => {
    if (!this.canSearch()) {
      return alert('Select some filters');
    }
    const payments = await PaymentService.getByQuery({
      ...this.state.filters,
      pageNumber,
      sortColumn: sort,
      sortDirection: direction
    });
    this.setState({
      payments
    });
  };

  onRowClick = async row => {
    const selectedPayment = await PaymentService.getById(row.id);
    this.setState({
      splitView: true,
      tableFields: this.state.tableFields.filter(f => !f.hideOnSplit),
      selectedPayment
    });
  };

  handleChange = (name, value) => {
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value
      }
    }));
  };

  clear = () => {
    this.setState(this.baseState);
  };

  onSort = async (direction, column) => {
    if (!this.state.payments.data.length || !direction) {
      return;
    }
    await this.searchPayment(1, column, direction);
  };

  onSplitViewClose = () => {
    this.setState({
      splitView: false,
      tableFields: this.baseState.tableFields
    });
  };

  onKeyUp = e => {
    e.keyCode === 13 && this.search();
  };

  onCancelClick = () => {
    this.setState(pv => ({ showCancelModal: !pv.showCancelModal, cancellationReference: '' }));
  };

  onRefundClick = () => {
    this.setState(pv => ({ showRefundModal: !pv.showRefundModal, cancellationReference: '' }));
  };

  confirmCancellation = async () => {
    try {
      const selectedPayment = await (this.state.showCancelModal
        ? PaymentService.cancel(this.state.selectedPayment.id, this.state.cancellationReference)
        : PaymentService.refund(this.state.selectedPayment.id, this.state.cancellationReference));
      if (selectedPayment && selectedPayment.id) {
        this.setState({
          showCancelModal: false,
          showRefundModal: false,
          selectedPayment,
          cancellationReference: ''
        });
        Toast.success(translate.t('successTransaction'));
      } else {
        if (selectedPayment && selectedPayment.errorCode) {
          Toast.error(translate.t('paymentError.05'));

        } else {
          Toast.error(translate.t('errorOcurred'));
        }
      }
    } catch (e) {
      Toast.error(translate.t('errorOcurred'));
    }
  };
  async componentDidMount() {
    const statuses = await CatalogService.getByType('PAYMENT_STATUS');

    this.setState(
      pv => ({
        ...pv,
        paymentTypes: [],
        paymentMethods: [],
        statuses,
        filters: {
          ...pv.filters,
          from: moment().format('YYYY-MM-DD'),
          to: moment()
            .add(1, 'day')
            .format('YYYY-MM-DD')
        }
      }),
      () => this.search()
    );
  }

  render() {
    const { payments, statuses, filters, splitView, selectedPayment, showCancelModal } = this.state;
    return (
      <div>
        <Title>{translate.t('searchPayments')}</Title>
        <div className="row my-3">
          <div className={`pb-3 ${splitView ? 'col-12 col-md-6 px-0 pr-md-3' : 'col-12'}`}>
            <FiltersContainer splitView={splitView}>
              <div className="row no-gutters">
                <Control className={`col-12 col-md-6 ${splitView ? '' : 'col-lg-3'}`}>
                  <TextInput
                    label={translate.t('confirmationNumber')}
                    onChange={e => this.handleChange('authorization', e.target.value)}
                    placeholder={translate.t('confirmationNumber')}
                    value={filters.authorization}
                    onKeyUp={this.onKeyUp}
                  />
                </Control>
                <Control className={`col-12 col-md-6 ${splitView ? '' : 'col-lg-3'}`}>
                  <TextInput
                    label={translate.t('fullName')}
                    onChange={e => this.handleChange('fullName', e.target.value)}
                    placeholder={translate.t('fullName')}
                    value={filters.fullName}
                    onKeyUp={this.onKeyUp}
                  />
                </Control>
                {/* <Control className="col-12 col-md-4 col-lg-2" hide={splitView}>
                  <TextInput
                    label={translate.t('reference')}
                    onChange={e => this.handleChange('reference', e.target.value)}
                    placeholder={translate.t('reference')}
                    value={filters.reference}
                    onKeyUp={this.onKeyUp}
                  />
                </Control> */}
                <Control className="col-12 col-sm-6 col-md-4 col-lg-2" hide={splitView}>
                  <DatePicker
                    label={translate.t('from')}
                    autoComplete="off"
                    name="from"
                    onSelect={date => this.handleChange('from', date || '')}
                    value={filters.from}
                  />
                </Control>
                <Control className="col-12 col-sm-6 col-md-4 col-lg-2" hide={splitView}>
                  <DatePicker
                    label={translate.t('to')}
                    autoComplete="off"
                    minDate={moment(filters.from) || moment()}
                    name={translate.t('to')}
                    onSelect={date => this.handleChange('to', date || '')}
                    value={filters.to}
                  />
                </Control>
                <Control className="col-12 col-sm-6 col-md-4 col-lg-2" hide={splitView}>
                  <Dropdown
                    label={translate.t('status')}
                    name="statusId"
                    items={statuses}
                    optionValue="id"
                    optionText="name"
                    value={filters.statusId}
                    onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                  />
                </Control>
              </div>
              <div className="row no-gutters">
                {/* <Control className="col-12 col-md-4 col-lg-3" hide={splitView}>
                  <Dropdown
                    label={translate.t('paymentMethod')}
                    name="paymentMethodId"
                    items={paymentMethods}
                    optionValue="paymentMethodId"
                    optionText="name"
                    value={filters.paymentMethodId}
                    onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                  />
                </Control> */}

                {/* <Control className="col-12 col-md-4 col-lg-2" hide={splitView}>
                  <Dropdown
                    label={translate.t('paymentType')}
                    items={paymentTypes}
                    optionValue="paymentTypeId"
                    optionText="name"
                    name="paymentTypeId"
                    value={filters.paymentTypeId}
                    onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                  />
                </Control> */}

                <Control className={`col-6 ${splitView ? '' : 'col-lg-2'}`}>
                  <Button className="m-0" style={{ width: '100%', height: '100%' }} onClick={this.clear}>
                    {translate.t('clear')}
                  </Button>
                </Control>
                <Control className={`col-6 ${splitView ? '' : 'col-lg-2'}`}>
                  <Button
                    className="m-0"
                    style={{ width: '100%', height: '100%' }}
                    onClick={this.search}
                    primary="true"
                  >
                    {translate.t('search')}
                  </Button>
                </Control>
              </div>
            </FiltersContainer>

            <div className="row">
              <div className="col-12">
                <Table
                  tableFields={this.state.tableFields}
                  reportData={payments.rows}
                  data={payments.rows}
                  reportFilename={'Payments ' + new Date() + '.csv'}
                  totalItems={payments.count}
                  itemsPerPage={20}
                  onSort={this.onSort}
                  onPaginateSearch={this.onPaginateSearch}
                  onRowClick={this.onRowClick}
                  onRowDoubleClick={this.onRowDoubleClick}
                />
              </div>
            </div>
          </div>
          {splitView && (
            <div className="col-12 col-md-6" style={{ flex: 1, backgroundColor: '#edf2f6' }}>
              <PaymentQuickView
                onClose={this.onSplitViewClose}
                payment={selectedPayment}
                onCancelClick={this.onCancelClick}
                onRefundClick={this.onRefundClick}
              />
            </div>
          )}
        </div>
        <Modal
          className="modal-sm"
          show={this.state.showCancelModal || this.state.showRefundModal}
          close={() => this.setState({ showCancelModal: false, showRefundModal: false })}
          title={showCancelModal ? translate.t('cancel') : translate.t('refund')}
        >
          <FiltersContainer splitView={splitView}>
            <div className="row no-gutters">
              <div style={{ fontFamily: 'exo-regular' }}>
                {(showCancelModal
                  ? `${translate.t('areYouSureYouWantToCancelPayment')}`
                  : `${translate.t('areYouSureYouWantToRefundPayment')}`) + ` (${selectedPayment.authorization})`}
              </div>
              <Control className={`col-6`}>
                <TextInput
                  label={showCancelModal ? translate.t('cancellationReference') : translate.t('refundReference')}
                  onChange={e => this.setState({ cancellationReference: e.target.value })}
                  placeholder={showCancelModal ? translate.t('cancellationReference') : translate.t('refundReference')}
                  value={this.state.cancellationReference}
                />
              </Control>
            </div>
          </FiltersContainer>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Control>
              <Button
                className="m-0"
                style={{ width: '100%', height: '100%' }}
                danger={true}
                onClick={() => this.confirmCancellation()}
              >
                {showCancelModal ? translate.t('cancelPayment') : translate.t('refundPayment')}
              </Button>
            </Control>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Collect;
