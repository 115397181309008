import ColorRequest from './colorRequest';

class ContractService {
  static async createContract(contract) {
    try {
      const { data: resp } = await ColorRequest.post('/contracts/members', buildPeople(contract));
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async updateContract(contract) {
    try {
      const { data: resp } = await ColorRequest.put(
        '/contracts/' + contract.contractId + '/members',
        buildPeople(contract)
      );
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async sign(contractId, signatures) {
    try {
      const { data: resp } = await ColorRequest.put(`/contracts/${contractId}/sign`, signatures);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async updateAdditionalInfo(contractId, additionalInfo) {
    try {
      const { data: resp } = await ColorRequest.put(`/contracts/${contractId}/verify`, additionalInfo);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async changeContractProductPrice(contractId, change) {
    try {
      const { data: resp } = await ColorRequest.put(`/contracts/${contractId}/product-price`, change);
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getListByQuery(params) {
    try {
      const { data: resp } = await ColorRequest.post('/contracts/list/query', params);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getById(contractId) {
    try {
      const { data: resp } = await ColorRequest.get('/contracts/' + contractId);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getParticipants(contractId) {
    try {
      const { data: resp } = await ColorRequest.get('/contracts/' + contractId + '/participants');
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getGalleries() {
    try {
      const { data: resp } = await ColorRequest.get('/contracts/galleries');
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getProducts() {
    try {
      const { data: resp } = await ColorRequest.get('/contracts/products');
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getStatuses() {
    try {
      const { data: resp } = await ColorRequest.get('/contracts/statuses');
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getRoomTypes(productId) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/roomTypes?productId=${productId}`);
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getMembershipTypes(productId) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/membershipTypes?productId=${productId}`);
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getContractSeasons(productId) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/contractSeasons?productId=${productId}`);
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async getMembershipWeekQtys(productId) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/membershipWeekQtys?productId=${productId}`);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getGifts(criteria) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/gifts?criteria=${criteria}`);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getPromissoryNoteTypes() {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/promissoryNoteTypes`);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getMembershipCatalogPrices({
    contractSeasonId,
    roomTypeId,
    membershipWeekQty,
    membershipTypeId,
    productId
  }) {
    try {
      const { data: resp } = await ColorRequest.get(`/contracts/membershipCatalogPrices`, {
        params: {
          contractSeasonId,
          roomTypeId,
          membershipWeekQty,
          membershipTypeId,
          productId
        }
      });
      return resp;
    } catch (e) {
      return e;
    }
  }

  static getAnnexTypes(languageId, type) {
    return ColorRequest.get('/contracts/annexTypes', { params: { languageId, type } });
  }
  static async isUpdatable(contractId) {
    try {
      const resp = await ColorRequest.get(`/contracts/${contractId}/isUpdatable`);
      return resp.data;
    } catch (e) {
      return e;
    }
  }

  static async getContractDocumentTemplate(templateName, languageId) {
    try {
      const resp = await ColorRequest.get(
        `/contracts/contractTemplate?templateName=${templateName}&languageId=${languageId}`
      );
      return resp.data;
    } catch (e) {
      return e;
    }
  }

  static async getCertificateTypes() {
    try {
      const { data: response } = await ColorRequest.get('/contracts/certificateTypes');
      return response;
    } catch (e) {
      return e;
    }
  }

  static async updateContractStatus(contractId, acronym) {
    try {
      const { data: resp } = await ColorRequest.put(`/contracts/${contractId}/status`, { acronym });
      return resp;
    } catch (e) {
      return e;
    }
  }
}

function buildPeople(contract) {
  contract.people.map(p => {
    if (p.identificationNumber && p.identificationTypeId) {
      p.identifications = [
        {
          value: p.identificationNumber,
          identificationTypeId: p.identificationTypeId,
          countryId: p.nationalityId
        }
      ];
    }
    return p;
  });
  return contract;
}

export default ContractService;
