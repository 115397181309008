const BURPLE = '#5d38db';
const AQUA_BLUE = '#00a8ff';
const PALE_GRAY_TWO = '#f7f9fa';
const PALE_GRAY_THREE = '#efeef8';
const PALE_GRAY = '#dee7ee';
const MAIN_BORDER = '#e6e5e6';
const HEADER_COLOR = '#4a4a4a';
const INPUT_LABEL = '#7a838f';
const INPUT_BORDER = '#dee7ee';
const INPUT_TEXT_COLOR = '#394a51';
const TEALISH_GREEN = '#0be881';
const CORAL = '#ef5350';
const SKY = '#80d4ff';
const PALE_SKY_BLUE = '#bfe9ff';
const BLUE_GRAY_TWO = '#8395a7';
const WARNING = '#ffc107';
const CONTEXT_BORDER = '#d0d6e6';
const CONTEXT_COLOR = '#686868';
const TABLE_BORDER = '#b8c5d0';
const TABLE_PAGINATION_BORDER = '#c6cbd4';
const TABLE_PAGINATION_SUMMARY = '#333333';
const TABLE_CHECK_DOT_BORDER = '#7a838f';
const TABLE_CHECK_DOT_FILL = '#f7f7f7';
const TABLE_THREE_DOT_MENU_FILL = '#d8d8d8';
const MINT_GREEN = '#00ffb3';
const CHARCOAL_GRAY = '#394a51';
const ACTIVE_TAB = '#e1e3e4';
const GRAY_BORDER = '#dfe7ed';
const WHITE = '#fff';
const INFO = '#28a745';
const CHECKBOX_HOVER = '#ccc';
const CHECKBOX_CHECKMARK = '#eee';
const CHECKBOX_CHECKED = '#2196f3;';
const SPRING_GREEN = '#00ff71';

export const COLORS = {
  BURPLE,
  AQUA_BLUE,
  PALE_GRAY_TWO,
  MAIN_BORDER,
  HEADER_COLOR,
  INPUT_LABEL,
  INPUT_BORDER,
  INPUT_TEXT_COLOR,
  PALE_GRAY,
  TEALISH_GREEN,
  CORAL,
  SKY,
  PALE_SKY_BLUE,
  BLUE_GRAY_TWO,
  WARNING,
  CONTEXT_BORDER,
  CONTEXT_COLOR,
  PALE_GRAY_THREE,
  TABLE_BORDER,
  TABLE_PAGINATION_BORDER,
  TABLE_PAGINATION_SUMMARY,
  TABLE_CHECK_DOT_BORDER,
  TABLE_CHECK_DOT_FILL,
  TABLE_THREE_DOT_MENU_FILL,
  MINT_GREEN,
  CHARCOAL_GRAY,
  ACTIVE_TAB,
  GRAY_BORDER,
  WHITE,
  INFO,
  CHECKBOX_HOVER,
  CHECKBOX_CHECKMARK,
  CHECKBOX_CHECKED,
  SPRING_GREEN
};
