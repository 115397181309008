import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { withLoader, Button } from '../../reusable-components';
import Comments from '../../misc/containers/Comments';
import { Subtitle, Header } from '../../reusable-components/styles';
import { SubContainer } from '../../reusable-components/styles';
import { ContractService } from '../../services';

const STATUS_DRAFT = 'DRAFT';
const STATUS_ANNULLED = 'ANN';

class Verify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: props.context.contractId || props.context.contract.contractId,
      contract: {}
    };
  }

  async componentDidMount() {
    await ContractService.getById(this.state.contractId).then(contract => this.setState({ contract }));
  }

  render() {
    const { context = {} } = this.props;
    const { contract = {} } = this.state;
    return (
      <Fragment>
        <div className="row justify-content-center mt-1">
          {(contract.status || {}).acronym !== STATUS_DRAFT && (
            <Button onClick={() => context.updateContractStatus(contract.contractId, STATUS_DRAFT, true)}>
              <i className="fa fa-arrow-left mr-2" />
              {translate.t('returnToDraft')}
            </Button>
          )}
          <Button onClick={() => context.printContract(contract.contractId)}>
            <i className="fa fa-file-text mr-2" />
            {translate.t('signContract')}
          </Button>
          {(contract.status || {}).acronym !== STATUS_ANNULLED && (
            <Button onClick={() => context.updateContractStatus(contract.contractId, STATUS_ANNULLED, true)}>
              <i className="fa fa-times mr-2" />
              {translate.t('annullContract')}
            </Button>
          )}
        </div>
        <Subtitle>
          <Header>
            <i className="fa fa-angle-right" />
            {` ${translate.t('verificationComments')}`}
          </Header>
        </Subtitle>
        <SubContainer>
          <Comments rows={4} entityType="CONTRACT" entityId={(context.contract || {}).contractId} />
        </SubContainer>
      </Fragment>
    );
  }
}
export default withLoader(Verify);
