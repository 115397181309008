import React, { Fragment } from 'react';
import { Table } from '.';
import translate from '../../translate';
import moment from 'moment';

const calculateMonthlyPayment = (
  loanAmount,
  loanTermQuantity,
  loanInterest,
  loanFirstDueDate,
  maxLoanFirstDueDate = '2050-12-31'
) => {
  const isLoanDataReady =
    loanAmount > 0 &&
    loanTermQuantity > 0 &&
    loanInterest >= 0 &&
    loanFirstDueDate &&
    moment(loanFirstDueDate).isValid() &&
    moment(loanFirstDueDate).isSameOrAfter(moment()) &&
    moment(loanFirstDueDate).isBefore(maxLoanFirstDueDate);

  let data = {};

  if (isLoanDataReady) {
    data.monthlyInterestRate = loanInterest / 100 / 12;
    const ratePowQty = Math.pow(1 + data.monthlyInterestRate, loanTermQuantity);
    data.monthlyPayment = (loanAmount * ratePowQty * data.monthlyInterestRate) / (ratePowQty - 1);
    data.totalToPay = data.monthlyPayment * loanTermQuantity;
    data.totalInterest = data.totalToPay - loanAmount;
    data.payOffDate = moment(loanFirstDueDate)
      .add(loanTermQuantity, 'M')
      .toDate();
  }

  return data;
};

const loanTableFields = [
  { header: translate.t('date'), column: 'date', size: '25%' },
  { header: translate.t('interest'), column: 'interest', size: '25%', type: 'money' },
  { header: translate.t('principal'), column: 'principal', size: '25%', type: 'money' },
  { header: translate.t('balance'), column: 'balance', size: '25%', type: 'money' }
];

const AmortizationTable = ({ loanAmount, loanTermQuantity, loanInterest, loanFirstDueDate }) => {
  const loanDetail = [];
  const loanData = calculateMonthlyPayment(loanAmount, loanTermQuantity, loanInterest, loanFirstDueDate);
  if (loanData.monthlyPayment) {
    let balance = loanAmount;

    for (let index = 0; index < loanTermQuantity; index++) {
      const date = translate.l(
        'dateFormats.date',
        moment(loanFirstDueDate)
          .add(index, 'M')
          .toDate()
      );
      const interest = balance * loanData.monthlyInterestRate;
      const principal = loanData.monthlyPayment - interest;
      balance -= principal;

      loanDetail.push({ date, interest, principal, balance });
    }
  }

  return (
    <Fragment>
      {loanDetail.length > 0 && (
        <div className="row mb-2">
          <div className="col-12">
            <Table
              tableFields={loanTableFields}
              data={loanDetail}
              disableRowSelection={true}
              showRowCheck={false}
              showRowMenu={false}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export { calculateMonthlyPayment };
export { AmortizationTable };
