import React, { Component, Fragment } from 'react';
import { TextInput, Select } from '../../reusable-components';
import translate from '../../translate';
import { ContractService } from '../../services/index';

class GiftForm extends Component {
  getGifts = async (criteria, callback) => {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }
      const { giftsSelected } = this.props;
      let gifts = await ContractService.getGifts(criteria);
      gifts = gifts.filter(gift => !giftsSelected.find(giftS => giftS.giftId === gift.giftId));
      callback(gifts);
    } catch (e) {
      callback([]);
    }
  };

  render() {
    const { handleArrayChange, index, gift = {} } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-6 col-sm-8">
            <Select
              isMulti={false}
              getOptionValue={i => i.giftId}
              getOptionLabel={i => i.name}
              defaultValue={gift}
              cacheOptions
              placeholder={translate.t('giftCodeOrName')}
              onChange={a => {
                handleArrayChange('gifts', index, 'giftId', a.giftId);
                handleArrayChange('gifts', index, 'name', a.name);
              }}
              loadOptions={this.getGifts}
              required
            />
          </div>
          <div className="col-6 col-sm-4">
            <TextInput
              label={translate.t('quantity')}
              required
              onChange={e => handleArrayChange('gifts', index, 'quantity', e.target.value)}
              pattern="^0*[1-9]\d*$"
              placeholder="0"
              type="number"
              step={1}
              min={1}
              value={gift.quantity || (gift.contractGift || {}).quantity}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default GiftForm;
