import axios from 'axios';
import { API_URL } from '../const';
import { withRouter } from 'react-router';

class ColorRequest {
  static loggedUser = null;
  static pendingRequests = [];

  static getLoggedUser() {
    if (this.loggedUser) {
      return this.loggedUser;
    }
    const strLoggedUser = localStorage.getItem('loggedUser');
    return strLoggedUser ? JSON.parse(strLoggedUser) : null;
  }

  //return config && config.skipLoader ? promise : this.attachLoader(url, promise);
  static preResponse(requestPromise, url, config = {}) {
    if (!config.skipLoader) {
      this.pendingRequests.push({ url });
    }
    return requestPromise
      .catch(e => {
        this.pendingRequests = [];
        if (e.response.status === 401) {
          window.location.href = '/login';
        } else {
          throw e;
        }
      })
      .then(result => {
        if (!config.skipLoader) {
          this.pendingRequests.shift();
          window.dispatchEvent(new CustomEvent('x-custom-load', { detail: { pendingRequests: this.pendingRequests } }));
        }

        return result;
      });
  }

  static post(url, data, config) {
    const promise = axios.post(API_URL + url, data, ColorRequest.setUserToken(config));
    return this.preResponse(promise, url, config);
  }

  static patch(url, data, config) {
    const promise = axios.patch(API_URL + url, data, ColorRequest.setUserToken(config));
    return this.preResponse(promise, url, config);
  }

  static put(url, data, config) {
    const promise = axios.put(API_URL + url, data, ColorRequest.setUserToken(config));
    return this.preResponse(promise, url, config);
  }

  static get(url, config) {
    const promise = axios.get(API_URL + url, ColorRequest.setUserToken(config));
    return this.preResponse(promise, url, config);
  }

  static delete(url, config) {
    const promise = axios.delete(API_URL + url, ColorRequest.setUserToken(config));
    return this.preResponse(promise, url, config);
  }

  static setUserToken(config) {
    const loggedUser = this.getLoggedUser();
    if (loggedUser) {
      config = config || {};
      config.headers = config.headers || {};
      config.headers['Authorization'] = loggedUser.token;
    }
    return config;
  }
}

export default ColorRequest;
