import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import translate from '../../translate';

const Username = styled.div`
  font-family: 'exo-light';
  font-size: 20px;
  display: flex;
  align-items: center;
`;

const Description = styled.div`
  font-family: 'roboto-regular';
  font-size: 16px;
  margin: 10px;
`;

const CommentContainer = styled.div`
  margin: 20px;
`;

const CommentDate = styled.div`
  font-size: 12px;
  margin-left: 10px;
`;

export default class Comment extends Component {
  render() {
    const { comment } = this.props;
    return (
      <CommentContainer>
        <Username>
          {comment.username}
          <CommentDate> {translate.l('dateFormats.datetime', moment(comment.createdAt).toDate())}</CommentDate>
        </Username>
        <Description>{comment.description}</Description>
      </CommentContainer>
    );
  }
}
