import React from 'react';
import { Number, Cvc, Expiration } from 'react-credit-card-primitives';
import { COLORS } from '../../vars/palette';
import styled from 'styled-components';
import Label from '../../common/Inputs/base/InputLabel';

import { Jcb, Visa, Maestro, MasterCard, Diners, Discover, Amex, Default } from '../../assets/img';

const CardTypesImages = {
  Visa: Visa,
  Mastercard: MasterCard,
  'American Express': Amex,
  'Diners Club': Diners,
  Discover: Discover,
  JCB: Jcb,
  Maestro: Maestro
};

const CardInputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const CCInput = styled.div`
  background-color: ${COLORS.WHITE};
  padding: 5px;
  flex-direction: row;
  display: flex;
  font-family: 'exo-light';
  border: 2px solid ${COLORS.INPUT_BORDER}
  border-radius: 2px;
  width: 100%
  padding-top: 2px;

  &:focus-within {
    border: 2px solid ${COLORS.AQUA_BLUE};
  }
`;

const baseInput = styled.input`
  border: none;
  font-family: 'exo-light';
  font-size: 12pt;
  background-color: white;
`;
const CardInput = styled(baseInput)`
  margin-left: 5px;
  width: 175px;
}
`;

const ExpireInput = styled(baseInput)`
  width: 70px;
  text-align: center;
`;

const CvcInput = styled(baseInput)`
  width: 44px;
  text-align: center;
}
`;
const ImgCard = styled.img`
  backface-visibility: hidden;
  width: 32px;
  height: 32px;
  transition: all 400ms ease-in;
  display: block;
`;

export default class CreditCardInput extends React.PureComponent {
  state = {
    cvvEditing: false,
    cardEditing: true,
    card: {
      cardNumber: '',
      cardType: '',
      valid: false
    },
    expiration: {
      month: undefined,
      year: undefined,
      valid: false
    },
    cvc: {
      value: '',
      valid: false
    },
    isCardValid: false
  };

  handleCardChange = (type, obj) => {
    const { onChange: onCardChange } = this.props;
    this.setState(
      {
        [type]: obj
      },
      () => {
        const { card, expiration, cvc } = this.state;
        let isCardValid;
        if (card.valid && expiration.valid && cvc.valid) {
          isCardValid = true;
        } else {
          isCardValid = false;
        }

        this.setState({ isCardValid });

        onCardChange &&
          onCardChange({
            cardNumber: card.cardNumber,
            cardType: card.cardType,
            expirationMonth: expiration.month,
            expirationYear: expiration.year,
            cvc: cvc.value,
            valid: isCardValid
          });
      }
    );
  };

  renderInput = ({ valid, getInputProps, type, value }) => {
    const visaFormat = value.length > 16 ? { fontSize: '10pt' } : {};
    const { cardEditing } = this.state;
    const { onChange, ...inputProps } = getInputProps();

    if (valid && cardEditing) {
      this.expiration.focus();
      this.setState({ cardEditing: false });
    }

    return (
      <CardInputContainer>
        <div style={{ position: 'relative', top: -11 }}>
          <ImgCard src={CardTypesImages[type] || Default} alt="" />
        </div>
        <CardInput {...inputProps} style={visaFormat} onChange={e => this.onCardChange(e, onChange)} />
      </CardInputContainer>
    );
  };

  renderExpiration = ({ valid, getInputProps }) => {
    if (valid) this.cvc.focus();

    return <ExpireInput {...getInputProps()} ref={input => (this.expiration = input)} />;
  };
  renderCvc = ({ getInputProps }) => {
    return <CvcInput {...getInputProps()} ref={input => (this.cvc = input)} />;
  };

  onCardChange = (e, onChange) => {
    this.setState({ cardEditing: true });
    onChange && onChange(e);
  };

  render() {
    const { creditcard = {} } = this.props;
    return (
      <CCInput>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="label-container" style={{ marginLeft: 38 }}>
            {<Label style={{ fontSize: 9 }}>{'Card number'}</Label>}
          </span>
          <Number
            value={creditcard.cardNumber}
            render={this.renderInput.bind(this)}
            onChange={({ valid, type, value }) =>
              this.handleCardChange('card', { cardNumber: value, cardType: type, valid })
            }
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="label-container" style={{ marginLeft: 10 }}>
            {<Label style={{ fontSize: 9 }}>{'Expiration'}</Label>}
          </span>
          <Expiration
            month={creditcard.expirationMonth}
            year={creditcard.expirationYear}
            render={this.renderExpiration}
            onChange={({ month, year, valid }) => this.handleCardChange('expiration', { month, year, valid })}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="label-container" style={{ marginLeft: 9 }}>
            {<Label style={{ fontSize: 9 }}>{'CVC'}</Label>}
          </span>
          <Cvc
            cardType={this.state.card.cardType}
            value={creditcard.cvc}
            render={this.renderCvc}
            onChange={({ valid, value }) => this.handleCardChange('cvc', { valid, value })}
          />
        </div>
      </CCInput>
    );
  }
}
