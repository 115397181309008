import React, { Fragment, Children } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

import { InputWrapper } from './base';

const DropDownList = styled.div`
  min-width: 150px;
  width: auto;
  max-height: 200px;
  min-height: 100px;
  overflow-y: scroll;
  border-radius: 2px;
  box-shadow: -9px 7px 0 0 rgba(137, 165, 202, 0.1);
  border: solid 1px #d0d6e6;
  background-color: #ffffff;
  position: absolute;
  margin-top: 5px;
  left: -1px;
  z-index: 99;

  & .dropdown-list-item {
    padding: 0 20px;
    height: 33px;
    vertical-align: middle;
    font-family: 'roboto-medium';
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.36;
    letter-spacing: normal;
    color: #686868;
    white-space: nowrap;
  }
  & .dropdown-list {
    padding-top: 10px;
  }

  & .dropdown-list-item:hover {
    background-color: ${COLORS.PALE_GRAY_THREE};
    cursor: pointer;
    color: #686868;
  }
  & .dropdown-list-item.focus {
    color: ${COLORS.BURPLE};
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  align-items: center;

  & input {
    flex: 1;
  }

  & .inner-input {
    background-color: #fff;
    font-family: 'exo-regular';
    width: 100%;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1px;
    border: none;
    outline: none;
    cursor: default;
    color: ${COLORS.INPUT_TEXT_COLOR};
  }
`;

const DropdownArrow = styled.div`
  display: inline-block;
  border-top: 8px solid ${COLORS.HEADER_COLOR};
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
  mix-blend-mode: multiply;
  &.focus {
    border-top: 8px solid ${COLORS.BURPLE};
  }
`;

export const DropdownListItem = ({ value, onItemSelected, selectedValue, text, index }) => {
  return (
    <li
      className={`dropdown-list-item ${selectedValue === value ? 'focus' : ''}`}
      onClick={e => onItemSelected && onItemSelected(e, { value, text, child: true, index })}
    >
      {text}
    </li>
  );
};

const selectObject = { value: '', text: '--SELECT--' };

class Dropdown extends React.Component {
  state = {
    listVisible: false,
    selectedItem: selectObject
  };

  componentDidMount() {
    const childrens = Children.toArray(this.props.children);
    if (childrens.length > 0) {
      const element = childrens[0];
      const { value, text } = element.props;
      this.setState({
        selectedItem: {
          value,
          text
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { value: defaultValue } = this.props;
    const { selectedItem: prevSelectedItem = {} } = prevState;
    if (prevProps.value != defaultValue || prevSelectedItem.value != defaultValue) {
      const options = [...this.nativeSelect.options];
      const found = options.find(item => item.value == defaultValue);
      if (!found) return;

      const { text, value } = options.find(item => item.value == defaultValue);

      this.setState(
        {
          selectedItem: {
            text,
            value
          }
        },
        () => {
          const onChangeEvent = new Event('change', { bubbles: true });
          this.nativeSelect.dispatchEvent(onChangeEvent);
        }
      );
    }
  }

  onFocus = () => {
    this.setState({
      listVisible: true
    });
  };

  onBlur = () => {
    setTimeout(() => {
      this.setState({
        listVisible: false
      });
    }, 300);
  };

  onItemSelected = (e, item) => {
    this.setState(
      {
        selectedItem: {
          text: item.text,
          value: item.value
        }
      },
      () => {
        const onChangeEvent = new Event('change', { bubbles: true });
        this.nativeSelect.dispatchEvent(onChangeEvent);
      }
    );
  };

  onChange = e => {
    const { onChange } = this.props;
    const index = e.nativeEvent.target.selectedIndex;
    const { text, value } = e.nativeEvent.target.options[index];
    if (value !== '') {
      this.setState({
        selectedItem: {
          text,
          value
        }
      });
    } else {
      this.setState({
        selectedItem: selectObject
      });
    }
    onChange && onChange(e);
  };

  openList = () => {
    this.hidden.focus();
    this.onFocus();
  };

  generateNativeSelect = () => {
    const {
      optionText: text,
      items = [],
      name = '',
      optionValue: value,
      addSelectElement = true,
      required,
      id
    } = this.props;
    const { selectedItem } = this.state;
    const childrens = Children.toArray(this.props.children);
    return (
      <select
        name={name}
        required={required}
        onChange={this.onChange}
        onMouseDown={e => {
          e.preventDefault();
        }}
        ref={element => (this.nativeSelect = element)}
        value={selectedItem.value}
        style={{ opacity: 0, width: 1 }}
        id={id}
      >
        {addSelectElement && <option text="--SELECT--" value="" />}
        {childrens.map((child, i) => {
          return (
            <option key={i} value={child.props.value}>
              {child.props.text}
            </option>
          );
        })}
        {items.map((item, i) => {
          const itemText = typeof text === 'function' ? text(item) : item[text];
          return (
            <option key={i} value={item[value]}>
              {itemText}
            </option>
          );
        })}
      </select>
    );
  };

  render() {
    const { listVisible, selectedItem } = this.state;
    const { optionText: text, items = [], name = '', optionValue: value, addSelectElement = true } = this.props;
    const childrens = Children.toArray(this.props.children);
    return (
      <Fragment>
        <DropDownContainer tabIndex={0}>
          <input
            className="inner-input"
            value={selectedItem.text}
            readOnly={true}
            ref={element => (this.hidden = element)}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          />
          <DropdownArrow onClick={this.openList} className={`${listVisible ? 'focus' : ''}`} />
          {this.generateNativeSelect()}
        </DropDownContainer>
        {listVisible && (
          <DropDownList className="dropdown-list">
            <ul className="dropdown-list">
              {addSelectElement && (
                <DropdownListItem
                  selectedValue={selectedItem.value}
                  value=""
                  text="--SELECT--"
                  onItemSelected={this.onItemSelected}
                />
              )}
              {childrens.map((child, i) => {
                return (
                  <DropdownListItem
                    key={i}
                    {...child.props}
                    selectedValue={selectedItem.value}
                    onItemSelected={this.onItemSelected}
                  />
                );
              })}
              {items.map((item, i) => {
                const itemText = typeof text === 'function' ? text(item) : item[text];
                return (
                  <DropdownListItem
                    selectedValue={selectedItem.value}
                    value={item[value]}
                    name={name}
                    index={i}
                    key={i}
                    onItemSelected={this.onItemSelected}
                    text={itemText}
                  />
                );
              })}
            </ul>
          </DropDownList>
        )}
      </Fragment>
    );
  }
}

export default InputWrapper(Dropdown);
