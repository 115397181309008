import React, { Component, Fragment } from 'react';
import { Select } from '../../reusable-components';
import translate from '../../translate';
import { PersonService } from '../../services/index';

class ParticipantForm extends Component {
  getEmployees = async (criteria, callback) => {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }

      const employees = await PersonService.getEmployees(criteria);

      callback(employees);
    } catch (e) {
      callback([]);
    }
  };
  handlePositionChange = async (index, positionId) => {
    await this.props.handleArrayChange('participants', index, 'positionId', positionId);
  };
  handleEmployeeChange = (value, index) => {
    const { person = {} } = value;
    const name = `${person.firstName || ''} ${person.lastName || ''}`;
    this.props.handleArrayChange('participants', index, 'employeeId', value.employeeId);
    this.props.handleArrayChange('participants', index, 'name', name);
  };

  render() {
    const { index, participant = {}, positions = [] } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-6 col-sm-8">
            <Select
              isMulti={false}
              placeholder={translate.t('employeeCodeOrName')}
              getOptionValue={e => e.employeeId}
              getOptionLabel={e => {
                const { person = {} } = e;
                return e.name || `${person.firstName || ''} ${person.lastName || ''}`;
              }}
              defaultValue={participant}
              onChange={value => this.handleEmployeeChange(value, index)}
              loadOptions={this.getEmployees}
              required
            />
          </div>
          <div className="col-6 col-sm-4">
            <Select
              placeholder={translate.t('position')}
              options={positions}
              getOptionValue={o => o.positionId}
              getOptionLabel={p => p.positionName}
              value={positions.find(
                position =>
                  position.positionId === (participant.positionId || (participant.contractParticipant || {}).positionId)
              )}
              required
              onChange={e => this.handlePositionChange(index, e.positionId)}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ParticipantForm;
