import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, Select } from '../../reusable-components';

class AddressForm extends Component {
  render() {
    const { address, parentIndex, index, addressTypes, countries, context } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-6 col-md-4 pb-2">
            <Select
              placeholder={translate.t('addressType')}
              options={addressTypes}
              getOptionValue={at => at.addressTypeId}
              getOptionLabel={at => at.name}
              value={addressTypes.find(at => at.addressTypeId === address.addressTypeId)}
              required
              onChange={e => {
                context.handleArrayChildChange(
                  'people',
                  parentIndex,
                  'addresses',
                  index,
                  'addressTypeId',
                  e.addressTypeId
                );
              }}
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('address')}
              autoComplete={'address'}
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'addresses', index, 'street1', e.target.value)
              }
              required
              placeholder={translate.t('address')}
              value={address.street1 || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('city')}
              autoComplete={'city'}
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'addresses', index, 'city', e.target.value)
              }
              required
              placeholder={translate.t('city')}
              value={address.city || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('state')}
              autoComplete={'state'}
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'addresses', index, 'state', e.target.value)
              }
              required
              placeholder={translate.t('state')}
              value={address.state || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <Select
              placeholder={translate.t('country')}
              options={countries}
              getOptionValue={c => c.countryId}
              getOptionLabel={c => c.name}
              value={countries.find(c => c.countryId === address.countryId)}
              required
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'addresses', index, 'countryId', e.countryId)
              }
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('zipCode')}
              autoComplete={'zipCode'}
              onChange={e =>
                context.handleArrayChildChange('people', parentIndex, 'addresses', index, 'zipCode', e.target.value)
              }
              required
              placeholder={translate.t('zipCode')}
              value={address.zipCode || ''}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AddressForm;
