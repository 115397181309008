import React, { Component } from 'react';
import { COLORS } from '../vars/palette';
import styled from 'styled-components';
import getMenuOptions from '../../menu';
import { UserService } from '../../services';
import Logout from '../../auth/components/Logout';

const Wrapper = styled.div`
  background-color: ${COLORS.PALE_GRAY_TWO};
`;

const SuiteText = styled.span`
  color: ${COLORS.AQUA_BLUE};
  font-weight: bold;
  padding-left: 5px;
`;

const HeaderContainer = styled.div`
  height: 80px;
  cursor: pointer;
  font-family: roboto-regular;
  font-size: 16px;
  font-weight: 300;
  color: #394a51;
  align-items: center;
  border-bottom: 2px solid ${COLORS.AQUA_BLUE};
`;

const HomeButton = styled.div`
  display: flex;
  background-color: #ffffff;
  height: 100%;
  cursor: pointer;
  align-items: center;
  padding: 0px 20px;
`;

const UserInfo = styled.div`
  // border-left: 1px solid ${COLORS.PALE_GRAY};
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding: 0px 20px;
  background-color: #fff;
`;

const MenuOption = styled.div`
  display: flex;
  &:hover {
    color: ${COLORS.AQUA_BLUE};
    font-weight: bold;
  }
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  align-items: center;
  height: 100%;
  border-top: ${props => (props.selected ? '5px solid #00ffb3' : '5px solid transparent')};
  transition: border 300ms;
`;

const SubHeaderContainer = styled.div`
  background-color: ${COLORS.AQUA_BLUE}
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const SubOption = styled.div`
  &:hover {
    font-weight: bold;
  }
  font-family: roboto-regular;
  font-size: 16px;
  cursor: pointer;
  color: ${COLORS.PALE_GRAY_TWO};
  padding: 15px;
  font-weight: ${props => (props.selected ? 'bolder' : 'normal')};
`;

const UserName = styled.div`
  color: ${COLORS.HEADER_COLOR};
  font-size: 20px;
  font-family: exo-light;
`;

const RoleName = styled.div`
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  font-size: 12px;
`;

const Sidenav = styled.div`
  width: 70%;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.85);
  margin: 0;
  padding: 0;
  transform: translateX(-99.99%);
  transition: all 0.3s ease;
  overflow: scroll;

  &.active {
    transform: translateX(0%);
  }
`;

const SidenavOption = styled.li`
  display: block;
  color: #fff;
  padding: 20px;
  cursor: pointer;
  font-family: exo-regular;
  border-left: 5px solid transparent;

  &:hover,
  &.selected {
    border-left-color: ${COLORS.AQUA_BLUE};
  }

  & ul {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out;
  }

  & ul.active {
    max-height: 500px;
  }

  & li {
    padding: 15px 0;
    font-family: exo-light;
  }

  & li:hover {
    background-color: #4f5b69;
  }
`;

const SidenavToggler = styled.span`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.5);
  z-index: 99;
  display: none;

  &.active {
    display: block;
  }
`;

class Header extends Component {
  constructor(props) {
    super(props);
    const menuOptions = getMenuOptions();
    const requestedPath = props.history.location.pathname;
    const selectedOption = this.getInitialSelectedOption(requestedPath, menuOptions) || {};
    const selectedSubOption = this.getInitialSelectedSubOption(requestedPath, selectedOption.childs);
    this.state = {
      requestedPath,
      selectedOption,
      selectedSubOption,
      options: menuOptions,
      user: {},
      showSidenav: false
    };
  }
  componentDidMount() {
    const user = UserService.getLoggedUser();
    this.setState({ user });
  }

  getInitialSelectedOption = (requestedPath, options) => {
    return options.find(o => (o.childs || []).some(so => so.url === requestedPath));
  };

  getInitialSelectedSubOption = (requestedPath, opts) => {
    return (opts || []).find(o => o.url === requestedPath);
  };

  selectOption = opt => {
    this.setState({
      selectedOption: opt
    });

    !opt.hasChilds && this.props.history.push(opt.url);
  };

  selectSubOption = opt => {
    this.setState({
      selectedSubOption: opt
    });
    this.props.history.push(opt.url);
  };

  toggleShowSidenav = () => {
    this.setState({ showSidenav: !this.state.showSidenav });
  };

  render() {
    const { selectedOption, selectedSubOption, user, showSidenav } = this.state;
    const options = (this.state.options || []).map(o => {
      return { ...o, hasChilds: o.childs && o.childs.length };
    });

    return (
      <Wrapper>
        <div className="d-md-none">
          <Sidenav className={showSidenav ? 'active' : ''}>
            <ul className="pt-3">
              {options.map(o => (
                <SidenavOption
                  key={o.feature}
                  className={o.feature === selectedOption.feature ? 'selected' : ''}
                  onClick={() => {
                    !o.hasChilds && this.toggleShowSidenav();
                    this.selectOption(o);
                  }}
                >
                  {o.title}
                  <ul className={o.feature === selectedOption.feature ? 'active' : ''}>
                    {o.childs.map(so => (
                      <li
                        key={so.feature}
                        className="px-3 mt-2"
                        onClick={() => {
                          this.toggleShowSidenav();
                          this.selectSubOption(so);
                        }}
                      >
                        <i className="fa fa-chevron-right pr-2" />
                        {so.title}
                      </li>
                    ))}
                  </ul>
                </SidenavOption>
              ))}
            </ul>
          </Sidenav>
          <SidenavToggler className={showSidenav ? 'active' : ''} onClick={() => this.toggleShowSidenav()} />
        </div>
        <HeaderContainer className="row justify-content-between">
          <div
            className="col-2 col-sm-1 d-flex d-md-none align-items-center justify-content-center"
            style={{ backgroundColor: '#fff', height: '100%' }}
            onClick={() => this.toggleShowSidenav()}
          >
            <i className="fa fa-bars" style={{ cursor: 'pointer' }} />
          </div>
          <HomeButton className="col-4 col-sm-5 col-md-auto" onClick={() => this.props.history.replace('/')}>
            {/* <div>Color</div> */}
            <SuiteText>Cluby</SuiteText>
          </HomeButton>
          {options.map(o => (
            <MenuOption
              className="col-md-auto d-none d-md-flex"
              key={o.feature}
              selected={o.feature === (selectedOption || {}).feature}
              onClick={() => this.selectOption(o)}
            >
              {o.title}
            </MenuOption>
          ))}
          <UserInfo className="col-6 col-md-auto">
            <UserName>{user && user.fullname}</UserName>
            <RoleName>{user && user.role && user.role.name}</RoleName>
            <div className="pb-5">
              <Logout history={this.props.history} />
            </div>
          </UserInfo>
        </HeaderContainer>
        <SubHeaderContainer className="row d-none d-md-flex">
          {(selectedOption.childs || []).map(o => (
            <SubOption
              className="col-auto"
              key={o.feature}
              selected={o.feature === (selectedSubOption || {}).feature}
              onClick={() => this.selectSubOption(o)}
            >
              {o.title}
            </SubOption>
          ))}
        </SubHeaderContainer>
      </Wrapper>
    );
  }
}

export default Header;
