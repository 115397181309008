import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Paragraph, Label, Subtitle } from '../../reusable-components/styles';
import styled from 'styled-components';

export const OwnersDataBox = styled.div`
  border: 2px solid #dee7ee;

  & p {
    color: #394a51;
    line-height: 22px;
    letter-spacing: 0.06em;
    font-family: 'exo-regular';
    font-size: 16px;
  }

  & a {
    text-decoration: none;
  }

  & i {
    font-size: 25px;
    font-weight: bold;
    color: #8295a6;
  }
`;

class OwnersDataView extends Component {
  constructor(props) {
    super(props);
    this.state = { showMoreInfo: false };
  }
  toggleInfo = () => {
    this.setState({ showMoreInfo: !this.state.showMoreInfo });
  };
  render() {
    const { owner = {}, styles = {} } = this.props;
    const { showMoreInfo = false } = this.state;
    const { language = {}, country = {}, livingCountry = {}, phones = [], emails = [], addresses = [] } = owner;

    return (
      <Fragment>
        <OwnersDataBox className="p-3" style={styles}>
          <div className="row">
            <div className="col-auto">
              <Label>{translate.t('name')}</Label>
              <Paragraph>{`${owner.firstName || ''} ${owner.lastName || ''}`}</Paragraph>
            </div>
            <div className="col-auto">
              <Label>{translate.t('language')}</Label>
              <Paragraph>{(language || {}).description}</Paragraph>
            </div>
            <div className="col-auto">
              <Label>{translate.t('nationality')}</Label>
              <Paragraph>{(country || {}).nationality || (country || {}).name}</Paragraph>
            </div>
            <div className="col-auto">
              <Label>{translate.t('resident')}</Label>
              <Paragraph>{livingCountry ? livingCountry.name : ''}</Paragraph>
            </div>
            <div className="col-auto">
              <Label style={{ color: 'transparent' }}>{'.'}</Label>
              <Paragraph style={{ fontSize: 12 }}>
                <a onClick={this.toggleInfo} href={'#' + owner.personId}>
                  {showMoreInfo ? ' - ' + translate.t('lessInfo') : ' + ' + translate.t('moreInfo')}
                </a>
              </Paragraph>
            </div>
          </div>
          {showMoreInfo && (
            <Fragment>
              <Subtitle style={{ marginTop: '25px', fontSize: '16px', color: '#394a51' }}>
                <div>
                  <i className="fa fa-angle-right pr-2" />
                  {` ${translate.t('phones')}`}
                </div>
              </Subtitle>
              <div className="row pl-4">
                {phones
                  .slice(0, 3)
                  .sort(
                    (a, b) =>
                      a.contactPhoneTypeId > b.contactPhoneTypeId
                        ? 1
                        : a.contactPhoneTypeId < b.contactPhoneTypeId
                          ? -1
                          : 0
                  )
                  .map(phone => {
                    return (
                      <div key={phone.contactPhoneId} className="col-auto col-md-3">
                        <Label>{phone.contactPhoneType.name}</Label>
                        <a href={`tel:${phone.number}`}>
                          <Paragraph>{phone.number}</Paragraph>
                        </a>
                      </div>
                    );
                  })}
              </div>
              <Subtitle style={{ marginTop: '25px', fontSize: '16px', color: '#394a51' }}>
                <div>
                  <i className="fa fa-angle-right pr-2" />
                  {` ${translate.t('emails')}`}
                </div>
              </Subtitle>
              <div className="row pl-4">
                {emails
                  .slice(0, 3)
                  .sort((a, b) => (a.emailTypeId > b.emailTypeId ? 1 : a.emailTypeId < b.emailTypeId ? -1 : 0))
                  .map(email => {
                    return (
                      <div key={email.emailId} className="col-auto">
                        <Label>{email.emailType.name}</Label>
                        <a href={`mailto:${email.value}`}>
                          <Paragraph>{email.value}</Paragraph>
                        </a>
                      </div>
                    );
                  })}
              </div>
              <Subtitle style={{ marginTop: '25px', fontSize: '16px', color: '#394a51' }}>
                <div>
                  <i className="fa fa-angle-right pr-2" />
                  {` ${translate.t('addresses')}`}
                </div>
              </Subtitle>
              <div className="row pl-4 mb-3">
                {addresses
                  .slice(0, 3)
                  .sort((a, b) => (a.addressTypeId > b.addressTypeId ? 1 : a.addressTypeId < b.addressTypeId ? -1 : 0))
                  .map(address => {
                    return (
                      <div key={address.addressId} className="col-auto">
                        <Label>{address.addressType.name}</Label>
                        <Paragraph>{`${address.street1} ${address.street2 || ''} ${address.zipCode || ''}`}</Paragraph>
                      </div>
                    );
                  })}
              </div>
            </Fragment>
          )}
        </OwnersDataBox>
      </Fragment>
    );
  }
}
export default OwnersDataView;
