import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Modal } from '../../reusable-components';
import { Label, Paragraph, Badge } from '../../reusable-components/styles';
import moment from 'moment';

class ChargebackView extends Component {
  render() {
    const { chargeback, show, close } = this.props;

    return (
      <Fragment>
        <Modal
          className="modal-sm"
          show={show}
          close={close}
          title={translate.t('chargeback')}
          modalBodyStyles={{ paddingBottom: '25px' }}
        >
          <div className="row mb-3">
            <div className="col-md-3">
              <Label>{translate.t('date')}</Label>
              <Paragraph>{moment(chargeback).format('YYYY-MM-DD')}</Paragraph>
            </div>
            <div className="col-md-3">
              <Label>{translate.t('amount')}</Label>
              <Paragraph>{translate.l('currency', chargeback.amount || 0)}</Paragraph>
            </div>
            <div className="col-md-6">
              <Label>{translate.t('authorizedBy')}</Label>
              <Paragraph>{chargeback.authorizedByName}</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Label>{translate.t('employees')}</Label>
              <div>
                {chargeback.employees &&
                  chargeback.employees.length &&
                  chargeback.employees.map(
                    employee => employee.name && <Badge key={employee.employeeId}>{employee.name}</Badge>
                  )}
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <Label>{translate.t('reason')}</Label>
              <Paragraph style={{ fontFamily: 'roboto-regular' }}>{chargeback.reason}</Paragraph>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default ChargebackView;
