import React from 'react';
import translate from '../../translate';

const styles = {
  wrapper: {
    width: '777px',
    marginTop: 50,
    fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '13px',
    color: '#676a6c',
    padding: 40,
    textAlign: 'justify'
  },
  content: {
    marginTop: 50
  },
  title: {
    textAlign: 'center',
    marginTop: 50
  },
  signature: {
    padding: '0px 30px',
    borderTopColor: 'black',
    borderTopWidth: '1px',
    borderTopStyle: 'solid'
  },
  memberSignature: {
    padding: '0px 60px',
    borderTopColor: 'black',
    borderTopWidth: '1px',
    borderTopStyle: 'solid'
  }
};


const PrintableAnnex = ({ annex, contract, ...props }) => {
  const { number, code, people = [] } = contract;
  const { detail, date = new Date(), signatureType, signedByEmployee } = annex || {};
 
  return ( 
    <div id={`printable-annex`} style={{display: 'none'}}>
      <style dangerouslySetInnerHTML={{__html: `
        @page { size: auto;  margin: 0mm; }
      `}} />
      <div style={styles.wrapper}>
        <h4>{translate.strftime(date || new Date(), '')}</h4>
        <h4>{translate.t('contract')}: {number}{code}</h4>
        <h4>{translate.t('members')}:</h4>
        {
          people.map(p => {
            const { personId, name } = p;
            return (
              <p key={personId}>{name}</p>
            )
          })
        } 
        <h2 style={styles.title}>{translate.t('appendant')}</h2>
        <div style={styles.content} dangerouslySetInnerHTML={{__html: detail }} />
        <div style={{ display: signatureType === 'EMP' ? 'block' : 'none' }}>
          <h4 style={{ textAlign: 'center', marginTop: 100 }}>
            <span style={styles.signature}>
              { signedByEmployee && signedByEmployee.person ? `${signedByEmployee.person.firstName} ${signedByEmployee.person.lastName}`  : translate.t('authorizedSignature') }
            </span>
          </h4>        
        </div>
        <div style={{ display: signatureType === 'MEM' ? 'flex' : 'none', alignItems: 'center', justifyContent: 'space-around' }}>
          <h4 style={{ textAlign: 'center', marginTop: 100 }}><span style={styles.memberSignature}>{(contract.owner && contract.owner.name) || ''}</span></h4>        
          <h4 style={{ textAlign: 'center', marginTop: 100 }}><span style={styles.memberSignature}>{translate.t('member')}</span></h4>        
        </div>
      </div>
    </div>
  )
};

export default PrintableAnnex;