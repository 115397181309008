import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Modal } from '../../reusable-components';
import { Label, Paragraph } from '../../reusable-components/styles';

const ANNEX_TYPE_ANNEX = 'AN';
const SIGNATURE_TYPE_MEMBER = 'MEM';

class AnnexDetailView extends Component {

  render(){
    const { annex = {}, show, close } = this.props;
    const name = annex.annexType ? annex.annexType.name : translate.t('annexDetail');
    const type = annex.annexType ?
      translate.t(annex.annexType.type === ANNEX_TYPE_ANNEX ? 'annex' : 'authorization' ) :
      translate.t('notAvailableAbbrev');
    const signatureType = annex.signatureType ?
      translate.t(annex.signatureType === SIGNATURE_TYPE_MEMBER ? 'member' : 'employee') :
      translate.t('notAvailableAbbrev');
    const authorizedByEmployee = (annex.authorizedByEmployee && annex.authorizedByEmployee.person && annex.authorizedByEmployee.person.name) ||
      translate.t('notAvailableAbbrev');
    const signedByEmployee = (annex.signedByEmployee && annex.signedByEmployee.person && annex.signedByEmployee.person.name) ||
      translate.t('notAvailableAbbrev');
    return (
      <Fragment>
        <Modal className='modal-sm' show={show} close={close} title={name} modalBodyStyles={{ paddingBottom: '25px'}}>
          <div className="row mb-3">
            <div className="col-md-6">
              <Label>{translate.t('type')}</Label>
              <Paragraph>{type}</Paragraph>
            </div>
            <div className="col-md-6">
              <Label>{translate.t('signatureType')}</Label>
              <Paragraph>{signatureType}</Paragraph>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-6">
              <Label>{translate.t('authorizedBy')}</Label>
              <Paragraph>{authorizedByEmployee}</Paragraph>
            </div>
            <div className="col-md-6">
              <Label>{translate.t('signedBy')}</Label>
              <Paragraph>{signedByEmployee}</Paragraph>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Label>{translate.t('detail')}</Label>
              <Paragraph style={{fontFamily:'roboto-regular'}} dangerouslySetInnerHTML={{__html:annex.detail}}/>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default AnnexDetailView;

