import React from 'react'
import PropTypes from 'prop-types'

const Titles = ({title, subtitle}) => {
  return [
    typeof title === 'string' && title ? <h4 key="box-title" className="title">{title}</h4> : null,
    typeof subtitle === 'string' && title ? <h4 key="box-sub-title" className="sub-title">{subtitle}</h4> : null,
  ]
}

Titles.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

Titles.defaultProps = {
  title: '',
  subtitle: ''
}

export default Titles
