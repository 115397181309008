import React, { Component, Fragment } from 'react';

class Reports extends Component {
  componentDidMount() {
    window.tableau._createNewVizesAndStartLoading();
  }

  render() {
    return (
      <Fragment>
        <div
          className="tableauPlaceholder embed-responsive col-md-12 justify-content-center"
          style={{ overflow: 'scroll' }}
        >
          <object className="tableauViz embed-responsive-item" width="100%" height="827" style={{ display: 'none' }}>
            <param name="host_url" value="https://us-east-1.online.tableau.com/" />
            <param name="embed_code_version" value="3" />
            <param name="site_root" value="&#47;t&#47;xhinola" />
            <param name="name" value="Invitation&#47;Dashboard1" />
            <param name="tabs" value="no" />
            <param name="toolbar" value="yes" />
            <param name="showAppBanner" value="false" />
            <param name="filter" value="iframeSizedToWindow=true" />
          </object>
        </div>
      </Fragment>
    );
  }
}

export default Reports;
