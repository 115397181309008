import React, { Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import translate from '../../translate';
import { Table, TextInput, Button, DatePicker, Dropdown } from '../../reusable-components';
import ContractQuickView from '../components/ContractQuickView';
import ContractService from '../../services/contractService';
import { COLORS } from '../../reusable-components/vars/palette';
import { SpanLink } from '../../reusable-components/styles';

const tableFields = [
  {
    header: 'Contract',
    column: contract => (contract.serial ? contract.number + '-' + contract.serial : contract.number),
    size: '10%',
    report: true,
    sort: 'number'
  },
  {
    header: 'Member',
    column: 'member',
    size: '20%',
    maxWidth: 150,
    report: true,
    sort: 'member'
  },
  {
    header: 'Language',
    column: 'language',
    size: '10%',
    report: true,
    sort: 'language',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: 'Status',
    column: 'status',
    size: '10%',
    report: true,
    sort: 'status',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: 'Product',
    column: 'product',
    size: '10%',
    report: true,
    sort: 'product',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: 'Volume',
    column: 'volume',
    size: '10%',
    type: 'money',
    report: true,
    sort: 'volume'
  },
  {
    header: 'Season',
    column: 'season',
    size: '10%',
    report: true,
    sort: 'season',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: 'Unit',
    column: 'unit',
    size: '10%',
    report: true,
    sort: 'unit',
    hideOnSplit: true,
    hideOnSmallScreens: true
  },
  {
    header: 'Weeks',
    column: 'weeks',
    size: '10%',
    report: true,
    sort: 'weeks',
    hideOnSplit: true,
    hideOnSmallScreens: true
  }
];

const Title = styled.div`
  margin-top: 10px;
  font-family: exo-light;
  font-size: 36px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.HEADER_COLOR};
`;

const FiltersContainer = styled.div`
  background-color: ${COLORS.PALE_GRAY_TWO};
  padding: 15px;
  margin-bottom: 25px;
`;

const Control = styled.div`
  padding: 5px !important;
  display: ${props => (props.hide ? 'none' : 'block')};
`;

class Contracts extends Component {
  constructor(props) {
    super(props);

    this.baseState = {
      contracts: { data: [] },
      splitView: false,
      tableFields: tableFields,
      selectedContract: {},
      filters: {
        number: '',
        member: '',
        statusId: 0,
        galleryId: 0,
        productId: 0,
        dateType: '',
        dateTo: '',
        dateFrom: '',
        itemsPerPage: 20,
        pageNumber: 1,
        showAllFilters: false
      }
    };

    this.state = {
      ...this.baseState,
      contracts: { data: [] },
      products: [],
      statuses: [],
      galleries: []
    };
  }

  dateTypes = [{ value: 'SD', text: 'Sold date' }, { value: 'PD', text: 'Processable date' }];

  canSearch = () => {
    return Object.keys(this.state.filters)
      .filter(k => k !== 'itemsPerPage' && k !== 'pageNumber' && k !== 'dateType')
      .some(k => this.state.filters[k]);
  };

  onPaginateSearch = pageNumber => {
    this.searchContract(pageNumber);
  };

  search = () => {
    this.searchContract(1);
  };

  searchContract = async (pageNumber, sort = '', direction = '') => {
    if (!this.canSearch()) {
      return alert('Select some filters');
    }
    const contracts = await ContractService.getListByQuery({
      ...this.state.filters,
      pageNumber,
      sortColumn: sort,
      sortDirection: direction
    });

    this.setState({
      contracts
    });
  };

  handleChange = (name, value) => {
    this.setState(prevState => ({
      ...prevState,
      filters: {
        ...prevState.filters,
        [name]: value
      }
    }));
  };

  clear = () => {
    this.setState(this.baseState);
  };

  onRowClick = async row => {
    const contract = await ContractService.getById(row.contractId);
    this.setState({
      splitView: true,
      tableFields: this.state.tableFields.filter(f => !f.hideOnSplit),
      selectedContract: contract
    });
  };

  onRowDoubleClick = contract => {
    return this.props.history.push(`/contracts/${contract.contractId}/update`);
  };

  onSplitViewClose = () => {
    this.setState({
      splitView: false,
      tableFields: this.baseState.tableFields
    });
  };

  onSort = async (direction, column) => {
    if (!this.state.contracts.data.length || !direction) {
      return;
    }
    await this.searchContract(1, column, direction);
  };

  onKeyUp = e => {
    e.keyCode === 13 && this.search();
  };

  toggleShowAllFilters = () => {
    this.setState({ showAllFilters: !this.state.showAllFilters });
  };

  async componentDidMount() {
    const products = await ContractService.getProducts();
    const galleries = await ContractService.getGalleries();
    const statuses = await ContractService.getStatuses();

    this.setState({
      products,
      statuses,
      galleries
    });
  }

  render() {
    const {
      contracts,
      statuses,
      products,
      galleries,
      filters,
      splitView,
      selectedContract,
      showAllFilters
    } = this.state;
    const isMobile = window.innerWidth < 768;
    return (
      <div>
        <Title className={splitView ? 'd-none d-md-block' : ''}>Search Contracts</Title>
        <div className="row my-3">
          {(!isMobile || !splitView) && (
            <div className={`pb-3 ${splitView ? 'col-12 col-md-6 px-0 pr-md-3' : 'col-12'}`}>
              <FiltersContainer splitView={splitView}>
                <div className="row no-gutters">
                  <Control className={`col-12 col-md-6 ${splitView ? '' : 'col-lg-3'}`}>
                    <TextInput
                      label="Contract"
                      onChange={e => this.handleChange('number', e.target.value)}
                      placeholder="Contract"
                      value={filters.number}
                      onKeyUp={this.onKeyUp}
                    />
                  </Control>
                  <Control className={`col-12 col-md-6 ${splitView ? '' : 'col-lg-3'}`}>
                    <TextInput
                      label="Member name"
                      onChange={e => this.handleChange('member', e.target.value)}
                      placeholder="Member name"
                      value={filters.member}
                      onKeyUp={this.onKeyUp}
                    />
                  </Control>
                  <Control className="col-12 col-md-4 col-lg-2" hide={splitView || (isMobile && !showAllFilters)}>
                    <Dropdown
                      label="Date Type"
                      name="dateType"
                      items={this.dateTypes}
                      optionValue="value"
                      optionText="text"
                      value={filters.dateType}
                      onChange={e => this.handleChange(e.target.name, e.target.value)}
                    />
                  </Control>
                  <Control
                    className="col-12 col-sm-6 col-md-4 col-lg-2"
                    hide={splitView || (isMobile && !showAllFilters)}
                  >
                    <DatePicker
                      label="From"
                      autoComplete="off"
                      maxDate={moment()}
                      name="dateFrom"
                      onSelect={date => this.handleChange('dateFrom', date ? moment(date).format('YYYY-MM-DD') : '')}
                      value={filters.dateFrom}
                    />
                  </Control>
                  <Control
                    className="col-12 col-sm-6 col-md-4 col-lg-2"
                    hide={splitView || (isMobile && !showAllFilters)}
                  >
                    <DatePicker
                      label="To"
                      autoComplete="off"
                      minDate={moment(filters.dateFrom) || moment()}
                      maxDate={moment()}
                      name="dateTo"
                      onSelect={date => this.handleChange('dateTo', date ? moment(date).format('YYYY-MM-DD') : '')}
                      value={filters.dateTo}
                    />
                  </Control>
                </div>

                <div className="row no-gutters">
                  <Control className="col-12 col-md-4 col-lg-3" hide={splitView || (isMobile && !showAllFilters)}>
                    <Dropdown
                      label="Status"
                      name="statusId"
                      items={statuses}
                      optionValue="statusId"
                      optionText="name"
                      value={filters.statusId}
                      onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                    />
                  </Control>
                  <Control className="col-12 col-md-4 col-lg-3" hide={splitView || (isMobile && !showAllFilters)}>
                    <Dropdown
                      label="Product Type"
                      name="productId"
                      items={products}
                      optionValue="productId"
                      optionText="name"
                      value={filters.productId}
                      onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                    />
                  </Control>
                  <Control className="col-12 col-md-4 col-lg-2" hide={splitView || (isMobile && !showAllFilters)}>
                    <Dropdown
                      label="Gallery"
                      items={galleries}
                      optionValue="galleryId"
                      optionText="name"
                      name="galleryId"
                      value={filters.galleryId}
                      onChange={e => this.handleChange(e.target.name, Number(e.target.value))}
                    />
                  </Control>
                  <Control className="col-12 d-md-none">
                    <SpanLink onClick={this.toggleShowAllFilters}>
                      {showAllFilters ? ' - ' + translate.t('lessFilters') : ' + ' + translate.t('moreFilters')}
                    </SpanLink>
                  </Control>
                  <Control className={`col-12 col-sm-6 ${splitView ? '' : 'col-lg-2'}`}>
                    <Button className="m-0" style={{ width: '100%', height: '100%' }} onClick={this.clear}>
                      Clear
                    </Button>
                  </Control>
                  <Control className={`col-12 col-sm-6 ${splitView ? '' : 'col-lg-2'}`}>
                    <Button
                      className="m-0"
                      style={{ width: '100%', height: '100%' }}
                      onClick={this.search}
                      primary="true"
                    >
                      Search
                    </Button>
                  </Control>
                </div>
              </FiltersContainer>

              <div className="row">
                <div className="col-12">
                  <Table
                    tableFields={this.state.tableFields}
                    reportData={contracts.data}
                    data={contracts.data}
                    reportFilename={'Contracts ' + new Date() + '.csv'}
                    totalItems={contracts.totalItems}
                    itemsPerPage={20}
                    onSort={this.onSort}
                    onPaginateSearch={this.onPaginateSearch}
                    onRowClick={this.onRowClick}
                    onRowDoubleClick={this.onRowDoubleClick}
                  />
                </div>
              </div>
            </div>
          )}
          {splitView && (
            <div className="col-12 col-md-6" style={{ flex: 1, backgroundColor: '#edf2f6' }}>
              <ContractQuickView
                onClose={this.onSplitViewClose}
                onShowContractDetail={this.onRowDoubleClick}
                contract={selectedContract}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Contracts;
