import React, { Component} from 'react'

class Admin extends Component {
  render() {
    return (
      <div>
        Admin view
      </div>
    )
  }
}


export default Admin
