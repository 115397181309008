import React, { Component } from 'react';
import translate from '../../translate';
import moment from 'moment';
import styled from 'styled-components';
import { Signature } from '../../reusable-components';

const OWNER_ACRONYM = 'OWN';
const CO_OWNER_ACRONYM = 'COWN';

const PrintableDiv = styled.div`
  font-family: 'open sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 13px;
  padding: 0 60px;

  & strong {
    font-weight: bold;
  }

  & h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin: 15px 0;
  }

  & p {
    margin: 13px 0;
    text-align: justify;
  }

  & .clauseLabel {
    display: inline-block;
    min-width: 200px;
  }

  & .signature-section {
    page-break-inside: avoid;
  }

  & .label-container {
    color: #000;
  }
`;

const Label = styled.span`
  font-weight: bold;
  display: inline-block;
  margin-bottom: 7px;
`;

const DataText = styled.span`
  display: block;
  margin-bottom: 5px;
`;

const InlineDataText = styled.span`
  display: inline-block;
  margin-bottom: 5px;
  margin-left: 3px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const TwoColRow = styled.div`
  & > div {
    width: 50%;
  }
`;

const Col = styled.div`
  display: inline-block;
`;

const PrintSignature = styled.span`
  padding: 5px 40px;
  border-top: 1px solid black;
  text-align: center;
  margin-top: 140px;
  display: inline-block;
  font-weight: bold;
  min-width: 300px;
  max-width: 100%;
  word-break: break-all;
  word-wrap: break-word;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap
  justify-content: space-around;
`;

class PrintableContract extends Component {
  render() {
    const { contract, template, onChangeSignature } = this.props;
    const { number, people = [], contractChanges = [] } = contract;
    const change = contractChanges && contractChanges.length && contractChanges[0];
    const contractLoan = contract.contractLoans && contract.contractLoans.length ? contract.contractLoans[0] : {};
    const product = change
      ? change.contractProducts && change.contractProducts.length && change.contractProducts[0]
      : {};
    const contractPrice = change.contractPrices && change.contractPrices.length ? change.contractPrices[0] : {};
    const owner =
      people.find(
        (p, index) =>
          ((p.contractPerson || {}).personTitle && p.contractPerson.personTitle.acronym === OWNER_ACRONYM) ||
          index === 0
      ) || {};
    const coowner = people.find(
      (p, index) =>
        ((p.contractPerson || {}).personTitle && p.contractPerson.personTitle.acronym === CO_OWNER_ACRONYM) || index > 0
    );

    const locale = owner.language ? owner.language.code.toLowerCase() : 'en';
    const address = owner.addresses && owner.addresses.length ? owner.addresses[0] : {};
    const { phones, emails } = owner;
    const contractDate = moment(contract.creationDate)
      .locale(locale)
      .format('MMMM DD, YYYY');
    const contractValidity = moment(contract.creationDate).diff(
      product.expirationDate || contract.creationDate,
      'years'
    );
    const dpRemaining =
      contract.promissoryNotes && contract.promissoryNotes.reduce((acc, p) => acc + parseFloat(p.amount), 0);

    if (template && template.sections) {
      template.sections = template.sections.map(section => {
        let content = section.content;

        content = content.replace(/#membershipWeeks#/g, product.membershipWeeks);
        content = content.replace(
          /#membershipTypeName#/g,
          product.membershipType ? product.membershipType.name : translate.t('notAvailableAbbrev', { locale })
        );
        content = content.replace(
          /#unitTypeName#/g,
          product.roomType ? product.roomType.name : translate.t('notAvailableAbbrev', { locale })
        );
        content = content.replace(/#membershipWeeksPerYear#/g, product.weeksUsagePerYear);
        content = content.replace(/#contractValidity#/g, contractValidity);
        content = content.replace(/#priceOfPurchase#/g, translate.l('currency', contractPrice.volume || 0));
        content = content.replace(/#closingCost#/g, translate.l('currency', contractPrice.closingCost || 0));
        content = content.replace(/#totalInitialPaid#/g, translate.l('currency', contractPrice.paidToday || 0));
        content = content.replace(/#dpRemaining#/g, translate.l('currency', dpRemaining || 0));
        content = content.replace(/#financedAmount#/g, translate.l('currency', contractLoan.originalLoanAmount || 0));
        content = content.replace(/#contractDate#/g, contractDate);
        content = content.replace(/#monthlyPayment#/g, translate.l('currency', contractLoan.paymentAmount || 0));
        content = content.replace(
          /#annualFeeYear#/g,
          product.annualFeeDate || translate.t('notAvailableAbbrev', { locale })
        );
        content = content.replace(/#annualFeeAmount#/g, product.annualFee);

        return {
          ...section,
          content
        };
      });
    }

    const titleSection =
      template && template.sections && template.sections.find(section => section.acronym === 'CTIENG').content;
    const introSection =
      template && template.sections && template.sections.find(section => section.acronym === 'CINENG').content;
    const agreetmentSection =
      template && template.sections && template.sections.find(section => section.acronym === 'CMAENG').content;
    const clauses =
      template && template.sections && template.sections.filter(section => section.name.startsWith('Contract Clause'));

    return (
      <PrintableDiv>
        <p>
          {translate.t('contract', { locale })} # <strong>{number}</strong>
        </p>
        <div className="mt-3" style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: titleSection }} />
        <div dangerouslySetInnerHTML={{ __html: introSection }} />
        <h3 style={{ textTransform: 'uppercase' }}>{translate.t('memberInfo', { locale })}</h3>
        <Row className="mb-3">
          <Col>
            <Label>{translate.t('name', { locale })}</Label>
            <DataText>{`${coowner ? '1 -' : ''} ${owner.name}`}</DataText>
            {coowner && <DataText>{`2 - ${coowner.name}`}</DataText>}
          </Col>
          <Col>
            <Label>{translate.t('nationality', { locale })}</Label>
            <DataText>{owner.country ? owner.country.nationality || owner.country.name : ''}</DataText>
            {coowner && (
              <DataText>{coowner.country ? coowner.country.nationality || coowner.country.name : ''}</DataText>
            )}
          </Col>
          <Col>
            <Label>{translate.t('civilStatus', { locale })}</Label>
            <DataText>{owner.civilStatus ? owner.civilStatus.name : ''}</DataText>
            {coowner && <DataText>{coowner.civilStatus ? coowner.civilStatus.name : ''}</DataText>}
          </Col>
        </Row>
        <Row>
          <Col>
            <Label>{translate.t('address', { locale })}:</Label>
            <InlineDataText>{`${address.street1} ${address.street2 ? address.street2 : ''}`}</InlineDataText>
          </Col>
        </Row>
        <TwoColRow>
          <Col>
            <Label>{translate.t('city', { locale })}:</Label>
            <InlineDataText>{address.city}</InlineDataText>
          </Col>
          <Col>
            <Label>{translate.t('state', { locale })}:</Label>
            <InlineDataText>{address.state}</InlineDataText>
          </Col>
        </TwoColRow>
        <TwoColRow>
          <Col>
            <Label>{translate.t('country', { locale })}:</Label>
            <InlineDataText>{address.country ? address.country.name : ''}</InlineDataText>
          </Col>
          <Col>
            <Label>{translate.t('zipCode', { locale })}:</Label>
            <InlineDataText>{address.zipCode}</InlineDataText>
          </Col>
        </TwoColRow>
        {phones &&
          phones.length && (
            <TwoColRow>
              {phones.map(phone => (
                <Col key={phone.contactPhoneId}>
                  <Label>{(phone.contactPhoneType || {}).name}:</Label>
                  <InlineDataText>{phone.number}</InlineDataText>
                </Col>
              ))}
            </TwoColRow>
          )}
        {emails &&
          emails.length && (
            <TwoColRow>
              {emails.map((email, index) => (
                <span className="label" key={email.emailId}>
                  <Label>{`${translate.t('email', { locale })} ${index + 1}`}:</Label>
                  <InlineDataText>{email.value}</InlineDataText>
                </span>
              ))}
            </TwoColRow>
          )}
        <h3 style={{ textAlign: 'center', textTransform: 'uppercase' }}>{translate.t('clauses', { locale })}</h3>
        {clauses &&
          clauses.length &&
          clauses.map(clause => (
            <div key={clause.documentTemplateSectionId} dangerouslySetInnerHTML={{ __html: clause.content }} />
          ))}
        <div className="signature-section">
          <div dangerouslySetInnerHTML={{ __html: agreetmentSection }} />
          <p>
            {translate.t('signedOn', { locale })} <strong>{contractDate}</strong>
          </p>
          {owner.personId && (
            <FlexContainer>
              <div>
                <div className="d-print-none">
                  <Signature
                    value={(owner.contractPerson || {}).signature}
                    placeholder={`${owner.name} (${translate.t('owner', { locale })})`}
                    onChange={signature => onChangeSignature(owner.personId, signature)}
                    required
                  />
                </div>
                <div className="d-none d-print-inline-block">
                  <div style={{ textAlign: 'center' }}>
                    <PrintSignature>{owner.name}</PrintSignature>
                    <span className="d-block">({translate.t('owner', { locale })})</span>
                  </div>
                </div>
              </div>
              {coowner && (
                <div>
                  <div className="d-print-none">
                    <Signature
                      value={(coowner.contractPerson || {}).signature}
                      placeholder={`${coowner.name} (${translate.t('coOwner', { locale })})`}
                      onChange={signature => onChangeSignature(coowner.personId, signature)}
                      required
                    />
                  </div>
                  <div className="d-none d-print-inline-block">
                    <div style={{ textAlign: 'center' }}>
                      <PrintSignature>{coowner.name}</PrintSignature>
                      <span className="d-block">({translate.t('coOwner', { locale })})</span>
                    </div>
                  </div>
                </div>
              )}
            </FlexContainer>
          )}
        </div>
      </PrintableDiv>
    );
  }
}
export default PrintableContract;
