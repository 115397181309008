import React, { Component, Fragment } from 'react';
import { TextInput, Select, DatePicker, Textarea } from '../../reusable-components';
import translate from '../../translate';
import { PersonService } from '../../services/index';
import moment from 'moment';

class ChargebackForm extends Component {
  async getEmployees(criteria, callback) {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }
      const employees = await PersonService.getEmployees(criteria);

      callback(employees);
    } catch (e) {
      callback([]);
    }
  }

  render() {
    const { handleArrayChange, index, chargeBack = {} } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <Select
              isMulti
              placeholder={translate.t('employeeCodeOrName')}
              getOptionValue={e => e.employeeId}
              getOptionLabel={e => (e.person || {}).name || e.name}
              defaultValue={chargeBack.employees || []}
              cacheOptions
              onChange={a =>
                handleArrayChange(
                  'chargeBacks',
                  index,
                  'employees',
                  a.map(e => {
                    return { employeeId: e.employeeId, name: (e.person || {}).name };
                  })
                )
              }
              loadOptions={this.getEmployees}
              required
            />
          </div>

          <div className="col-md-4 mt-3">
            <DatePicker
              label={translate.t('date')}
              autoComplete="off"
              name="datetime"
              onSelect={date =>
                handleArrayChange('chargeBacks', index, 'datetime', date ? moment(date).format('YYYY-MM-DD') : '')
              }
              required
              value={chargeBack.datetime ? moment(chargeBack.datetime).format('YYYY-MM-DD') : ''}
            />
          </div>
          <div className="col-md-4 mt-3">
            <TextInput
              label={translate.t('amount')}
              required
              onChange={e => handleArrayChange('chargeBacks', index, 'amount', e.target.value)}
              pattern="^0*[1-9]\d*$"
              placeholder="0"
              type="number"
              step={0.01}
              min={1}
              value={(chargeBack || {}).amount}
            />
          </div>
          <div className="col-md-4 mt-3">
            <Select
              isMulti={false}
              placeholder={translate.t('authorizedBy')}
              getOptionValue={e => e.employeeId || e.authorizedBy}
              getOptionLabel={e => (e.person || {}).name || e.authorizedByName}
              defaultValue={chargeBack || {}}
              cacheOptions
              onChange={a => {
                handleArrayChange('chargeBacks', index, 'authorizedBy', a.employeeId);
                handleArrayChange('chargeBacks', index, 'authorizedByName', (a.person || {}).name);
              }}
              loadOptions={this.getEmployees}
              required
            />
          </div>
          <div className="col-12 mt-3">
            <Textarea
              value={chargeBack.reason}
              rows="3"
              label={translate.t('reason')}
              onChange={e => handleArrayChange('chargeBacks', index, 'reason', e.target.value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ChargebackForm;
