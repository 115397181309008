import React, { Component } from 'react';
import { Label } from '../../reusable-components/styles';
import translate from '../../translate';
import { COLORS } from '../../reusable-components/vars/palette';
import styled from 'styled-components';
import moment from 'moment';

const Content = styled.div`
  color: black;
  font-family: 'exo-regular';
  font-size: 14px;
`;

const CloseButton = styled.div`
  float: right;
  font-family: 'exo-regular';
  cursor: pointer;
  margin-top: 2px;
`;

class PaymentListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreInfo: false
    };
  }
  toggleInfo = () => {
    this.setState({ showMoreInfo: !this.state.showMoreInfo });
  };
  render() {
    const { showMoreInfo } = this.state;
    const { payment = {}, onClose } = this.props;
    return (
      <div className="col-md-6 mb-2" style={{ border: '1px solid ' + COLORS.PALE_GRAY, paddingBottom: 4 }}>
        <CloseButton
          onClick={() => {
            onClose(payment);
          }}
        >
          x
        </CloseButton>
        <div className="row" style={{ marginTop: 5 }}>
          <div className="col-auto mb-2">
            <Label>{translate.t('reference')}</Label>
            <Content>{payment.reference}</Content>
          </div>
          <div className="col-auto mb-2">
            <Label>{translate.t('confirmationNumber')}</Label>
            <Content>{payment.confirmationNumber || '-----'}</Content>
          </div>
          <div className="col-auto mb-2">
            <Label>{translate.t('date')}</Label>
            <Content>{moment(payment.paymentDate).format('YYYY-MM-DD')}</Content>
          </div>
        </div>
        <div className="row">
          <div className="col-auto mb-2">
            <Label>{translate.t('paymentMethod')}</Label>
            <Content>{payment.paymentMethod.name}</Content>
          </div>
          <div className="col-auto mb-2">
            <Label>{translate.t('amount')}</Label>
            <Content>{payment.amount}</Content>
          </div>

          <div className="col-auto mb-2">
            <Label>{translate.t('status')}</Label>
            <Content>{(payment.status && payment.status.name) || '-----'}</Content>
          </div>
        </div>
        <a onClick={this.toggleInfo} href={'#' + payment.paymentId} className="mb-3" style={{ fontSize: 14 }}>
          {(showMoreInfo ? ' - ' : ' + ') + translate.t('details')}
        </a>
        {showMoreInfo &&
          (payment.paymentDetails || []).map(pd => (
            <div key={'PD-' + pd.paymentDetailId} className="row mb-2 mt-1">
              <div className="col-auto mb-2">
                <Label>{translate.t('paymentType')}</Label>
                <Content>{pd.paymentType.name}</Content>
              </div>
              <div className="col-auto mb-2">
                <Label>{translate.t('amount')}</Label>
                <Content>{pd.amount}</Content>
              </div>
            </div>
          ))}
      </div>
    );
  }
}
export default PaymentListItem;
