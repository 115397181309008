import styled from 'styled-components'
import { COLORS } from '../vars/palette'

const FilterSection = styled.div`
  display: grid;
  grid-template-columns: repeat(6,1fr);
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  border: 1px solid ${COLORS.PALE_GRAY};
  background-color: ${COLORS.PALE_GRAY_TWO};
  padding: 15px 20px;
`

export const SectionColumn = styled.div`
  ${props => props.start ? `grid-column-start : ${props.start};` : '' }
  ${props => props.end ? `grid-column-end : ${props.end};` : '' }
`

export default FilterSection