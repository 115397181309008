import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { ContractService } from '../../services';
import { Select, TextInput, DatePicker } from '../../reusable-components';
import moment from 'moment';

let weekChangeCount = 0;

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      roomTypes: [],
      contractSeasons: [],
      membershipTypes: [],
      membershipWeekQtys: []
    };
  }

  handleProductLists = async (value, callback) => {
    this.setProductDropdowns(value);
    callback && callback();
  };

  async componentDidMount() {
    weekChangeCount = 0;
    const products = await ContractService.getProducts();
    this.setState({ products });
    const value = this.props.context.contract.productId;
    if (value) {
      this.setProductDropdowns(value);
    }
  }

  setProductDropdowns = async value => {
    const roomTypes = await ContractService.getRoomTypes(value);
    const contractSeasons = await ContractService.getContractSeasons(value);
    const membershipTypes = await ContractService.getMembershipTypes(value);
    const membershipWeekQtys = await ContractService.getMembershipWeekQtys(value);
    await this.setState({
      roomTypes,
      contractSeasons,
      membershipTypes,
      membershipWeekQtys
    });
  };

  onMembershipWeekChange = e => {
    const {
      contractSeasonId,
      roomTypeId,
      membershipWeeks,
      membershipTypeId,
      productId,
      membershipPrice,
      contractProductId
    } = this.props.context.contract;
    this.props.context.handlePropertyChange('membershipWeeks', Number(e.membershipWeekQty));
    const weeksMatch = this.state.membershipWeekQtys.filter(m => m.membershipWeekQty === membershipWeeks).length;
    if (!weeksMatch) return;

    weekChangeCount++;

    ContractService.getMembershipCatalogPrices({
      contractSeasonId,
      roomTypeId,
      membershipWeekQty: membershipWeeks,
      membershipTypeId,
      productId
    }).then(result => {
      const price = result[0] || {};
      if (
        weeksMatch &&
        Number(price.listPriceMin) !== Number(membershipPrice) &&
        weekChangeCount <= 2 &&
        contractProductId > 0
      )
        return;
      this.props.context.handlePropertyChange('membershipPriceCatalog', price);
      price.listPriceMin && this.props.context.handlePropertyChange('membershipPrice', price.listPriceMin);
      price.closingCost && this.props.context.handlePropertyChange('closingCost', price.closingCost);
    });
  };

  render() {
    const { context } = this.props;
    const { contract } = context;
    const { products, roomTypes, contractSeasons, membershipTypes, membershipWeekQtys } = this.state;

    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-6 col-lg-4 pb-2">
            <Select
              placeholder={translate.t('productType')}
              name="productId"
              options={products}
              getOptionValue={o => o.productId}
              getOptionLabel={o => o.name}
              value={products.find(p => p.productId === contract.productId)}
              onChange={e => {
                this.handleProductLists(
                  Number(e.productId),
                  async () => await context.handlePropertyChange('productId', Number(e.productId))
                );
              }}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <Select
              placeholder={translate.t('unitType')}
              name="roomTypeId"
              options={roomTypes}
              getOptionValue={o => o.roomTypeId}
              getOptionLabel={o => o.name}
              disabled={!contract.productId}
              value={roomTypes.find(p => p.roomTypeId === contract.roomTypeId)}
              onChange={e => context.handlePropertyChange('roomTypeId', Number(e.roomTypeId))}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <Select
              placeholder={translate.t('contractSeason')}
              name="contractSeasonId"
              options={contractSeasons}
              getOptionValue={o => o.contractSeasonId}
              getOptionLabel={o => o.name}
              value={contractSeasons.find(p => p.contractSeasonId === contract.contractSeasonId)}
              onChange={e => context.handlePropertyChange('contractSeasonId', Number(e.contractSeasonId))}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <Select
              placeholder={translate.t('membershipType')}
              name="membershipTypeId"
              options={membershipTypes}
              getOptionValue={e => e.membershipTypeId}
              getOptionLabel={e => e.name}
              value={membershipTypes.find(p => p.membershipTypeId === contract.membershipTypeId)}
              onChange={e => context.handlePropertyChange('membershipTypeId', Number(e.membershipTypeId))}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <Select
              placeholder={translate.t('membershipWeeks')}
              name="membershipWeeks"
              options={membershipWeekQtys}
              getOptionValue={o => o.membershipWeekQty}
              getOptionLabel={o => o.membershipWeekQty}
              value={membershipWeekQtys.find(p => p.membershipWeekQty === contract.membershipWeeks)}
              onChange={this.onMembershipWeekChange}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <TextInput
              label={translate.t('preferredRateNight')}
              name="preferredRates"
              type="number"
              min={0}
              max={999999999}
              value={contract.preferredRates}
              placeholder={translate.t('preferredRateNight')}
              onChange={e => context.handlePropertyChange(e.target.name, e.target.value)}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <TextInput
              label={translate.t('expirationYears')}
              autoComplete="off"
              name="expirationYears"
              type="number"
              min={1}
              max={999999999}
              onChange={e => {
                const value = e.target.value;
                context.handlePropertyChange('expirationYears', value);
              }}
              value={contract.expirationYears || ''}
              required
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <DatePicker
              label={translate.t('annualFeeDate')}
              autoComplete="off"
              name="annualFeeDate"
              onSelect={date =>
                context.handlePropertyChange('annualFeeDate', date ? moment(date).format('YYYY-MM-DD') : '')
              }
              value={contract.annualFeeDate}
            />
          </div>
          <div className="col-sm-6 col-lg-4 pb-2">
            <TextInput
              type="number"
              pattern="^0*[1-9]\d*$"
              placeholder="$0.00"
              label={translate.t('annualFee')}
              name="annualFee"
              onChange={e => context.handlePropertyChange(e.target.name, e.target.value)}
              value={contract.annualFee}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ProductForm;
