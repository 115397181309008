import Features from './features';
import { UserService } from './services';
import DashboardIcon from './assets/images/welcome/dashboard.png';
import ReportIcon from './assets/images/welcome/report.png';
import CollectIcon from './assets/images/welcome/collect.png';
import ContractIcon from './assets/images/welcome/contract.png';
import AdminIcon from './assets/images/welcome/admin.png';

const menuOptions = [
  // {
  //   title: 'Dashboard',
  //   description: 'Organize all your work on one place',
  //   image: DashboardIcon,
  //   feature: Features.WELCOME_DASHBOARD,
  //   url: '/dashboard'
  // },
  // {
  //   title: 'Contract',
  //   description: 'Create, edit and view all your contracts',
  //   image: ContractIcon,
  //   feature: Features.WELCOME_CONTRACT,
  //   url: '/contracts',
  //   childs: [
  //     {
  //       title: 'List',
  //       feature: Features.CONTRACTS_SEARCH,
  //       url: '/contracts'
  //     },
  //     {
  //       title: 'Create',
  //       feature: Features.CONTRACTS_CREATE,
  //       url: '/contracts/new'
  //     }
  //   ]
  // },
  {
    title: 'Collect',
    description: 'Manage payment details',
    image: CollectIcon,
    feature: Features.WELCOME_COLLECT,
    url: '/collect',
    childs: [
      {
        title: 'List',
        feature: Features.WELCOME_COLLECT,
        url: '/collect'
      },
      {
        title: 'Create',
        feature: Features.COLLECT_CREATE,
        url: '/collect/new'
      }
    ]
  // },
  // {
  //   title: 'Report',
  //   description: 'See, Control and Manage all your reports',
  //   image: ReportIcon,
  //   feature: Features.WELCOME_REPORT,
  //   url: '/reports'
  // },
  // {
  //   title: 'Admin',
  //   description: 'Control modules',
  //   image: AdminIcon,
  //   feature: Features.WELCOME_ADMIN,
  //   url: '/admin'
  }
];

function getAllowedOptions(opts) {
  return opts.filter(o => UserService.isAllowed(o.feature));
}

export default () => {
  return (
    getAllowedOptions(menuOptions).map(o => {
      const childs = getAllowedOptions(o.childs || []);
      o.childs = childs;
      return o;
    }) || []
  );
};
