import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Total, Label, Paragraph, BorderedDiv } from '../../reusable-components/styles';

class MembershipCostView extends Component {
  render() {
    const contract = this.props.contract;
    const contractChange =
      (contract.contractChanges && contract.contractChanges.length && contract.contractChanges[0]) || {};
    const contractPrice =
      contractChange.contractPrices && contractChange.contractPrices.length ? contractChange.contractPrices[0] : {};
    const percentageToPayToday = contractPrice.volume
      ? (((contractPrice.paidToday - contractPrice.closingCost) / contractPrice.volume) * 100).toFixed(2)
      : 0;

    return (
      <Fragment>
        <BorderedDiv className="row mx-0">
          <div className="col-8 pb-3">
            <Total style={{ float: 'none' }}>
              <Label>{translate.t('price')}</Label>
              <p>{translate.l('currency', contractPrice.volume || 0)}</p>
            </Total>
          </div>
          <div className="col-4" style={{ borderLeft: '1px solid #c7c7c7' }}>
            <Label>{translate.t('closingCost')}</Label>
            <Paragraph className="regular-paragraph">
              {translate.l('currency', contractPrice.closingCost || 0)}
            </Paragraph>
          </div>
        </BorderedDiv>
        <BorderedDiv className="row no-border-top py-2 mx-0">
          <div className="col-auto pb-2">
            <Label>{`${translate.t('downpaymentRequiredAbbrev')} (${contractPrice.requiredDPPercentage}%)`}</Label>
            <Paragraph className="regular-paragraph">
              {translate.l('currency', contractPrice.requiredDownpayment)}
            </Paragraph>
          </div>
          <div className="col-auto">
            <Label>{translate.t('totalDownpayment')}</Label>
            <Paragraph className="regular-paragraph">
              {translate.l('currency', contractPrice.paidDownpayment)}
            </Paragraph>
          </div>
          <div className="col-auto">
            <Label>{`${translate.t('totalPaidToday')} (${
              percentageToPayToday < 0 ? 0 : percentageToPayToday
            }%)`}</Label>
            <Paragraph className="regular-paragraph">{translate.l('currency', contractPrice.paidToday)}</Paragraph>
          </div>
        </BorderedDiv>
      </Fragment>
    );
  }
}
export default MembershipCostView;
