import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

const ArrowBlock = styled.button`
  padding: 5px 10px 5px 10px;
  border: 1px solid ${COLORS.TABLE_PAGINATION_BORDER}
  cursor: pointer;
  outline: none;
  width: 40px;
  height: 30px;
  margin-right: 3px;
  ${props => (props.first ? 'margin-left: 10px' : '')}
`;

const Container = styled.div`
  height: 64px;
  display: flex;
  clear:both;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid ${COLORS.TABLE_BORDER}
  border-left: 1px solid ${COLORS.TABLE_BORDER}
  border-top: 1px solid ${COLORS.TABLE_BORDER}

`;

const PaginationSummary = styled.div`
  margin-left: 15px;
  & > div > span {
    color: ${COLORS.TABLE_PAGINATION_SUMMARY}
    font-family: 'roboto-regular';
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: normal;
  }

  & > div > span.bold { 
    font-weight: bold;
    font-family: 'roboto-bold';
  }
`;

export default class Pagination extends React.Component {
  state = {
    paginationDisabled: false
  };
  handlePageChange(status) {
    let pageNumber = null;
    let { activePage, handlePageChange: onPageChange } = this.props;
    let { pageCount } = this.props;

    switch (status) {
      case 'first':
        pageNumber = 1;
        break;
      case 'last':
        pageNumber = pageCount;
        break;
      case 'next':
        pageNumber = activePage === pageCount ? 1 : ++activePage;
        break;
      case 'pre':
        pageNumber = activePage === 1 ? pageCount : --activePage;
        break;
      default:
        pageNumber = status;
    }
    this.setState({ paginationDisabled: true });
    onPageChange(pageNumber);
    this.setState({ paginationDisabled: false });
  }
  render() {
    const { activePage = 1, itemsPerPage, totalItems, right } = this.props;

    const limit = activePage * itemsPerPage;
    return (
      <Container>
        <PaginationSummary>
          <div style={{ display: 'inline-block', width: '170px' }}>
            <span>Viewing&nbsp;</span>
            <span className="bold">
              {activePage === 1 ? activePage : (activePage - 1) * itemsPerPage + 1}-
              {limit > totalItems ? totalItems : limit}
              &nbsp;
            </span>
            <span>of&nbsp;</span>
            <span className="bold">{totalItems}</span>
          </div>
          <div style={{ display: 'inline-block' }}>
            {activePage >1 && (
              <ArrowBlock first disabled={this.state.paginationDisabled} onClick={() => this.handlePageChange('pre')}>
                <i className="fa fa-angle-left" />
              </ArrowBlock>
            )}
            <ArrowBlock style={{ fontFamily: 'roboto-regular', fontSize: '13px' }}>{activePage}</ArrowBlock>
            <ArrowBlock disabled={this.state.paginationDisabled} onClick={() => this.handlePageChange('next')}>
              <i className="fa fa-angle-right" />
            </ArrowBlock>
            <span>{this.state.paginationDisabled && <i className="fa fa-spinner fa-spin" />}</span>
          </div>
        </PaginationSummary>
        {right}
      </Container>
    );
  }
}

Pagination.propTypes = {
  nextPageText: PropTypes.string,
  prePageText: PropTypes.string,
  firstPageText: PropTypes.string,
  lastPageText: PropTypes.string,
  className: PropTypes.string,
  pagesDisplayed: PropTypes.number
};

Pagination.defaultProps = {
  firstPageText: 'First',
  lastPageText: 'Last',
  className: 'react-pagination-status pull-right',
  pagesDisplayed: 5
};
