import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, Select } from '../../reusable-components';

class BeneficiaryForm extends Component {
  render() {
    const { beneficiary, index, relationshipTypes, context } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('firstName')}
              autoComplete="firstName"
              onChange={e => context.handleArrayChange('beneficiaries', index, 'firstName', e.target.value)}
              required
              placeholder={translate.t('firstName')}
              value={beneficiary.firstName}
            />
          </div>
          <div className="col-sm-6 col-md-4 pb-2">
            <TextInput
              label={translate.t('lastName')}
              autoComplete="lastName"
              onChange={e => context.handleArrayChange('beneficiaries', index, 'lastName', e.target.value)}
              required
              placeholder={translate.t('lastName')}
              value={beneficiary.lastName}
            />
          </div>
          <div className="col-md-4 pb-2">
            <Select
              placeholder={translate.t('relationshipType')}
              options={relationshipTypes}
              getOptionValue={o => o.relationshipTypeId}
              getOptionLabel={o => o.name}
              required
              value={relationshipTypes.find(o => o.relationshipTypeId === beneficiary.relationshipTypeId)}
              onChange={e =>
                context.handleArrayChange('beneficiaries', index, 'relationshipTypeId', e.relationshipTypeId)
              }
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default BeneficiaryForm;
