import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { Select, TextInput, DatePicker, Button, NumberInput, Textarea } from '../../reusable-components';
import translate from '../../translate';
import { COLORS } from '../../reusable-components/vars/palette';
import { Subtitle, RemoveButton, RemoveButtonContainer } from '../../reusable-components/styles';
import { ContractService, PersonService } from '../../services';
import Checkbox from '../../reusable-components/common/Inputs/Checkbox';

const vigencies = [{ value: 12, label: '12' }, { value: 18, label: '18' }];
class CertificateForm extends Component {
  state = {
    certificateTypes: [],
    languages: []
  };

  getSerialNumber = certificate => {
    let serialNumber = '';
    if (certificate.certificateTypeId && certificate.languageId) {
      const certificateType = this.state.certificateTypes.find(
        ct => ct.certificateTypeId === certificate.certificateTypeId
      );
      const language = this.state.languages.find(lg => lg.languageId === certificate.languageId);
      serialNumber = `${certificateType && certificateType.acronym}-${language && language.code}`;
    }
    return serialNumber;
  };

  getExpirationDate = certificate => {
    let expirationDate = '';
    if (certificate.expeditionDate && certificate.months) {
      expirationDate = moment(certificate.expeditionDate)
        .add(parseInt(certificate.months), 'months')
        .format('YYYY-MM-DD');
    }
    return expirationDate;
  };

  handleChange = (index, name, value) => {
    let { certificateTypes } = this.state;
    const certificate = this.props.certificates[index];
    this.props.handleArrayChange('certificates', index, name, value);

    const serialCode = this.getSerialNumber(certificate);
    const expirationDate = this.getExpirationDate(certificate);
    serialCode && this.props.handleArrayChange('certificates', index, 'serialCode', serialCode);
    expirationDate && this.props.handleArrayChange('certificates', index, 'expirationDate', expirationDate);

    if (certificate.certificateTypeId) {
      const certificateType = certificateTypes.find(ct => ct.certificateTypeId === certificate.certificateTypeId);
      if (certificateType) {
        this.props.handleArrayChange('certificates', index, 'price', certificateType.price);
        this.props.handleArrayChange('certificates', index, 'cost', certificateType.cost);
      }
    }
  };

  handleDate = (index, name, date) => {
    this.props.handleArrayChange('certificates', index, name, moment(date).format('YYYY-MM-DD'));
  };

  handleOwner = (parentIndex, index, e) => {
    let { name, value } = e.target;
    this.props.handleArrayChildChange('certificates', parentIndex, 'owners', index, name, value);
  };

  addOwner = parentIndex => {
    this.props.addChildItem('certificates', parentIndex, 'owners');
  };

  removeOwner = (parentIndex, index) => {
    this.props.removeChildItem('certificates', parentIndex, 'owners', index);
  };

  async componentDidMount() {
    let certificateTypes = await ContractService.getCertificateTypes();
    certificateTypes = certificateTypes && certificateTypes.filter(cert => cert.acronym !== 'RCI'); // do not show rci types
    const languages = await PersonService.getLanguages();
    this.setState({
      certificateTypes,
      languages
    });
  }

  render() {
    let { certificateTypes, languages } = this.state;
    let { index, certificate } = this.props;

    return (
      <Fragment>
        <div id={`printable-certificate`} style={{ display: 'none' }}>
          <img alt="dummy" src={window.location.origin + '/dummyCertificate.jpg'} />
        </div>
        <div className="row">
          <div className="col-md-4 mb-2">
            <TextInput
              name="folioNumber"
              label={translate.t('folioNumber')}
              onChange={({ target }) => this.handleChange(index, target.name, target.value)}
              value={certificate.folioNumber}
              required
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <NumberInput
              value={certificate.adults || ''}
              type="number"
              min={0}
              max={99}
              step={1}
              label={translate.t('adults')}
              required
              name="adults"
              placeholder="0"
              onChange={({ target }) => this.handleChange(index, target.name, Number(target.value))}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <NumberInput
              value={certificate.children || ''}
              type="number"
              label={translate.t('children')}
              required
              min={0}
              max={99}
              step={1}
              name="children"
              placeholder="0"
              onChange={({ target }) => this.handleChange(index, target.name, Number(target.value))}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <Select
              placeholder={translate.t('certificateType')}
              options={certificateTypes}
              getOptionValue={o => o.certificateTypeId}
              getOptionLabel={o => o.name}
              required
              name="certificateTypeId"
              value={certificateTypes.find(ct => ct.certificateTypeId === certificate.certificateTypeId)}
              onChange={ct => this.handleChange(index, 'certificateTypeId', Number(ct.certificateTypeId))}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <Select
              placeholder={translate.t('language')}
              options={languages}
              getOptionValue={o => o.languageId}
              getOptionLabel={o => o.description}
              name="languageId"
              value={languages.find(l => l.languageId === certificate.languageId)}
              onChange={l => this.handleChange(index, 'languageId', l.languageId)}
              required
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <TextInput label={translate.t('serialNumber')} readOnly value={this.getSerialNumber(certificate, index)} />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <DatePicker
              label={translate.t('expeditionDate')}
              value={certificate.expeditionDate}
              onChange={date => this.handleChange(index, 'expeditionDate', moment(date).format('YYYY-MM-DD'))}
              required
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <Select
              placeholder={translate.t('vigency')}
              options={vigencies}
              getOptionLabel={v => v.label}
              getOptionValue={v => v.value}
              value={vigencies.find(v => v.value === certificate.months)}
              name="months"
              onChange={e => this.handleChange(index, 'months', Number(e.value))}
              required
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-2">
            <DatePicker
              label={translate.t('expirationDate')}
              value={this.getExpirationDate(certificate)}
              readOnly
              required
            />
          </div>
        </div>

        <Subtitle>Owners</Subtitle>
        {certificate.owners.map((owner, ownerIndex) => (
          <div className="row mb-3 mt-2" key={owner.uiid || ownerIndex}>
            <div className={`row mx-0 pt-2 ${certificate.owners.length > 1 ? 'col-11' : 'col'}`}>
              <div className="col-sm-6">
                <TextInput
                  value={owner.firstName}
                  label={translate.t('firstName')}
                  name="firstName"
                  onChange={e => this.handleOwner(index, ownerIndex, e)}
                  required
                />
              </div>
              <div className="col-sm-6">
                <TextInput
                  value={owner.lastName}
                  label={translate.t('lastName')}
                  name="lastName"
                  onChange={e => this.handleOwner(index, ownerIndex, e)}
                  required
                />
              </div>
            </div>
            {certificate.owners.length > 1 && (
              <div className="col-1 px-0 d-flex align-items-center">
                <RemoveButtonContainer>
                  <RemoveButton
                    className="fa fa-trash"
                    type="button"
                    onClick={() => this.removeOwner(index, ownerIndex)}
                  />
                </RemoveButtonContainer>
              </div>
            )}
          </div>
        ))}
        <div className="row mb-2 mt-2">
          <div className="col-3">
            <Button
              style={{ opacity: 0.5 }}
              backgroundColor={COLORS.MINT_GREEN}
              textColor={COLORS.CHARCOAL_GRAY}
              type="button"
              onClick={() => this.addOwner(index)}
            >
              {`${translate.t('add')} ${translate.t('owner')} `}
            </Button>
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-3">
            <Checkbox
              isChecked={certificate.hasAnnex}
              onCheck={value => this.handleChange(index, 'hasAnnex', value)}
              labelText={translate.t('annex')}
              labelStyle={{ fontFamily: '"open sans", "Helvetica Neue", Helvetica, Arial, sans-serif' }}
            />
          </div>
        </div>

        <div className="row mb-3">
          <div className="col-12" style={{ position: 'relative' }}>
            <Textarea
              value={certificate.comment}
              rows="3"
              name="comment"
              label={translate.t('comment')}
              onChange={({ target }) => this.handleChange(index, target.name, target.value)}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CertificateForm;
