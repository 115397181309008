import React from "react";
import WizardHeader from "./components/WizardHeader";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    const selectedIndex = props.selectedIndex || 0;
    const currentStep = props.steps[selectedIndex];

    this.state = {
      steps: props.steps,
      selectedIndex,
      currentStep
    };
  }

  selectStep = (selectedIndex) => {
    this.props.selectStep(this.state.selectedIndex, selectedIndex, () => {
      const currentStep = this.state.steps[selectedIndex]
      this.setState({
        selectedIndex,
        currentStep
      });
    });
  };

  render() {
    const { steps, currentStep, selectedIndex } = this.state;
    const { style, context } = this.props;
    const Content = currentStep.content;
    return (
      <div style={style}>
        <WizardHeader
          steps={steps}
          selectedIndex={selectedIndex}
          selectStep={this.selectStep}
        />
        <div><Content context={context} /></div>
      </div>
    );
  }
}

export default Wizard;