import React, { Component } from 'react';
import { UserService } from '../../services';
import { Button, TextInput } from '../../reusable-components';
import LoginBG from '../../assets/images/login-bg.jpg';
import styled from 'styled-components';
import translate from '../../translate';

const Content = styled.div`
  display: flex;
  height: 100vh;
`;

const WelcomeMsg = styled.span`
  font-family: exo-light;
  font-size: 33.1px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
`;

const EnjoyMsg = styled.div`
  font-family: exo-extralight;
  font-size: 16.6px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4a4a4a;
`;
const FormWrapper = styled.div`
  flex: 1;
  background-color: #f7f9fa;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;

  @media (max-width: 991.98px) {
    & > div {
      height: 80%;
    }
  }
`;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
    this.initialState = this.state;
  }

  onLogin = async e => {
    e.preventDefault();
    const state = this.props.location.state;
    const from = state ? state.from.pathname : false;
    const resp = await UserService.authenticate(this.state.username, this.state.password);

    if (resp.authenticated && from) {
      this.props.history.replace(from);
    } else if (resp.authenticated) {
      this.props.history.replace('/collect');
    } else {
      alert(resp.message);
    }
  };

  clear = () => {
    this.setState(this.initialState);
  };

  render() {
    return (
      <Content>
        <FormWrapper className="px-3 py-5">
          <div>
            <WelcomeMsg>
              {translate.t('welcomeTo')}{' '}
              <span style={{ fontWeight: 600, fontFamily: 'exo-semibold' }}>Cluby</span>
            </WelcomeMsg>
            <EnjoyMsg>{translate.t('loginWelcomeMessage')}.</EnjoyMsg>
            <form onSubmit={this.onLogin} style={{ marginTop: '6%' }}>
              <div style={{ marginBottom: '30px' }}>
                <TextInput
                  autoCapitalize="none"
                  label="Username"
                  onChange={e => this.setState({ username: e.target.value })}
                  placeholder="Username"
                  required
                  value={this.state.username}
                />
              </div>
              <div className="form-group">
                <TextInput
                  label="Password"
                  onChange={e => this.setState({ password: e.target.value })}
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  required
                  value={this.state.password}
                />
              </div>
              <div style={{ marginTop: '7%', display: 'flex', justifyContent: 'center' }}>
                <Button type="button" style={{ minWidth: '130px' }} onClick={this.clear}>
                  {translate.t('clear')}
                </Button>
                <Button style={{ minWidth: '130px', marginLeft: '10px' }} type="submit" success>
                  {translate.t('login')}
                </Button>
              </div>
            </form>
          </div>
        </FormWrapper>
        <div
          className="d-none d-lg-block"
          style={{
            flex: 1,
            backgroundImage: `url(${LoginBG})`,
            backgroundSize: '100% 100%'
          }}
        />
      </Content>
    );
  }
}

export default Login;
