import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, Select } from '../../reusable-components';

class PhoneForm extends Component {
  render() {
    const { phone, parentIndex, index, phoneTypes, context } = this.props;
    return (
      <Fragment>
        <div className="row no-gutters">
          <div className="col-6 pb-2 pr-2">
            <Select
              placeholder={translate.t('phoneType')}
              options={phoneTypes}
              isClearable={true}
              getOptionValue={phone => phone.contactPhoneTypeId}
              getOptionLabel={phone => phone.name}
              value={phoneTypes.find(pt => pt.contactPhoneTypeId === phone.contactPhoneTypeId)}
              onChange={e =>
                context.handleArrayChildChange(
                  'people',
                  parentIndex,
                  'phones',
                  index,
                  'contactPhoneTypeId',
                  e.contactPhoneTypeId
                )
              }
              required
            />
          </div>
          <div className="col-6 pb-2">
            <TextInput
              label={translate.t('phone')}
              type="tel"
              autoComplete="phone"
              max={99999999999}
              onChange={e =>
                context.handleArrayChildChange(
                  'people',
                  parentIndex,
                  'phones',
                  index,
                  'number',
                  (e.target.value || '').replace(/\D/g, '')
                )
              }
              placeholder={translate.t('phone')}
              value={phone.number}
              required
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PhoneForm;
