import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Label, Paragraph, BorderedDiv } from '../../reusable-components/styles';

class FinancingView extends Component {
  render() {
    const contract = this.props.contract;
    const contractLoan = contract.contractLoans && contract.contractLoans.length ? contract.contractLoans[0] : {};

    return contractLoan.originalLoanAmount > 0 ? (
      <Fragment>
        <h2 className="my-3">{translate.t('financing')}</h2>

        <BorderedDiv className="row py-2 mx-0">
          <div className="col-auto pb-2">
            <Label>{translate.t('amountToFinance')}</Label>
            <Paragraph className="regular-paragraph">
              {translate.l('currency', contractLoan.originalLoanAmount || 0)}
            </Paragraph>
          </div>
          <div className="col-auto">
            <Label>{translate.t('terms')}</Label>
            <Paragraph className="regular-paragraph">{contractLoan.term || 0}</Paragraph>
          </div>
          <div className="col-auto">
            <Label>{translate.t('firstDueDate')}</Label>
            <Paragraph className="regular-paragraph">{contractLoan.firstDueDate}</Paragraph>
          </div>
          <div className="col-auto">
            <Label>{`% ${translate.t('interest')}`}</Label>
            <Paragraph className="regular-paragraph">{contractLoan.interest}</Paragraph>
          </div>
        </BorderedDiv>
        <BorderedDiv className="row py-2 no-border-top mx-0">
          <div className="col-auto pb-2">
            <Label>{translate.t('paymentAmount')}</Label>
            <Paragraph className="regular-paragraph">
              {translate.l('currency', contractLoan.paymentAmount || 0)}
            </Paragraph>
          </div>
        </BorderedDiv>
      </Fragment>
    ) : (
      <div />
    );
  }
}
export default FinancingView;
