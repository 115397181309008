import React, { Component } from 'react';
import styled from 'styled-components';

const Image = styled.div`
  margin-bottom: 25px;
  margin-top: 15px;

  @media (max-width: 575.98px) {
    margin-bottom: 5px;
  }
`;

const Title = styled.div`
  width: 97px;
  height: 24px;
  font-family: roboto-bold;
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #394a51;
  margin-bottom: 15px;
`;

const Description = styled.div`
  width: 170px;
  height: 40px;
  font-family: exo-regular;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #394a51;
`;

const Rectangle = styled.div`
  width: 200px;
  height: 200px;
  border: solid 1px #cccccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-bottom: 30px;
  cursor: pointer;

  @media (max-width: 575.98px) {
    width: 100px;
    height: 100px;
  }
`;

class WelcomeButton extends Component {
  goTo = () => {
    this.props.history.push(this.props.url);
  };
  render() {
    return (
      <Rectangle onClick={this.goTo}>
        <Image>
          <img src={this.props.image} alt="" />
        </Image>
        <Title>{this.props.title}</Title>
        <Description className="d-none d-sm-block">{this.props.description}</Description>
      </Rectangle>
    );
  }
}

export default WelcomeButton;
