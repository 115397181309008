import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Modal } from '../../reusable-components';
import { Label, Paragraph } from '../../reusable-components/styles';

class CertificateView extends Component {
  render() {
    const { certificate, show, close } = this.props;
    const owners = certificate.owners && certificate.owners.map(o => `${o.firstName} ${o.lastName}`).toString();
    return (
      <Fragment>
        <Modal
          className="modal-sm"
          show={show}
          close={close}
          title={translate.t('certificate')}
          modalBodyStyles={{ paddingBottom: '25px' }}
        >
          <div className="col-12">
            <div className="row mb-3">
              <div className="col-md-3">
                <Label>{translate.t('folioNumber')}</Label>
                <Paragraph>{certificate.folioNumber}</Paragraph>
              </div>
              <div className="col-md-3">
                <Label>{translate.t('certificateType')}</Label>
                <Paragraph>{certificate.certificateType && certificate.certificateType.name}</Paragraph>
              </div>
              <div className="col-md-3">
                <Label>{translate.t('language')}</Label>
                <Paragraph>{certificate.languageId}</Paragraph>
              </div>
              <div className="col-md-3">
                <Label>{translate.t('certificateType')}</Label>
                <Paragraph>{certificate.serialCode}</Paragraph>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-3">
                <Label>{translate.t('expeditionDate')}</Label>
                <Paragraph>{certificate.expeditionDate}</Paragraph>
              </div>
              <div className="col-md-2">
                <Label>{translate.t('vigency')}</Label>
                <Paragraph>{certificate.months}</Paragraph>
              </div>
              <div className="col-md-3">
                <Label>{translate.t('expirationDate')}</Label>
                <Paragraph>{certificate.expirationDate}</Paragraph>
              </div>
              <div className="col-md-2">
                <Label>{translate.t('adults')}</Label>
                <Paragraph>{certificate.adults}</Paragraph>
              </div>
              <div className="col-md-2">
                <Label>{translate.t('children')}</Label>
                <Paragraph>{certificate.children}</Paragraph>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12">
                <Label>{translate.t('owners')}</Label>
                <Paragraph>{owners}</Paragraph>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <Label>{`${translate.t('hasAppendant')} ?`}</Label>
                <Paragraph>{certificate.hasAnnex ? 'Yes' : 'No'}</Paragraph>
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12">
                <Label>{translate.t('comment')}</Label>
                <Paragraph>{certificate.comment}</Paragraph>
              </div>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default CertificateView;
