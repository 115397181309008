import React from 'react'
import styled from 'styled-components'
import { COLORS } from '../../../vars/palette'

const DatePickerStyle = styled.div`
  & .react-datepicker {
    border-radius: 0;
    box-shadow: -9px 7px 0 0 rgba(137,165,202,0.1);
    border-color: ${COLORS.CONTEXT_BORDER};
    font-family: 'exo-regular'
  }

  & .react-datepicker__header {
    background-color: ${COLORS.PALE_GRAY_TWO};
    border-bottom: 1px solid ${COLORS.CONTEXT_BORDER};
  }

  & .react-datepicker__month-dropdown, .react-datepicker__year-dropdown {
    background-color: ${COLORS.PALE_GRAY_TWO};
    border: 1px solid ${COLORS.CONTEXT_BORDER};
    border-radius: 0;
    box-shadow: -9px 7px 0 0 rgba(137,165,202,0.1);

  }

  & .react-datepicker__day:hover {
    background-color: ${COLORS.PALE_GRAY_THREE};
    border-radius: 50%;
  }

  & .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__day--keyboard-selected {
    background-color: #5d38db;
    border-radius: 50%;
    color: #fff;
    
  }

  & .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__day--keyboard-selected:hover {
    filter: brightness(85%);
    background-color: #5d38db !important;
    border-radius: 50% !important;
    color: #fff !important;
  }

  & .react-datepicker__navigation--years {
    display: inline
  }

  

  & .react-datepicker__navigation--years-upcoming {
    border-bottom-color: ${COLORS.HEADER_COLOR}
    border-width: 0.30rem;
  }

  & .react-datepicker__navigation--years-previous {
    border-top-color: ${COLORS.HEADER_COLOR}
    border-width: 0.30rem;
  }

  & .react-datepicker__header__dropdown {
    margin: 5px;
  }

  & .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow{
    border-width: 0.30rem;
    border-top-color: ${COLORS.HEADER_COLOR}
  }

  & .react-datepicker__navigation--next {
    border-left-color: ${COLORS.HEADER_COLOR};
    border-width: 0.30rem;
  }

  & .react-datepicker__navigation--previous {
    border-right-color: ${COLORS.HEADER_COLOR};
    border-width: 0.30rem;
  }

  & .react-datepicker__year-option, .react-datepicker__month-option, .react-datepicker__month-year-option {
 
  }

  & .react-datepicker__year-option:hover, .react-datepicker__month-option:hover, .react-datepicker__month-year-option:hover {
    background-color: ${COLORS.PALE_GRAY_THREE};
  }
 



`

const DatePickerStyleWrapper = (WrappedComponent) => (
  class extends React.Component {
    render() {
      return (
        <DatePickerStyle>
          <WrappedComponent {...this.props} />
        </DatePickerStyle>
      )
    }
  }
)

export default DatePickerStyleWrapper