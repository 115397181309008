import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Paragraph, Label } from '../../reusable-components/styles';
import styled from 'styled-components';
import moment from 'moment';

export const ContractInfoPanel = styled.div`
  border: 1px solid #0be881;
  background-color: rgba(185, 255, 216, 0.23);

  & p {
    color: #394a51;
    line-height: 22px;
    letter-spacing: 0.06em;
    font-family: 'exo-regular';
    font-size: 16px;
  }
`;

class ContractInfoView extends Component {
  render() {
    const { contract = {}, classNames = '' } = this.props;

    const contractChanges = contract.contractChanges;
    const change = contractChanges && contractChanges.length && contractChanges[0];
    const product = change
      ? change.contractProducts && change.contractProducts.length && change.contractProducts[0]
      : {};
    const roomType = product.roomType || {};
    const contractSeason = product.contractSeason || {};
    const membershipType = product.membershipType || {};
    const productType = membershipType.product || {};
    const location = (change || {}).location || {};
    const gallery = location.gallery || {};

    return (
      <Fragment>
        <ContractInfoPanel className={classNames}>
          <div className="row">
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('productType')}</Label>
              <Paragraph>{productType.name}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('contractSeason')}</Label>
              <Paragraph>{contractSeason.name}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('unitType')}</Label>
              <Paragraph>{roomType.name}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('preferredRatesAbbrev')}</Label>
              <Paragraph>{product.preferredRates || 0}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('membershipWeeksAbbrev')}</Label>
              <Paragraph>{product.membershipWeeks || 0}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto mb-3 mb-md-0">
              <Label>{translate.t('soldDate')}</Label>
              <Paragraph>{translate.l('dateFormats.date', moment(contract.creationDate).toDate())}</Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto">
              <Label>{translate.t('expirationDate')}</Label>
              <Paragraph>
                {contract.expirationDate
                  ? translate.l('dateFormats.date', moment(product.expirationDate).toDate())
                  : translate.t('notAvailableAbbrev')}
              </Paragraph>
            </div>
            <div className="col-sm-6 col-md-auto">
              <Label>{translate.t('gallery')}</Label>
              <Paragraph>{gallery.name}</Paragraph>
            </div>
          </div>
        </ContractInfoPanel>
      </Fragment>
    );
  }
}
export default ContractInfoView;
