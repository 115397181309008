import React from 'react';
import { InputWrapper, DatePickerStyleWrapper, Input } from './base';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';
import moment from 'moment';

const DateInputStyle = styled(Input)`
  background-color: #fff;
  font-family: 'exo-regular';
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  border: none;
  color: ${COLORS.INPUT_TEXT_COLOR};
  border-radius: 0;
  appearance: none;
  min-height: 22px;

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    padding: 2px;
  }
`;

class DatePicker extends React.Component {
  onBlur = () => {
    const { onSelect: onChange, value, minDate, maxDate } = this.props;
    minDate && moment(value).isBefore(minDate) && onChange('');
    maxDate && moment(value).isAfter(maxDate) && onChange('');
  };

  render() {
    const { onSelect: onChange, value, minDate, maxDate, ...others } = this.props;
    return (
      <DateInputStyle
        type="date"
        pattern="\d{4}-\d{2}-\d{2}"
        onChange={e => onChange(e.target.value)}
        onBlur={this.onBlur}
        value={value || ''}
        {...{
          ...(minDate ? { min: moment(minDate).format('YYYY-MM-DD') } : {}),
          ...(maxDate ? { max: moment(maxDate).format('YYYY-MM-DD') } : {}),
          ...others
        }}
      />
    );
  }
}

export default DatePickerStyleWrapper(InputWrapper(DatePicker));
