import ColorRequest from './colorRequest';

class PaymentService {
  static async getById(paymentId) {
    try {
      const { data: response } = await ColorRequest.get('/payments/' + paymentId);
      return response;
    } catch (e) {
      return e;
    }
  }

  static async pay(payment) {
    try {
      const { data: resp } = await ColorRequest.post('/payments', payment);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async getPaymentTypes() {
    try {
      const { data: response } = await ColorRequest.get('/payments/paymentTypes');
      return response;
    } catch (e) {
      return e;
    }
  }

  static async getPaymentMethods() {
    try {
      const { data: response } = await ColorRequest.get('/payments/paymentMethods');
      return response;
    } catch (e) {
      return e;
    }
  }

  static async getStatuses() {
    try {
      const { data: response } = await ColorRequest.get('/payments/statuses');
      return response;
    } catch (e) {
      return e;
    }
  }

  static async getByQuery(query) {
    try {
      const { data: resp } = await ColorRequest.post('/payments/list/query', query);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async autocomplete(query) {
    try {
      const { data: resp } = await ColorRequest.post('/payments/autocomplete', query);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async link(contractId, entity) {
    try {
      const { data: resp } = await ColorRequest.put(`/payments/${contractId}/link`, entity);
      return resp;
    } catch (e) {
      return e;
    }
  }
  static async cancel(id, cancellationReference) {
    const { data: resp } = await ColorRequest.delete(
      `/payments/${id}` + (cancellationReference ? '?cancellationReference=' + cancellationReference : '')
    );
    return resp;
  }
  static async refund(id, refundReference) {
    const { data: resp } = await ColorRequest.put(
      `/payments/${id}` + (refundReference ? '?refundReference=' + refundReference : '')
    );
    return resp;
  }
}

export default PaymentService;
