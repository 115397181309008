import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1072;
  overflow: hidden;
  outline: 0;
  transition: all 0.3s;

  &.modal-sm {
    max-width: 720px;
  }
`;

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  outline: 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalHeader = styled.div`
  min-height: 60px;
  padding: 20px;
  margin-bottom: 20px;
`;

const ModalDialog = styled.div`
  position: relative;
  width: auto;
  margin: 3.75rem;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;

  @media (max-width: 767.98px) {
    margin: 10px 0;
  }
`;

const ModalBody = styled.div`
  padding: 10px 15px;

  @media (max-width: 767.98px) {
    padding: 10px 0;
  }
`;

const CloseButton = styled.span`
  cursor: pointer;
  float: right;
  font-size: 30px;
  margin: 0;
`;

const Title = styled.h3`
  font-family: 'exo-regular';
  float: left;
  font-weight: bold;
  text-align: left;
  padding-top: 10px;
  font-size: 24px;
  margin-bottom: 15px;
`;

const modalRoot = document.getElementById('root');

const ModalWrapper = props => {
  const { modalBodyStyles = {} } = props;
  return (
    <Fragment>
      <Wrapper
        className={`container ${props.className || ''}`}
        style={{
          transform: props.show ? 'translateY(0vh)' : 'translateY(-10000vh)',
          opacity: props.show ? '1' : '0'
          // display: props.show ? 'initial': 'none'
        }}
      >
        <ModalDialog>
          <ModalHeader>
            <Title>{props.title}</Title>
            <CloseButton onClick={props.close}>×</CloseButton>
          </ModalHeader>
          <ModalBody style={modalBodyStyles}>{props.children}</ModalBody>
        </ModalDialog>
      </Wrapper>
      {props.show && <Backdrop onClick={props.close} />}
    </Fragment>
  );
};

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);
  }

  render() {
    const { show, close, children, title, className, modalBodyStyles } = this.props;
    return ReactDOM.createPortal(
      <ModalWrapper show={show} modalBodyStyles={modalBodyStyles} close={close} className={className} title={title}>
        {children}
      </ModalWrapper>,
      this.el
    );
  }
}

export default Modal;
