import React from 'react'
import CSVLink from './CSVLink'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ExportButtonContainer = styled.div`
  float: right;
  margin-right: 15px;
`

const ExcelExport = ({reportFilename, reportData, reportFields, isLoading}) => {
  return (
    <ExportButtonContainer>
      <CSVLink
        filename={reportFilename}
        data={reportData}
        disabled={isLoading}
        headers={reportFields}
      >
        Export <i className={`${isLoading ? 'fa fa-spinner fa-spin fa-fw' : 'fa fa-download fa-fw'}`}/>    
      </CSVLink>
    </ExportButtonContainer>
  )
}

ExcelExport.propTypes = {
  reportFilename: PropTypes.string,
  reportData: PropTypes.arrayOf(PropTypes.object).isRequired,
  reportFields: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool
}

ExcelExport.defaultProps = {
  reportFilename: 'Report.csv',
  reportData: [],
  reportFields: [],
  isLoading: false
}

export default ExcelExport