import styled from 'styled-components';
import { COLORS } from '../../../vars/palette';

const getBackgroundColor = props => {
  if (props.primary) {
    return COLORS.AQUA_BLUE;
  } else if (props.danger) {
    return COLORS.CORAL;
  } else if (props.warning) {
    return COLORS.WARNING;
  } else if (props.info) {
    return COLORS.PALE_SKY_BLUE;
  } else if (props.default) {
    return COLORS.BLUE_GRAY_TWO;
  } else if (props.success) {
    return COLORS.TEALISH_GREEN;
  } else {
    return COLORS.BLUE_GRAY_TWO;
  }
};

const Button = styled.button`
  background-color: ${props =>
    props.backgroundColor || getBackgroundColor(props)};
  color: ${props => props.textColor || 'white'};
  border: none;
  font-family: 'roboto-medium';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  line-height: 1.5;
  outline: none;
  margin-left: 5px;
  min-width: 100px;
  &:hover {
    filter: brightness(95%);
    cursor: pointer;
  }

  &:active {
    filter: brightness(85%);
  }

  &.btn-primary {
    background-color: ${COLORS.AQUA_BLUE};
  }
  &.btn-danger {
    background-color: ${COLORS.CORAL};
  }
  &.btn-default {
    background-color: ${COLORS.BLUE_GRAY_TWO};
  }
  &.btn-warning {
    background-color: ${COLORS.WARNING};
  }
  &.btn-info {
    background-color: ${COLORS.PALE_SKY_BLUE};
  }
  &.btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  &.btn-lg {
    padding: 0.5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
`;

export default Button;
