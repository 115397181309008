import React from 'react';
import { InputWrapper } from './base';

class Textarea extends React.Component {
  onBlur = e => {
    const { onBlur, onChange } = this.props;
    const { value } = e.target;
    e.target.value = value.trim();
    onBlur && onBlur(e);
    onChange && onChange(e);
  };
  render() {
    const { pattern = '.*[^ ].*', maxLength = 250 } = this.props;
    return (
      <textarea
        maxLength={maxLength}
        className="form-control"
        autoComplete="off"
        style={{
          border: 'none',
          overflow: 'auto',
          outline: 'none',
          resize: 'none',
          width: '100%',
          fontFamily: 'exo-regular',
          fontSize: 14
        }}
        pattern={pattern}
        {...this.props}
        onBlur={this.onBlur}
      />
    );
  }
}

export default InputWrapper(Textarea);
