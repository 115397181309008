import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from '../../reusable-components/vars/palette';
import translate from '../../translate';
import { Button } from '../../reusable-components';

const CheckMark = styled.i`
  color: white;
  font-size: 5em !important;
  padding: 0.5em !important;
  background: ${COLORS.MINT_GREEN};
  border-radius: 50%;
`;

const Title = styled.h2`
  font-family: 'exo-regular';
  font-size: 30px;
  text-align: center;
  line-height: 35px;
`;

const StyledLink = styled(Link)`
  font-size: 20px;
  color: ${COLORS.AQUA_BLUE};
  text-align: center;
`;

const ConfirmationCheck = props => {
  const { context = {} } = props;
  const { owner = {} } = context.contract || {};

  return (
    <Fragment>
      <div className="row align-items-center justify-content-center" style={{ marginTop: '20%' }}>
        <CheckMark className="fa fa-check" />
      </div>
      <div className="row align-items-center justify-content-center mt-1">
        <Title className="col-8">{translate.t('contractSuccessSaved', { owner: owner.name })}</Title>
      </div>
    </Fragment>
  );
};

export default ConfirmationCheck;
