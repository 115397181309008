import React, { Component } from 'react';
import translate from '../../translate';
import styled from 'styled-components';
import { TextInput } from '../../reusable-components';
import { Subtitle, Label, Paragraph, Legend } from '../../reusable-components/styles';

const PriceInfoContainer = styled.div`
  width: 100%;
  margin-top: 10px;
`;

const SubtotalItem = styled.div`
  padding: 15px 15px 20px 18px;
  &:last-child,
  &:nth-child(2) {
    border-left: 1px solid #00a8ff;
  }

  @media (max-width: 575.98px) {
    &:last-child,
    &:nth-child(2) {
      border-left: none;
    }

    &:first-child {
      border-bottom: 1px solid #00a8ff;
    }
  }

  @media (max-width: 767.98px) {
    &:last-child {
      border-left: none;
      border-top: 1px solid #00a8ff;
    }
  }
`;

const Subtotal = styled.div`
  background-color: rgba(191, 233, 255, 0.3);
  border: 1px solid #00a8ff;
`;

class PriceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const onPropertyChange = this.props.handlePropertyChange;
    const { contract } = this.props;
    return (
      <div>
        <Subtitle>
          <div>
            <i className="fa fa-angle-right" />
            {` ${translate.t('price')} ${translate.t('information')}`}
          </div>
        </Subtitle>
        <PriceInfoContainer>
          <div className="row align-items-end">
            <div className="col-sm-6 col-lg-2 mb-2">
              <TextInput
                type="number"
                pattern="^0*[1-9]\d*$"
                placeholder="$0.00"
                label={translate.t('membershipPrice')}
                name="membershipPrice"
                max={999999999}
                min={0}
                onChange={e => {
                  const membershipPrice = e.target.value;
                  const dp =
                    membershipPrice > 0
                      ? Math.round((Number(membershipPrice) * Number(contract.requiredDPPercentage)) / 100)
                      : 0;

                  onPropertyChange(e.target.name, e.target.value);
                  onPropertyChange('requiredDownpayment', dp);
                }}
                value={contract.membershipPrice}
                required
              />
            </div>
            <div className="col-sm-6 col-lg-2 mb-2">
              <TextInput
                type="number"
                placeholder="$0.00"
                label={translate.t('closingCost')}
                name="closingCost"
                min={0}
                onChange={e => onPropertyChange(e.target.name, e.target.value)}
                max={contract.membershipPrice}
                value={contract.closingCost}
                required
              />
            </div>
            <Legend className="col-sm-6 col-lg-4 mb-2">
              <fieldset className="p-2">
                <legend>{translate.t('downpaymentRequired')}:</legend>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <TextInput
                      type="number"
                      pattern="^0*[1-9]\d*$"
                      placeholder="0%"
                      label={translate.t('percentage')}
                      name="requiredDPPercentage"
                      min={0}
                      onChange={e => {
                        onPropertyChange(
                          'requiredDownpayment',
                          Math.round((Number(contract.membershipPrice) * Number(e.target.value)) / 100)
                        );
                        onPropertyChange(e.target.name, e.target.value);
                      }}
                      max={100}
                      value={contract.requiredDPPercentage || 0}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <TextInput
                      type="number"
                      pattern="^0*[1-9]\d*$"
                      placeholder="$0.00"
                      label={translate.t('amount')}
                      name="requiredDownpayment"
                      min={0}
                      onChange={e => {
                        onPropertyChange(
                          'requiredDPPercentage',
                          Math.round((Number(e.target.value) / Number(contract.membershipPrice)) * 100)
                        );
                        onPropertyChange(e.target.name, e.target.value);
                      }}
                      max={contract.membershipPrice}
                      value={contract.requiredDownpayment}
                      required
                    />
                  </div>
                </div>
              </fieldset>
            </Legend>
            <Legend className="col-sm-6 col-lg-4 mb-2">
              <fieldset className="p-2">
                <legend>
                  {translate.t('total')} {translate.t('paid')} {translate.t('today')}
                </legend>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <TextInput
                      type="number"
                      pattern="^0*[1-9]\d*$"
                      min={0}
                      placeholder="0%"
                      name="paidTodayPercentage"
                      label={translate.t('percentage')}
                      disabled
                      max={100}
                      value={contract.paidTodayPercentage || 0}
                      required
                    />
                  </div>
                  <div className="col-md-6 mb-2">
                    <TextInput
                      type="number"
                      pattern="^0*[1-9]\d*$"
                      min={0}
                      placeholder="$0.00"
                      max={contract.membershipPrice}
                      disabled
                      label={translate.t('amount')}
                      value={contract.paidToday || 0}
                      required
                    />
                  </div>
                </div>
              </fieldset>
            </Legend>
          </div>

          <Subtotal className="row mt-2 mx-0">
            <SubtotalItem className="col-sm-6 col-md-3">
              <Label>
                {translate.t('total')} {translate.t('toPay')} {translate.t('today')}
              </Label>
              <Paragraph>
                {translate.l('currency', Number(contract.closingCost || 0) + Number(contract.requiredDownpayment || 0))}
              </Paragraph>
            </SubtotalItem>
            <SubtotalItem className="col-sm-6 col-md-3">
              <Label>
                {translate.t('total')} {translate.t('collected')} {translate.t('today')}
              </Label>
              <Paragraph>
                {translate.l('currency', (contract.payments || []).reduce((acc, p) => acc + Number(p.amount), 0))}
              </Paragraph>
            </SubtotalItem>
            <SubtotalItem className="col-sm-12 col-md-6">
              <Label>
                {translate.t('capital')} {translate.t('toFinance')}
              </Label>
              <Paragraph>{translate.l('currency', 0)}</Paragraph>
            </SubtotalItem>
          </Subtotal>
        </PriceInfoContainer>
      </div>
    );
  }
}

export default PriceForm;
