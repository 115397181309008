import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, DatePicker, Select } from '../../reusable-components';
import moment from 'moment';

class PersonalInfoForm extends Component {
  render() {
    const {
      person,
      index,
      countries,
      occupations,
      civilStatuses,
      identificationTypes,
      languages,
      context
    } = this.props;
    const civilStatus = civilStatuses.find(c => c.civilStatusId === Number(person.civilStatusId || 0)) || {};
    return (
      <Fragment>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <TextInput
              label={translate.t('firstName')}
              autoComplete="firstName"
              onChange={e => context.handleArrayChange('people', index, 'firstName', e.target.value)}
              required
              placeholder={translate.t('firstName')}
              value={person.firstName || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <TextInput
              label={translate.t('secondName')}
              onChange={e => context.handleArrayChange('people', index, 'secondName', e.target.value)}
              autoComplete="secondName"
              placeholder={translate.t('secondName')}
              value={person.secondName || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <TextInput
              label={translate.t('lastName')}
              onChange={e => context.handleArrayChange('people', index, 'lastName', e.target.value)}
              autoComplete="lastName"
              placeholder={translate.t('lastName')}
              value={person.lastName || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <TextInput
              label={translate.t('secondLastName')}
              onChange={e => context.handleArrayChange('people', index, 'secondLastName', e.target.value)}
              placeholder={translate.t('secondLastName')}
              autoComplete="secondLastName"
              value={person.secondLastName || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('nationality')}
              options={countries}
              isClearable={true}
              getOptionValue={o => o.countryId}
              getOptionLabel={o => o.name}
              value={countries.find(o => o.countryId === person.nationalityId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'nationalityId', (o || {}).countryId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('occupation')}
              options={occupations}
              isClearable={true}
              getOptionValue={o => o.occupationId}
              getOptionLabel={o => o.name}
              value={occupations.find(o => o.occupationId === person.occupationId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'occupationId', o.occupationId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('countryOfResidence')}
              options={countries}
              isClearable={true}
              getOptionValue={o => o.countryId}
              getOptionLabel={o => o.name}
              value={countries.find(o => o.countryId === person.livingCountryId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'livingCountryId', o.countryId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('idType')}
              options={identificationTypes}
              getOptionValue={o => o.identificationTypeId}
              getOptionLabel={o => o.name}
              isClearable={true}
              value={identificationTypes.find(o => o.identificationTypeId === person.identificationTypeId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'identificationTypeId', o.identificationTypeId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <TextInput
              label={translate.t('idNo')}
              autoComplete="idNo"
              onChange={e => context.handleArrayChange('people', index, 'identificationNumber', e.target.value)}
              required
              placeholder={translate.t('idNo')}
              value={person.identificationNumber || ''}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('language')}
              options={languages}
              getOptionValue={o => o.languageId}
              getOptionLabel={o => o.description}
              isClearable={true}
              value={languages.find(o => o.languageId === person.preferredLanguageId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'preferredLanguageId', o.languageId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <DatePicker
              label={translate.t('birthdate')}
              autoComplete="off"
              maxDate={moment()}
              placeholder={translate.t('birthdate')}
              name="birthdate"
              onSelect={date =>
                context.handleArrayChange('people', index, 'birthdate', date ? moment(date).format('YYYY-MM-DD') : null)
              }
              required
              value={person.birthdate}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            <Select
              placeholder={translate.t('maritalStatus')}
              options={civilStatuses}
              getOptionValue={o => o.civilStatusId}
              getOptionLabel={o => o.name}
              isClearable={true}
              value={civilStatuses.find(o => o.civilStatusId === person.civilStatusId)}
              required
              onChange={o => context.handleArrayChange('people', index, 'civilStatusId', o.civilStatusId)}
            />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-3 pb-2">
            {civilStatus.acronym === 'M' && (
              <DatePicker
                label={translate.t('weddingAniversary')}
                autoComplete="off"
                maxDate={moment()}
                placeholder={translate.t('weddingAniversary')}
                name="anniversaryDate"
                onSelect={date =>
                  context.handleArrayChange(
                    'people',
                    index,
                    'anniversaryDate',
                    date ? moment(date).format('YYYY-MM-DD') : null
                  )
                }
                value={person.anniversaryDate}
              />
            )}
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PersonalInfoForm;
