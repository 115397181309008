import React, { Children } from 'react'
import styled from 'styled-components'
import shortid from 'shortid'
import PropTypes from 'prop-types'
import { COLORS } from '../vars/palette'




const TabStyle = styled.div`
  display: flex;
  flex-direction: column
`

const TabItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px
`

const TabContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  
`

const TabButton = styled.button`
  background-color: ${(props) => props.active ? '#dcebf3' : COLORS.PALE_GRAY_TWO};
  
  border: none;
  cursor: pointer;
  padding: 10px;
  outline: none
  
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-family: 'exo-light';
  font-size: 18px;

  &:first-child {
    border-right: none
  }
  &:last-child {
    border-left: none
  }
`



//this is a dummy component used to render child tabs component
class TabItem extends React.Component {
  render(){
    return (<div></div>)
  }
}
TabItem.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.element
  ]).isRequired,
  name: PropTypes.string,
  onTabChanged: PropTypes.func
}

class Tabs extends React.Component {
  state = {
    active: '',
  }

  selectTab = (tab) => {
    const { onTabChanged } = this.props

      const { name } = tab.props
      this.setState({
        active: name || tab.id
      }, () => onTabChanged && onTabChanged(name || tab.id))
  }

  shouldComponentUpdate(newProps, newState){
   if(newState.active !== this.state.active){
     return true
   } else {
     return false
   }
  }

  isTabActive = (tab, tabIndex) => {
    const { name, id } = tab.props
    let isActive = false;

    const { active } = this.state
    if (active === name || active === id) {
      isActive = true
    } else if (active === '' && tabIndex === 0) {
      isActive = true
    }
    return isActive
  }
  render() {
    const { contentStyle } = this.props
    const childs = Children.toArray(this.props.children).map((item) => ({ ...item, id: shortid.generate() }))

    return (
      <TabStyle className='col-12'>
        <TabItemsContainer className='col-12'>
          {
            childs.map((item, i) => {

              let active = this.isTabActive(item, i)

              return (
                <TabButton active={active} key={item.id} onClick={() => this.selectTab(item)}>{item.props.title}</TabButton>
              )

            })
          }
        </TabItemsContainer>
        <TabContentContainer className='col-12' style={{padding: 0, ...contentStyle}}>
          {
            childs.map((item, i) => {
              const { content: Content } = item.props
              
              let display = this.isTabActive(item, i) ? 'flex' : 'none';
             
              return (
                <div style={{ display }} key={item.id}>
                  {
                    typeof (content) === 'function' ? <Content />  : Content
                  }
                </div>
              )
            })
          }
        </TabContentContainer>

      </TabStyle>
    )
  }
}

Tabs.Item = TabItem

export default Tabs