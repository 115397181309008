import React, { Component } from 'react'
import { UserService } from '../../services'

class Logout extends Component { 
  logOut = async (e) => {
    e.preventDefault()
    await UserService.unauthenticate()
    this.props.history.replace("/login")
  }

  render() {
    return <a href="/" onClick={this.logOut}>Logout</a>
  }
}

export default Logout