import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { TextInput, /*Dropdown,*/ DatePicker } from '../../reusable-components';
import moment from 'moment';

class PromissoryNoteForm extends Component {
  render() {
    const { promissoryNote, handleArrayChange, index } = this.props;
    return (
      <Fragment>
        <div className="row">
          <div className="col-md-6 mb-2">
            <DatePicker
              label={translate.t('date')}
              autoComplete="off"
              minDate={moment()}
              placeholder={translate.t('date')}
              required
              name="dueDate"
              onSelect={date => {
                handleArrayChange('promissoryNotes', index, 'dueDate', date);
              }}
              value={promissoryNote.dueDate}
            />
          </div>
          <div className="col-md-6 mb-2">
            <TextInput
              label={translate.t('amount')}
              onChange={e => {
                handleArrayChange('promissoryNotes', index, 'amount', e.target.value);
              }}
              pattern="^0*[1-9]\d*$"
              placeholder="$0.00"
              type="number"
              required
              max={promissoryNote.ADPLeft + parseFloat(promissoryNote.amount || 0)}
              min="0"
              value={promissoryNote.amount}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default PromissoryNoteForm;
