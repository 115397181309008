import React, { Component } from 'react';
import WelcomeButton from '../components/WelcomeButton';
import getMenuOptions from '../../menu';
class Welcome extends Component {
  render() {
    const { history } = this.props;
    const menuOptions = getMenuOptions();
    return (
      <div className="my-5">
        <div className="row justify-content-center">
          {menuOptions.map(i => (
            <div key={i.feature} className="col-5 col-md-4 col-lg-3 d-flex justify-content-center">
              <WelcomeButton history={history} {...i} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Welcome;
