import React, { Fragment } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import styled from 'styled-components';
import { Modal } from '../..';
import translate from '../../../translate';
import { Button } from '../Buttons';

const SignatureContainer = styled.div`
  width: 400px;
  height: 200px;
  border: 1px solid black;

  & img {
    width: 100%;
    height: 100%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;

  & i {
    font-size: 25px;
    margin-left: 5px;
  }

  & i:hover {
    opacity: 0.6;
  }

  & .required-msg {
    font-family: 'exo-regular';
    color: red;
    font-size: 14px;
  }
`;

const SignatureWrapper = styled.div`
  border: 1px solid black;
`;

class Signature extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...props };
  }

  onSubmitSign = () => {
    const value = this.sigCanvas.isEmpty() ? '' : this.sigCanvas.toDataURL('image/png');
    if (this.state.required && value === '') {
      this.setState({ showRequired: true });
      return;
    }

    this.setState({
      value
    });
    this.state.onChange && this.state.onChange(value);
    this.toggleShowSignatureModal();
  };

  toggleShowSignatureModal = () => {
    this.setState({ showSignatureModal: !this.state.showSignatureModal, showRequired: false });
  };

  refreshSignature = () => {
    const value = this.state.value;
    value ? this.sigCanvas.fromDataURL(value, { ratio: 1 }) : this.sigCanvas.clear();
  };

  render() {
    const { value, showRequired, onClear, placeholder, showSignatureModal } = this.state;
    return (
      <Fragment>
        <div style={{ textAlign: 'center' }}>
          <span className="label-container">{placeholder || ''}</span>
          <SignatureContainer
            onClick={() => {
              this.refreshSignature();
              this.toggleShowSignatureModal();
            }}
          >
            {value && <img src={value} alt="signature" />}
          </SignatureContainer>
          <span>{translate.t('clickToSign')}</span>
        </div>
        <Modal
          className="modal-sm"
          show={showSignatureModal}
          close={this.toggleShowSignatureModal}
          title={translate.t('signature')}
        >
          <SignatureWrapper>
            <SignatureCanvas
              clearButton={true}
              penColor="black"
              ref={ref => {
                this.sigCanvas = ref;
              }}
              canvasProps={{ width: 536, height: 268 }}
            />
          </SignatureWrapper>
          <ButtonsContainer>
            <div>{showRequired && <span className="required-msg">{translate.t('signRequired')}</span>}</div>
            <div>
              <Button
                onClick={() => {
                  this.sigCanvas.clear();
                  onClear && onClear();
                }}
              >
                {translate.t('clear')}
              </Button>
              <Button onClick={() => this.onSubmitSign()}>{translate.t('submit')}</Button>
              {/* <i
                className="fa fa-trash"
                onClick={() => {
                  this.sigCanvas.clear();
                  onClear && onClear();
                }}
              />
              <i
                className="fa fa-check-square"
                onClick={() => {
                  this.onSubmitSign();
                }}
              /> */}
            </div>
          </ButtonsContainer>
        </Modal>
      </Fragment>
    );
  }
}

export default Signature;
