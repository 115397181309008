import React, { Component } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

const DropdownContainer = styled.div`
  display: inline-block;
  position: relative;
`;

const DropdownBtn = styled.button`
  color: ${props => (props.isExpanded ? COLORS.WHITE : COLORS.INPUT_LABEL)};
  font-family: 'exo-regular';
  font-size: 15px;
  font-weight: 700;
  line-height: 24px;
  padding: 5px 7px;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 5px;
  border: 2px solid ${props => props.color || COLORS.AQUA_BLUE};
  background-color: ${props => (props.isExpanded ? props.color || COLORS.AQUA_BLUE : COLORS.WHITE)};
  margin: 0;
  transition: all 0.2s;

  &:hover {
    background: ${props => props.color || COLORS.AQUA_BLUE};
    color: ${COLORS.WHITE};
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &::after {
    display: ${props => (props.showArrow ? 'inline-block' : 'none')};
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
`;

const DropdownMenu = styled.div`
  display: ${props => (props.isExpanded ? 'block' : 'none')};
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(0px, 35px, 0px);
  z-index: 1000;
  min-width: 10rem;
  padding: 0.25rem 0;
  margin: 0.25rem 0 0;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  cursor: pointer;

  & > a:not(:last-child) {
    border-bottom: 1px solid #dee7ee;
  }
`;

const DropdownItem = styled.a`
  display: block;
  padding: 0.75rem 0.5rem;
  color: #212529;
  margin: 0 0.25rem;
  font-family: 'exo-light';
  font-size: 16px;

  &:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa;
  }
`;

class DropdownButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  expandDropdown = () => {
    this.setState({ isExpanded: true });
  };

  collapseDropdown = () => {
    setTimeout(() => {
      !this.isCancelled && this.setState({ isExpanded: false });
    }, 300);
  };

  componentWillUnmount() {
    this.isCancelled = true;
  }

  render() {
    const { dropdownText, options = [], dropdownColor } = this.props;
    const { isExpanded } = this.state;
    const hasOptions = options.length > 0;
    return (
      <DropdownContainer>
        <DropdownBtn
          color={dropdownColor}
          isExpanded={isExpanded}
          onClick={() => this.expandDropdown()}
          onBlur={() => this.collapseDropdown()}
          showArrow={hasOptions}
        >
          {dropdownText}
        </DropdownBtn>
        {hasOptions && (
          <DropdownMenu isExpanded={isExpanded}>
            {options.filter(o => !o.hidden).map((option, index) => (
              <DropdownItem key={index} onClick={() => option.onClick && option.onClick()}>
                {option.text}
              </DropdownItem>
            ))}
          </DropdownMenu>
        )}
      </DropdownContainer>
    );
  }
}

export default DropdownButton;
