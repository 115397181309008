import React from 'react'
import { COLORS } from '../../vars/palette'
import { TextInput } from '../../'
import i18n from '../../../translate'
import styled from 'styled-components'
import { PaymentContext } from '../context'

const Group = styled.div`
  margin-bottom: 10px
  &:last-child {
    margin-bottom: 5px
  }
`

const CapitalizedInput = styled(TextInput)`
  text-transform: capitalize
`

class CashForm extends React.Component {

  state = {
    name: '',
    folioNumber: ''
  }

  handleChanges = (e) => {
    const { target: { name, value } } = e
    this.setState({
      [name] : value
    }, () => this.setCashPaymentDetails())
  }

  setCashPaymentDetails = () => {
    const { name, folioNumber }  = this.state;

    this.props.context.setPaymentMethodDetail('cashPaymentDetail', { name, folioNumber })

  }
  
  render() {
    return (
      <div className='col-12' style={{  backgroundColor: COLORS.PALE_GRAY_TWO }}>
        <form>
          <Group className='form-group'>
            <CapitalizedInput label={i18n.t('name')} name={'name'} value={this.state.name} onChange={this.handleChanges} />
          </Group>
          <Group className='form-group'>
            <TextInput label={i18n.t('folioNumber')} name={'folioNumber'} value={this.state.folioNumber} onChange={this.handleChanges} />
          </Group>
        </form>
      </div>
    )
  }
}

export default (props) => (
  <PaymentContext.Consumer>
    {
      context => <CashForm {...props} context={context} />
    }
  </PaymentContext.Consumer>
)