import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { withLoader, Textarea, Button } from '../../reusable-components';
import { COLORS } from '../../reusable-components/vars/palette';
import Comment from '../components/Comment';
import { CommentService } from '../../services';
import shortid from 'shortid';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      entityType: props.entityType,
      entityId: props.entityId,
      comments: [],
      description: ''
    };
  }

  addComment = async e => {
    const { description, comments, entityType, entityId } = this.state;
    const comment = await CommentService.create(entityType, entityId, description);

    comments.push(comment);
    this.setState({ description: '', comments });
  };

  async componentDidMount() {
    const { entityType, entityId } = this.state;
    const comments = await CommentService.get(entityType, entityId);
    this.setState({ comments });
  }

  render() {
    const { comments = [], description } = this.state;
    const { rows = 4, readOnly } = this.props;
    return (
      <Fragment>
        {comments.map(c => (
          <Comment key={'COMMENT' + (c._id || shortid.generate())} comment={c} />
        ))}
        {!readOnly && (
          <Fragment>
            <Textarea
              rows={rows}
              maxLength={1000}
              value={description}
              onChange={e => this.setState({ description: e.target.value })}
            />
            <Button
              backgroundColor={COLORS.MINT_GREEN}
              textColor={COLORS.CHARCOAL_GRAY}
              style={{ margin: '10px 0px', opacity: 0.5 }}
              disabled={!description.trim()}
              onClick={this.addComment}
            >
              {translate.t('add')}
            </Button>
          </Fragment>
        )}
      </Fragment>
    );
  }
}
export default withLoader(Comments);
