import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { Select, Dropdown, Editor } from '../../reusable-components';
import { ContractService, PersonService } from '../../services';

const types = [
  { label: translate.t('appendants'), value: 'AN' },
  { label: translate.t('authorizations'), value: 'AU' }
];

const signatures = [{ label: translate.t('members'), value: 'MEM' }, { label: translate.t('employees'), value: 'EMP' }];

class AnnexForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      annexTypes: []
    };
  }

  componentDidMount() {
    this.count = 0;
  }

  onTypeChange = e => {
    const type = e.target.value;
    const { index, handleArrayChange } = this.props;
    handleArrayChange('annexes', index, 'type', type);
    ContractService.getAnnexTypes(this.props.contract.languageId, type).then(result =>
      this.setState({ annexTypes: result.data })
    );
  };

  async getEmployees(criteria, callback) {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }
      const employees = await PersonService.getEmployees(criteria);

      callback(employees);
    } catch (e) {
      callback([]);
    }
  }

  onAnnexTypeChange = e => {
    this.count++;
    const annexTypeId = Number(e.target.value);
    const currentAnnexType = this.state.annexTypes.find(a => a.annexTypeId === annexTypeId);
    if (!currentAnnexType) return;
    const { index, handleArrayChange } = this.props;
    handleArrayChange('annexes', index, 'annexTypeId', annexTypeId);
    handleArrayChange('annexes', index, 'annexTypeName', currentAnnexType.name);
    if (this.props.annex.annexId ? this.count > 2 : true)
      handleArrayChange('annexes', index, 'detail', currentAnnexType.content);
  };

  onContentChange = (content, delta, source, editor) => {
    const { index, handleArrayChange } = this.props;
    handleArrayChange('annexes', index, 'detail', content);
  };

  onSignatureTypeChange = e => {
    const { index, handleArrayChange } = this.props;
    const signatureType = e.target.value;
    handleArrayChange('annexes', index, 'signatureType', signatureType);
  };

  getEmployeeOptionLabel = e => {
    const { person = {} } = e;
    return `${person.firstName || ''} ${person.lastName || ''}`;
  };

  toggleContent = e => {
    e.preventDefault();
    this.setState(prevState => {
      return {
        showContent: !prevState.showContent
      };
    });
  };

  render() {
    const { annexTypes } = this.state;
    const { annex = {}, index, handleArrayChange } = this.props;
    const {
      type,
      annexTypeId,
      detail = '',
      signatureType,
      signedByEmployee = {},
      authorizedByEmployee = {},
      annexType = {}
    } = annex;

    return (
      <Fragment>
        <div className="row mb-3">
          <div className="col-md-4 mb-2 mb-md-0">
            <Dropdown
              label={translate.t('type')}
              items={types}
              optionValue="value"
              optionText="label"
              required
              value={type || annexType.type}
              onChange={this.onTypeChange}
            />
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <Dropdown
              label={translate.t('appendants')}
              items={annexTypes}
              optionValue="annexTypeId"
              optionText="name"
              id={'annexTypeId-' + index}
              required
              value={annexTypeId}
              onChange={this.onAnnexTypeChange}
            />
          </div>
          <div className="col-md-4 mb-2 mb-md-0">
            <Dropdown
              label={translate.t('signatures')}
              items={signatures}
              optionValue="value"
              optionText="label"
              required
              value={signatureType}
              onChange={this.onSignatureTypeChange}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-6">
            <Select
              isMulti={false}
              placeholder={translate.t('signedBy')}
              getOptionValue={e => e && e.employeeId}
              getOptionLabel={this.getEmployeeOptionLabel}
              defaultValue={signedByEmployee}
              cacheOptions
              onChange={a => {
                handleArrayChange('annexes', index, 'signedBy', a.employeeId);
                handleArrayChange('annexes', index, 'signedByEmployee', a);
              }}
              loadOptions={this.getEmployees}
              required={signatureType === 'EMP'}
            />
          </div>
          <div className="col-6">
            <Select
              isMulti={false}
              placeholder={translate.t('authorizedBy')}
              getOptionValue={e => e && e.employeeId}
              getOptionLabel={this.getEmployeeOptionLabel}
              defaultValue={authorizedByEmployee}
              cacheOptions
              onChange={a => {
                handleArrayChange('annexes', index, 'authorizedBy', a.employeeId);
                handleArrayChange('annexes', index, 'authorizedByEmployee', a);
              }}
              loadOptions={this.getEmployees}
            />
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-12" style={{ position: 'relative' }}>
            <Editor onChange={this.onContentChange} content={detail} />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default AnnexForm;
