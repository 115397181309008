import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../../vars/palette';

const classicInput = props => <input ref={props.ref} {...props} />;

const Input = styled(classicInput)`
  &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0px 9999px white;
  }
  background-color: #fff;
  font-family: 'exo-regular';
  width: 100%;
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 1px;
  border: none;
  color: ${COLORS.INPUT_TEXT_COLOR};
`;

export default Input;
