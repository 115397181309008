import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import withAuth from './auth/components/withAuth';
import { IntlProvider } from 'react-intl';
import Login from './auth/containers/Login';
import './reusable-components/bootstrap-grid.css';
import './reusable-components/main-style.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Layout } from './reusable-components';
import Welcome from './misc/containers/Welcome';
import Dashboard from './dashboard/containers/Dashboard';
import Contracts from './contract/containers/Contracts';
import Collect from './collect/containers/Collect';
import Reports from './report/containers/Reports';
import Admin from './admin/containers/Admin';
import ContractForm from './contract/containers/ContractForm';
import ContractDetail from './contract/containers/Detail';
import WelcomePayment from './collect/containers/WelcomePayment';
import PrintableContainer from './contract/containers/PrintableContainer';

ReactDOM.render(
  <IntlProvider locale="en">
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Layout>
          {/* <Route exact path="/" component={withAuth(Welcome)} /> */}
          <Route exact path="/" component={withAuth(Collect)} />

          <Route exact path="/dashboard" component={withAuth(Dashboard)} />
          <Route exact path="/contracts" component={withAuth(Contracts)} />
          <Switch>
            <Route exact path="/contracts/new" component={withAuth(ContractForm)} />
            <Route exact path="/contracts/:contractId" component={withAuth(ContractDetail)} />
          </Switch>
          <Route exact path="/contracts/:contractId/update" component={withAuth(ContractForm)} />
          <Route exact path="/print-contract/:contractId" component={withAuth(PrintableContainer)} />
          <Route exact path="/collect" component={withAuth(Collect)} />
          <Route exact path="/collect/new" component={withAuth(WelcomePayment)} />
          <Route exact path="/reports" component={withAuth(Reports)} />
          <Route exact path="/admin" component={withAuth(Admin)} />
        </Layout>
      </Switch>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById('root')
);
