import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

const CheckboxContainer = styled.label`
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-top: 5px;
  cursor: pointer;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &:hover input ~ span {
    background-color: ${COLORS.CHECKBOX_HOVER};
  }

  & input:checked ~ span {
    background-color: ${COLORS.CHECKBOX_CHECKED};
  }

  & input:checked ~ span:after {
    display: block;
  }

  & span:after {
    left: 8px;
    top: 4px;
    width: 5px;
    height: 11px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: ${COLORS.CHECKBOX_CHECKMARK};

  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`;

const Label = styled.span`
  padding-top: 2px;
  display: inline-block;
  margin-left: -10px;
`;

const Checkbox = ({ isChecked, onCheck, labelText = '', labelStyle = {} }) => {
  return (
    <CheckboxContainer>
      <Label style={labelStyle}>{labelText}</Label>
      <input type="checkbox" onChange={() => onCheck && onCheck(!isChecked)} checked={isChecked ? 'checked' : ''} />
      <Checkmark />
    </CheckboxContainer>
  );
};

export default Checkbox;
