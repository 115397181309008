import React from 'react';
import PropTypes from 'prop-types';
import { Body, Pagination, Header, ExcelExport } from './Components';
import styled from 'styled-components';

const TableStyled = styled.table`
  width: 100%;
  max-width: 100%;
`;

const TableBody = styled.tbody`
  max-height: 700px;
  overflow: auto;
  display: block;
`;
const TableContainer = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

export default class Table extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      selected: -1,
      sort: '',
      sortField: ''
    };
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  setSelected = selected => {
    !this.props.disableRowSelection && this.setState({ selected });
  };

  rowClicked = (e, item, index) => {
    const { onRowClick } = this.props;
    this.setSelected(index);
    onRowClick && onRowClick(item);
  };

  keyUp = (e, item, index) => {
    const { onRowKeyUp } = this.props;
    this.setSelected(index);
    onRowKeyUp && onRowKeyUp(e.keyCode, item);
  };

  handlePageChange(page) {
    page = page > Math.ceil(this.props.totalItems / this.props.itemsPerPage) ? 1 : page;
    const { onPaginateSearch } = this.props;
    this.setState({
      activePage: page,
      selected: -1
    });
    onPaginateSearch && onPaginateSearch(page);
  }

  onSort = column => () => {
    const { onSort } = this.props;
    let sort = this.state.sort;
    const sortField = column.sort;
    switch (sort) {
      case 'asc':
        sort = '';
        break;
      case 'desc':
        sort = 'asc';
        break;
      default:
        sort = 'desc';
    }
    this.setState({
      sort,
      sortField: sort ? sortField : '',
      activePage: 1,
      selected: -1
    });

    onSort && onSort(sort, sortField);
  };

  componentWillReceiveProps(props) {
    !(props.data || []).length && this.setState({ activePage: 1 });
  }

  renderTable() {
    const {
      tableFields,
      data = [],
      onMouseDown,
      onRowDoubleClick,
      redirectLink,
      itemsPerPage,
      showRowCheck = true,
      showRowMenu = true
    } = this.props;
    const { activePage, selected } = this.state;

    const defaultTable = Body({
      columns: tableFields,
      data,
      onRowKeyUp: this.keyUp,
      onRowClick: this.rowClicked,
      onMouseDown,
      onRowDoubleClick,
      activePage,
      redirectLink,
      itemsPerPage,
      selected,
      showRowCheck,
      showRowMenu
    });
    return defaultTable;
  }
  render() {
    const {
      tableFields,
      redirectLink,
      reportData = [],
      fetching,
      reportFilename,
      totalItems,
      itemsPerPage
    } = this.props;
    const pageCount = Math.ceil(this.props.totalItems / this.props.itemsPerPage);
    const { sort, sortField } = this.state;

    const reportFields = tableFields.filter(row => row.report);
    const isPaginated = pageCount > 1;
    const Table = this.renderTable();

    return (
      <div>
        <div>
          {isPaginated && (
            <Pagination
              handlePageChange={this.handlePageChange}
              activePage={this.state.activePage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              pageCount={pageCount}
              right={
                reportData.length ? (
                  <ExcelExport
                    reportFields={reportFields}
                    reportData={reportData}
                    isLoading={fetching}
                    reportFilename={reportFilename}
                  />
                ) : null
              }
            />
          )}
        </div>
        <TableContainer>
          <TableStyled>
            <Header
              onSort={this.onSort}
              sortField={sortField}
              sort={sort}
              headers={tableFields}
              redirectLink={redirectLink}
            />
            <TableBody>{Table}</TableBody>
          </TableStyled>
        </TableContainer>
      </div>
    );
  }
}

Table.propTypes = {
  className: PropTypes.string,
  fontSize: PropTypes.string,
  pagesDisplayed: PropTypes.number,
  reportData: PropTypes.arrayOf(PropTypes.object)
};

Table.defaultProps = {
  className: 'table-responsive react-pagination-table',
  fontSize: '8.6pt',
  reportData: [],
  pagesDisplayed: 5
};
