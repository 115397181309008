import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import translate from '../../translate';
import { Button } from '../../reusable-components';
import { COLORS } from '../../reusable-components/vars/palette';
import GiftForm from './GiftForm';
import ParticipantForm from './ParticipantForm';
import AnnexForm from './AnnexForm';
import PrintableAnnex from './PrintableAnnex';

import { Subtitle, Header, RemoveButtonContainer, RemoveButton } from '../../reusable-components/styles';
import { SubContainer } from '../../reusable-components/styles';
import { PersonService, ContractService } from '../../services';
import { withLoader } from '../../reusable-components';

import ChargebackForm from './ChargebackForm';
import CertificateForm from './CertificateForm';

const BorderedDiv = styled.div`
  border: 5px solid ${COLORS.PALE_GRAY_TWO};
  padding: 5px;
`;

class SpecialProvision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      positions: [],
      annex: {}
    };
  }

  componentDidMount() {
    const { context } = this.props;
    PersonService.getPositions().then(positions => this.setState({ positions }));
    ContractService.getParticipants(context.contract.contractId).then(async participants => {
      participants.map(p => (p.person = p.employee.person));
      await context.handlePropertyChange('participants', participants);
    });
  }

  printAnnex = annex => () => {
    this.setState({ annex }, () => {
      const content = document.getElementById(`printable-annex`);
      const screen = window.open('');
      screen.document.write(content.innerHTML);
      screen.document.close();
      screen.focus();
    });
  };

  printCertificate = certificate => () => {
    const content = document.getElementById(`printable-certificate`);
    const screen = window.open('');
    screen.document.write(content.innerHTML);
    screen.document.close();
    screen.focus();
  };

  async getGifts(criteria, callback) {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }
      const gifts = await ContractService.getGifts(criteria);

      callback(gifts);
    } catch (e) {
      callback([]);
    }
  }

  render() {
    const { positions = [], annex } = this.state;
    const { context } = this.props;
    const { gifts = [], participants = [], chargeBacks = [], annexes = [], certificates = [] } = context.contract;

    return (
      <Fragment>
        <div style={{ paddingBottom: '10px' }}>
          <Subtitle>
            <div>
              <i className="fa fa-angle-right" />
              {` ${translate.t('certificates')}`}
            </div>
          </Subtitle>
          <SubContainer>
            {certificates.map((certificate, index) => {
              return (
                <BorderedDiv className="mb-3" key={certificate.certificateId || certificate.uiid}>
                  <CertificateForm
                    key={certificate.certificateId || certificate.uiid}
                    contract={context.contract}
                    certificate={certificate}
                    certificates={certificates}
                    index={index}
                    handleArrayChange={context.handleArrayChange}
                    handleArrayChildChange={context.handleArrayChildChange}
                    addChildItem={context.addChildItem}
                    removeChildItem={context.removeChildItem}
                  />
                  <RemoveButtonContainer style={{ justifyContent: 'space-between' }}>
                    <i
                      className="fa fa-print"
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={this.printCertificate(certificate)}
                    />
                    <RemoveButton
                      className="fa fa-trash"
                      onClick={() => context.removeItem('certificates', index)}
                      type="button"
                    />
                  </RemoveButtonContainer>
                </BorderedDiv>
              );
            })}
          </SubContainer>
          <Button
            style={{ opacity: 0.5 }}
            backgroundColor={COLORS.MINT_GREEN}
            textColor={COLORS.CHARCOAL_GRAY}
            type="button"
            onClick={() => context.addItem('certificates')}
          >
            {`${translate.t('add')} ${translate.t('certificates')} `}
          </Button>
        </div>

        <div style={{ paddingBottom: '10px' }}>
          <Subtitle>
            <Header>
              <i className="fa fa-angle-right" />
              {` ${translate.t('incentives')}`}
            </Header>
          </Subtitle>
          <SubContainer>
            {gifts.map((gift, index) => {
              return (
                <div className="row mb-3" key={gift.uiid || gift.giftId}>
                  <div className="col-11">
                    <GiftForm
                      giftsSelected={gifts}
                      gift={gift}
                      index={index}
                      handleArrayChange={context.handleArrayChange}
                    />
                  </div>
                  <div className="col-1 mx-0 d-flex align-items-center">
                    <RemoveButtonContainer>
                      <RemoveButton
                        className="fa fa-trash"
                        onClick={() => context.removeItem('gifts', index)}
                        type="button"
                      />
                    </RemoveButtonContainer>
                  </div>
                </div>
              );
            })}
            <Button
              style={{
                opacity: 0.5,
                marginLeft: 0,
                marginTop: 15
              }}
              backgroundColor={COLORS.MINT_GREEN}
              textColor={COLORS.CHARCOAL_GRAY}
              type="button"
              onClick={() => context.addItem('gifts')}
            >
              {`${translate.t('add')} ${translate.t('incentive')}`}
            </Button>
          </SubContainer>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Subtitle>
            <Header>
              <i className="fa fa-angle-right" />
              {` ${translate.t('participants')}`}
            </Header>
          </Subtitle>
          <SubContainer>
            {participants.map((participant, index) => {
              return (
                <div
                  className="row mb-3"
                  key={
                    participant.uiid ||
                    (participant.contractParticipant ? participant.contractParticipant.contractParticipantId : index)
                  }
                >
                  <div className="col-11">
                    <ParticipantForm
                      participant={participant}
                      index={index}
                      participants={participants}
                      handleArrayChange={context.handleArrayChange}
                      positions={positions}
                    />
                  </div>
                  <div className="col-1 mx-0 d-flex align-items-center">
                    <RemoveButtonContainer>
                      <RemoveButton
                        className="fa fa-trash"
                        onClick={() => context.removeItem('participants', index)}
                        type="button"
                      />
                    </RemoveButtonContainer>
                  </div>
                </div>
              );
            })}
            <Button
              style={{
                opacity: 0.5,
                marginLeft: 0,
                marginTop: 15
              }}
              backgroundColor={COLORS.MINT_GREEN}
              textColor={COLORS.CHARCOAL_GRAY}
              type="button"
              onClick={() => context.addItem('participants')}
            >
              {`${translate.t('add')} ${translate.t('participant')}`}
            </Button>
          </SubContainer>
        </div>
        <Subtitle>
          <Header>
            <i className="fa fa-angle-right" />
            {` ${translate.t('chargebacks')}`}
          </Header>
        </Subtitle>
        <div>
          {chargeBacks.map((chargeBack, index) => {
            return (
              <div key={chargeBack.uiid || chargeBack.chargebackId || index} className="row mt-3">
                <div className="col-11">
                  <ChargebackForm chargeBack={chargeBack} index={index} handleArrayChange={context.handleArrayChange} />
                </div>
                <div className="col-1 mx-0 d-flex align-items-center">
                  <RemoveButtonContainer>
                    <RemoveButton
                      className="fa fa-trash"
                      onClick={() => context.removeItem('chargeBacks', index)}
                      type="button"
                    />
                  </RemoveButtonContainer>
                </div>
              </div>
            );
          })}
          <Button
            style={{
              opacity: 0.5,
              marginLeft: 0,
              marginTop: 15
            }}
            backgroundColor={COLORS.MINT_GREEN}
            textColor={COLORS.CHARCOAL_GRAY}
            type="button"
            onClick={() => context.addItem('chargeBacks')}
          >
            {`${translate.t('add')} ${translate.t('chargeback')}`}
          </Button>
        </div>
        <div style={{ paddingBottom: '10px' }}>
          <Subtitle>
            <div>
              <i className="fa fa-angle-right" />
              {` ${translate.t('authorizations')} & ${translate.t('appendants')}`}
            </div>
          </Subtitle>
          <SubContainer>
            {annexes.map((annex, index) => {
              return (
                <BorderedDiv className="mb-3" key={annex.annexId || annex.uuid}>
                  <AnnexForm
                    contract={context.contract}
                    annex={annex}
                    index={index}
                    handleArrayChange={context.handleArrayChange}
                  />
                  <RemoveButtonContainer style={{ justifyContent: 'space-between' }}>
                    <i
                      className="fa fa-print"
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={this.printAnnex(annex)}
                    />
                    <RemoveButton
                      className="fa fa-trash"
                      onClick={() => context.removeItem('annexes', index)}
                      type="button"
                    />
                  </RemoveButtonContainer>
                </BorderedDiv>
              );
            })}
          </SubContainer>
          <Button
            style={{ opacity: 0.5 }}
            backgroundColor={COLORS.MINT_GREEN}
            textColor={COLORS.CHARCOAL_GRAY}
            type="button"
            onClick={() => context.addItem('annexes')}
          >
            {`${translate.t('add')} ${translate.t('authorization')} / ${translate.t('appendant')}`}
          </Button>
        </div>
        <PrintableAnnex contract={context.contract} annex={annex} />
      </Fragment>
    );
  }
}
export default withLoader(SpecialProvision);
