
const helpers = {

  handleColumnType: (columnValue, row) => {
    switch(row.type) {
      case 'date':
        return helpers.formatDate(columnValue)
      case 'money':
        return helpers.moneyFormat(columnValue)
      default:
        return columnValue
    }
  },
  
  //Table props handlers
  setTableRowValue: (item, row) => {

    if(typeof row.column === 'function'){
      return helpers.handleColumnType(helpers.handlePropAsFunction(item, row.column), row)
    }

    return helpers.handleColumnType(helpers.handleProps(row.column, item), row)
  },

  handleProps: (props, item, acc=item) => {
    let rowProperties = props.split('.')
    let [firstProp] = rowProperties

    if (Array.isArray(acc && acc[firstProp])) {
      let [firstSubProp] = acc[firstProp]
      rowProperties.shift()

      return helpers.handleProps(rowProperties.join('.'), item, firstSubProp)
    }
    if (rowProperties.length === 1) {
      return acc && acc[firstProp]
    }
    rowProperties.shift()
    return helpers.handleProps(rowProperties.join('.'), item, acc[firstProp])
  },

  handlePropAsFunction: (item, fn) => {
    return fn(item)
  },

  //Modificators

  formatDate: (item) => {
    const itemDate = new Date()
    let dateWithTimeStamp = ''
    if (item) {
      dateWithTimeStamp = (item.includes('T') || item.includes('Z')) ? item.toString() : `${item.toString()}T13:00:00`
      return new Date(itemDate.setDate(new Date(dateWithTimeStamp).getDate())).toDateString()
    }
    return dateWithTimeStamp
  },

  moneyFormat: (value) => value && `$${parseFloat(value, 10).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`,

  //Pagination section
  
  paginationRange: (activePage, pageCount, pagesDisplayed) => {
    let rangeFrom = activePage === 1 ? 0 : (pageCount < pagesDisplayed) ? 0 : activePage - 1
    let rangeTo = activePage === 1 ? pagesDisplayed : (pagesDisplayed + activePage - 1)
    
    if((rangeFrom + pagesDisplayed) >= pageCount){
      rangeFrom = (pageCount - pagesDisplayed) < 0 ? 0 : (pageCount - pagesDisplayed)
    }
    if(rangeTo > pageCount){
      rangeTo = pageCount
    }

    return {rangeFrom, rangeTo}
  },

  paginationIndex: (page, index, limit) => {
    return (page * limit) - (limit - index)
  },

  toCSV: (data, headers, separator) => {
    if (helpers.isJsons(data)) return helpers.jsons2csv(data, headers, separator)
    throw new TypeError('Data should be a "Array of objects" ')
  },

  isJsons: (array) => { 
    return Array.isArray(array) && array.every(row => {
      return typeof row === 'object' && !(row instanceof Array)
    })
  },

  jsons2csv: (data, headers, separator) => {
    return helpers.joiner(helpers.jsons2arrays(data, headers), separator)
  },

  joiner: (data,separator = ',') => {
    return data.map((row, index) => { 
      return row.map((element) => '"' + element + '"').join(separator)
    }).join('\n')
  },

  jsons2arrays:(jsons, headers) => {
    headers = headers || helpers.jsonsHeaders(jsons)
     
    let headerLabels = headers
    if (helpers.isJsons(headers)) {
      headerLabels = headers.map((header) => header.header)
    }
     
    const data = jsons.map((object) => {
      return headers.map((header) => {
        return helpers.setTableRowValue(object, header)
      })
    })
    return [headerLabels, ...data]
  },

  jsonsHeaders: (array) => {
    return Array.from(
      array.map(json => Object.keys(json)).reduce((a, b) => new Set([...a, ...b]), [])
    )
  },

  buildURI: (data, uFEFF, headers, separator) => {
    return encodeURI(
      `data:text/csvcharset=utf-8,${uFEFF ? '\uFEFF' : ''}${helpers.toCSV(data, headers, separator)}`)
  }
}

export default helpers