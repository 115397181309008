import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import { DatePicker, NumberInput, TextInput } from '../../reusable-components';
import moment from 'moment';
import { calculateMonthlyPayment } from '../../reusable-components/Table/AmortizationTable';

class LoanForm extends Component {
  render() {
    const { handlePropertyChange, contract } = this.props;
    const monthlyPayment = (
      calculateMonthlyPayment(
        contract.totalFinanced,
        contract.loanTermQuantity,
        contract.loanInterest,
        contract.loanFirstDueDate
      ) || {}
    ).monthlyPayment;

    return (
      <Fragment>
        <div className="row">
          <div className="col-md-3 mb-2">
            <NumberInput
              label={translate.t('terms')}
              onChange={e => handlePropertyChange(e.target.name, e.target.value)}
              pattern="^0*[1-9]\d*$"
              placeholder="0"
              type="number"
              step={1}
              min={1}
              max={500}
              required
              name="loanTermQuantity"
              value={contract.loanTermQuantity}
            />
          </div>
          <div className="col-md-3 mb-2">
            <NumberInput
              label={translate.t('interest')}
              onChange={e => handlePropertyChange(e.target.name, e.target.value)}
              pattern="^0*[1-9]\d*$"
              placeholder="0%"
              type="number"
              name="loanInterest"
              step={0.01}
              min={0}
              max={100}
              value={contract.loanInterest}
            />
          </div>
          <div className="col-md-3 mb-2">
            <DatePicker
              label={translate.t('firstDueDate')}
              autoComplete="off"
              minDate={moment()}
              maxDate="2050-12-31"
              placeholder={translate.t('firstDueDate')}
              name="loanFirstDueDate"
              onSelect={date => {
                handlePropertyChange('loanFirstDueDate', date);
              }}
              value={contract.loanFirstDueDate}
            />
          </div>
          <div className="col-md-3 mb-2">
            <TextInput
              placeholder="$0.00"
              label={translate.t('monthlyPayment')}
              value={translate.l('currency', monthlyPayment || 0)}
              disabled
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
export default LoanForm;
