import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import { withRouter } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Content = styled.div`
  margin-bottom: 20px;
`;

const Container = styled.div`
  min-height: 100vh;
  margin-bottom: 20px;
`;

class Layout extends React.Component {
  render() {
    return (
      <Container>
        <Header history={this.props.history} />
        <div className="app-container">
          <Content>{this.props.children}</Content>
        </div>
        <ToastContainer />
      </Container>
    );
  }
}

export default withRouter(Layout);
