import React, { Component, Fragment } from 'react';
import translate from '../../translate';
import styled from 'styled-components';
import ContractInfoView from './ContractInfoView';
import OwnersDataView from './OwnersDataView';
import { Table, Toast, Modal, Button } from '../../reusable-components';
import MembershipCostView from './MembershipCostView';
import FinancingView from './FinancingView';
import AnnexDetailView from './AnnexDetailView';
import ChargebackView from './ChargebackView';
import { InfoButton, Badge, Subtitle, SubContainer, Paragraph } from '../../reusable-components/styles';
import { ContractService } from '../../services';
import { withLoader } from '../../reusable-components';
import CertificateView from './CertificateView';
import { COLORS } from '../../reusable-components/vars/palette';
import DropdownButton from '../../reusable-components/common/Inputs/DropdownButton';
import Comments from '../../misc/containers/Comments';
const OWNER_ACRONYM = 'OWN';
const CO_OWNER_ACRONYM = 'COWN';
const ANNEX_TYPE_ANNEX = 'AN';
const STATUS_DRAFT = 'DRAFT';
const STATUS_ANNULLED = 'ANN';
const STATUS_VERIFYING = 'VRF';
const STATUS_PENDING = 'PND';
const STATUS_PROCESSABLE = 'PRO';
const STATUS_CANCELED = 'CAN';

const SignatureLabel = styled.span`
  display: block;
  font-family: 'exo-light';
  font-size: 11px;
  text-align: center;
`;

const beneficiariesTableFields = [
  { header: translate.t('name'), column: 'name', size: '60%' },
  { header: translate.t('relationshipType'), column: 'relationshipType', size: '40%' }
];

const promissoryNotesTableFields = [
  { header: translate.t('dueDate'), column: 'dueDate', size: '40%' },
  { header: translate.t('amount'), column: 'amount', size: '35%', type: 'money' },
  { header: translate.t('type'), column: 'type', size: '35%' }
];

const incentivesTableFields = [
  { header: translate.t('code'), column: 'code', size: '20%' },
  { header: translate.t('productName'), column: 'productName', size: '60%' },
  { header: translate.t('quantity'), column: 'quantity', size: '20%' }
];

const participantsTableFields = [
  { header: translate.t('code'), column: 'code', size: '20%' },
  { header: translate.t('name'), column: 'name', size: '45%' },
  { header: translate.t('position'), column: 'position', size: '35%' }
];
const certificatesTableFields = [
  { header: translate.t('folioNumber'), column: 'folioNumber', size: '20%' },
  { header: translate.t('serialNumber'), column: 'serialCode', size: '45%' },
  { header: translate.t('vigency'), column: 'months', size: '35%' }
];
class ContractDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractId: props.context.contractId || props.context.contract.contractId,
      showAnnexDetail: false,
      showChargeback: false,
      showCertificate: false,
      selectedAnnex: {},
      selectedChargeback: {},
      selectedCertificate: {},
      contract: {}
    };
  }

  toggleShowCertificate = selectedCertificate => {
    this.setState({ showCertificate: !this.state.showCertificate, selectedCertificate });
  };

  toggleShowAnnexDetail = () => {
    this.setState({ showAnnexDetail: !this.state.showAnnexDetail });
  };

  toggleShowChargeback = () => {
    this.setState({ showChargeback: !this.state.showChargeback });
  };

  showAnnexesPopup = ({ annex }) => {
    this.toggleShowAnnexDetail();
    this.setState({ selectedAnnex: annex });
  };

  showChargeBacksPopup = ({ chargeback }) => {
    this.toggleShowChargeback();
    this.setState({ selectedChargeback: chargeback });
  };

  updateContractStatus = async (contractId, statusAcronym, reloadPage) => {
    try {
      await ContractService.updateContractStatus(contractId, statusAcronym);
    } catch (e) {
      return Toast.error(translate.t('errorUpdatingContractStatusMsg'));
    }
    Toast.success(translate.t('contractStatusUpdatedMsg'));

    switch (statusAcronym) {
      case STATUS_DRAFT:
        return this.props.context.history.push(`/contracts/${contractId}/update`);
      case STATUS_VERIFYING:
      case STATUS_ANNULLED:
      case STATUS_CANCELED:
        return this.props.context.history.push(`/contracts/${contractId}/${reloadPage ? 'update' : ''}`);
      default:
    }
  };

  toggleShowCancelContract = () => {
    this.setState({ showCancelContract: !this.state.showCancelContract });
  };

  printContract = contractId => {
    return this.props.context.history.push(`/print-contract/${contractId}`);
  };

  async componentDidMount() {
    await ContractService.getById(this.state.contractId).then(contract => this.setState({ contract }));
  }

  render() {
    const annexesTableFields = [
      { header: translate.t('type'), column: 'type', size: '40%' },
      { header: translate.t('name'), column: 'name', size: '45%' },
      {
        header: '',
        column: item => <InfoButton className="fa fa-info-circle" onClick={() => this.showAnnexesPopup(item)} />,
        size: '15%'
      }
    ];

    const chargeBacksTableFields = [
      {
        header: translate.t('employees'),
        column: item => (
          <Fragment>
            {item.chargeback.employees.filter(employee => employee && employee.name).map(employee => (
              <Badge key={employee.employeeId}>{employee.name}</Badge>
            ))}
          </Fragment>
        ),
        size: '40%'
      },
      { header: translate.t('amount'), column: 'amount', size: '15%', type: 'money' },
      { header: translate.t('authorizedBy'), column: 'authorizedBy', size: '30%' },
      {
        header: '',
        column: item => <InfoButton className="fa fa-info-circle" onClick={() => this.showChargeBacksPopup(item)} />,
        size: '15%'
      }
    ];

    const { contract = {}, showCancelContract } = this.state;
    let {
      people = [],
      beneficiaries = [],
      promissoryNotes = [],
      participants = [],
      gifts = [],
      annexes = [],
      chargeBacks = [],
      certificates = []
    } = contract;
    const owner = people.find(
      (p, index) =>
        ((p.contractPerson || {}).personTitle && p.contractPerson.personTitle.acronym === OWNER_ACRONYM) || index === 0
    );
    const coowner = people.find(
      (p, index) =>
        ((p.contractPerson || {}).personTitle && p.contractPerson.personTitle.acronym === CO_OWNER_ACRONYM) || index > 0
    );
    beneficiaries = beneficiaries.map(beneficiary => {
      return {
        name: `${beneficiary.firstName} ${beneficiary.lastName}`,
        relationshipType: beneficiary.relationshipType ? beneficiary.relationshipType.name : ''
      };
    });
    promissoryNotes = promissoryNotes.map(promissoryNote => {
      return {
        dueDate: promissoryNote.dueDate,
        amount: promissoryNote.amount,
        type: promissoryNote.promissoryNoteType ? promissoryNote.promissoryNoteType.name : ''
      };
    });
    participants = participants.map(participant => {
      return {
        code: participant.code,
        name: participant.person ? `${participant.person.firstName} ${participant.person.lastName}` : '',
        position: participant.position
      };
    });
    gifts = gifts.map(gift => {
      return {
        code: gift.acronym || translate.t('notAvailableAbbrev'),
        productName: gift.name,
        quantity: parseInt(gift.quantity, 10)
      };
    });
    annexes = annexes.map(annex => {
      return {
        type: annex.annexType && translate.t(annex.annexType.type === ANNEX_TYPE_ANNEX ? 'appendant' : 'authorization'),
        name: annex.annexType ? annex.annexType.name : '',
        annex
      };
    });
    chargeBacks = chargeBacks.map(chargeback => {
      return {
        amount: chargeback.amount,
        authorizedBy: chargeback.authorizedByName,
        chargeback
      };
    });

    let dropdownColor = '';
    let dropdownOptions = [];

    switch ((contract.status || {}).acronym) {
      case STATUS_DRAFT:
        dropdownColor = COLORS.WARNING;
        dropdownOptions = [
          {
            text: translate.t('annullContract'),
            onClick: () => this.updateContractStatus(contract.contractId, STATUS_ANNULLED)
          },
          {
            text: translate.t('verify'),
            onClick: () => this.updateContractStatus(contract.contractId, STATUS_VERIFYING)
          }
        ];
        break;
      case STATUS_VERIFYING:
        dropdownColor = COLORS.AQUA_BLUE;
        dropdownOptions = [
          {
            text: translate.t('annullContract'),
            onClick: () => this.updateContractStatus(contract.contractId, STATUS_ANNULLED, true)
          },
          {
            text: translate.t('sendToDraft'),
            onClick: () => this.updateContractStatus(contract.contractId, STATUS_DRAFT)
          },
          {
            text: translate.t('signContract'),
            onClick: () => this.printContract(contract.contractId)
          }
        ];
        break;
      case STATUS_PENDING:
      case STATUS_PROCESSABLE:
        dropdownColor = COLORS.SPRING_GREEN;
        dropdownOptions = [
          {
            text: translate.t('cancel'),
            onClick: () => this.toggleShowCancelContract()
          }
        ];
        break;
      case STATUS_ANNULLED:
      case STATUS_CANCELED:
        dropdownColor = COLORS.CHARCOAL_GRAY;
        break;
      default:
        dropdownColor = COLORS.SPRING_GREEN;
    }

    return (
      <Fragment>
        <div
          className="mt-4"
          style={{
            fontFamily: 'exo-regular',
            fontSize: '24px',
            margin: '10px'
          }}
        >
          <div className="d-flex align-items-center">
            <strong>{`${translate.t('noAbbrev')} ${contract.number || ''}`}</strong>
            {contract.status && (
              <div className="ml-3">
                <DropdownButton
                  dropdownText={contract.status.name}
                  dropdownColor={dropdownColor}
                  options={dropdownOptions}
                />
              </div>
            )}
          </div>

          <h1 className="mt-4 mb-2">{translate.t('owner')}</h1>
          <OwnersDataView owner={owner} styles={{ backgroundColor: '#f7f9fa' }} />
          {coowner && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('coOwner')}</h1>
              <OwnersDataView owner={coowner} />
            </Fragment>
          )}
          <h1 className="mt-4 mb-2">{translate.t('product')}</h1>
          <ContractInfoView contract={contract} classNames="mt-4 p-3" />

          <div className="row mt-5 mx-0">
            <div className="col-md-6 mb-3 px-0">
              <h2 className="mb-3">{translate.t('membershipCost')}</h2>
              <MembershipCostView contract={contract} />
              <FinancingView contract={contract} />
            </div>
            <div className="col-md-6 px-0 pl-md-2">
              <h2 className="mb-3">{translate.t('adp')}</h2>
              <Table
                tableFields={promissoryNotesTableFields}
                data={promissoryNotes}
                disableRowSelection={true}
                showRowCheck={false}
                showRowMenu={false}
              />
            </div>
          </div>
          {beneficiaries.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('beneficiaries')}</h1>
              <div className="row">
                <div className="col-12">
                  <Table
                    tableFields={beneficiariesTableFields}
                    data={beneficiaries}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {gifts.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('incentives')}</h1>
              <div className="row mb-2">
                <div className="col-12">
                  <Table
                    tableFields={incentivesTableFields}
                    data={gifts}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {annexes.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('authAndAppends')}</h1>
              <div className="row mb-2">
                <div className="col-12">
                  <Table
                    tableFields={annexesTableFields}
                    data={annexes}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {chargeBacks.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('chargebacks')}</h1>
              <div className="row mb-2">
                <div className="col-12">
                  <Table
                    tableFields={chargeBacksTableFields}
                    data={chargeBacks}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {certificates.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('certificates')}</h1>
              <div className="row mb-2">
                <div className="col-12">
                  <Table
                    tableFields={certificatesTableFields}
                    data={certificates}
                    disableRowSelection={false}
                    onRowDoubleClick={this.toggleShowCertificate}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          {participants.length > 0 && (
            <Fragment>
              <h1 className="mt-5 mb-2">{translate.t('participants')}</h1>
              <div className="row mb-2">
                <div className="col-12">
                  <Table
                    tableFields={participantsTableFields}
                    data={participants}
                    disableRowSelection={true}
                    showRowCheck={false}
                    showRowMenu={false}
                  />
                </div>
              </div>
            </Fragment>
          )}
          <div className="row justify-content-around my-5">
            {people.filter(p => p.contractPerson.signature).map((p, index) => {
              return (
                <div key={index} className="col-auto">
                  <SignatureLabel>
                    {`${p.name} (${index ? translate.t('coOwner') : translate.t('owner')})`}
                  </SignatureLabel>
                  <img
                    width="400"
                    height="200"
                    src={p.contractPerson.signature}
                    alt="signature"
                    style={{ border: '1px solid #dee7ee', marginTop: '5px' }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <AnnexDetailView
          show={this.state.showAnnexDetail}
          annex={this.state.selectedAnnex}
          close={this.toggleShowAnnexDetail}
        />
        <ChargebackView
          show={this.state.showChargeback}
          chargeback={this.state.selectedChargeback}
          close={this.toggleShowChargeback}
        />
        <CertificateView
          show={this.state.showCertificate}
          certificate={this.state.selectedCertificate}
          close={this.toggleShowCertificate}
          selectedCerticate
        />
        {(contract.status || {}).acronym !== STATUS_DRAFT && (
          <Fragment>
            <Subtitle>
              <h1>{translate.t('verificationComments')}</h1>
            </Subtitle>
            <SubContainer className="mb-5">
              <Comments
                rows={4}
                entityType="CONTRACT"
                entityId={this.state.contractId}
                readOnly={(contract.status || {}).acronym !== STATUS_VERIFYING}
              />
            </SubContainer>
          </Fragment>
        )}
        <Modal
          className="modal-sm"
          show={showCancelContract}
          close={this.toggleShowCancelContract}
          title={translate.t('confirmation')}
        >
          <Paragraph style={{ fontSize: '18px', marginBottom: '30px', color: 'black' }}>
            {translate.t('cancelContractMsg')}
          </Paragraph>
          <div className="d-flex justify-content-end">
            <Button onClick={() => this.toggleShowCancelContract()}>{translate.t('no')}</Button>
            <Button
              onClick={() => {
                this.updateContractStatus(this.state.contractId, STATUS_CANCELED, true);
                this.toggleShowCancelContract();
              }}
            >
              {translate.t('yes')}
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
export default withLoader(ContractDetailView);
