import React, { Component, Fragment } from 'react';
import ProductForm from './ProductForm';
import PriceForm from './PriceForm';
import PromissoryNoteForm from './PromissoryNoteForm';
import LoanForm from './LoanForm';
import styled from 'styled-components';
import { COLORS } from '../../reusable-components/vars/palette';
import { Button, Select, Toast } from '../../reusable-components';
import {
  Subtitle,
  BiColorContainer,
  Total,
  Label,
  Header,
  RemoveButtonContainer,
  RemoveButton,
  SubContainer
} from '../../reusable-components/styles';
import translate from '../../translate';
import { ContractService, PaymentService } from '../../services';
import { PaymentForm as MakePaymentForm } from '../../reusable-components/payments';
import PaymentListItem from './PaymentListItem';
import { withLoader } from '../../reusable-components';

const ProductInfoContainer = styled.div`
  width: 100%;
  background-color: ${COLORS.PALE_GRAY_TWO};
  & > div {
    margin: 10px;
  }
`;

const MarginedBiColorContainer = styled(BiColorContainer)`
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  & > div {
    margin-bottom: 10px;
  }
`;

class ProductPriceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promissoryNoteTypes: [],
      openMakePayment: false,
      totalADP: 0,
      totalFinanced: 0,
      contractId: this.props.context.contract.contractId,
      paymentFound: {},
      paymentTypes: [],
      conceptsToPay: []
    };
  }

  async componentDidMount() {
    const promissoryNoteTypes = await ContractService.getPromissoryNoteTypes();
    const paymentTypes = await PaymentService.getPaymentTypes();
    this.setState({ paymentTypes, promissoryNoteTypes });
    const payments = await this.getPayments({
      entityType: 'CONTRACT',
      statusName: 'APPROVED',
      entityId: this.props.context.contract.contractId
    });
    this.props.context.handlePropertyChange('payments', payments);
  }

  findPayment = async (criteria, callback) => {
    try {
      if (criteria.length < 3) {
        callback([]);
        return;
      }
      const paymentsAdded = this.props.context.contract.payments || {};
      const payments = await PaymentService.autocomplete({
        entityType: 'OTHER',
        entityId: -1,
        statusName: 'APPROVED',
        reference: criteria,
        fullName: criteria
      });
      const paymentsFiltered = payments.filter(p => paymentsAdded.some(pa => pa.paymentId === p.paymentId) === false);
      callback(paymentsFiltered);
    } catch (e) {
      callback([]);
    }
  };

  onPaymenSelect = async p => {
    await PaymentService.link(p.paymentId, {
      entityType: 'CONTRACT',
      entityId: this.props.context.contract.contractId
    });
    const { payments = [] } = this.props.context.contract;
    payments.push(p);
    this.props.context.handlePropertyChange('payments', payments);
  };

  removePayment = async p => {
    await PaymentService.link(p.paymentId, { entityType: 'OTHER', entityId: -1 });
    const payments = this.props.context.contract.payments.filter(payment => payment.paymentId !== p.paymentId);
    this.props.context.handlePropertyChange('payments', payments);
  };

  getPayments = query => {
    return PaymentService.autocomplete(query);
  };

  makePayment = () => {
    this.setState({ openMakePayment: true });
  };

  close = () => {
    this.setState({ openMakePayment: false });
  };

  onPay = async newPayment => {
    if (newPayment.paymentId && newPayment.status && newPayment.status.acronym === 'APPROVED') {
      const { payments = [] } = this.props.context.contract;
      payments.push(newPayment);
      await this.props.context.handlePropertyChange('payments', payments);
      this.setState({ conceptsToPay: [], openMakePayment: false });

      Toast.success(`${translate.t('referenceNumber')}: ${newPayment.reference}`, { autoClose: false });
    } else {
      Toast.error(`${translate.t('declined')} ${newPayment.reference || ''}`);
    }
  };

  closeMakePayment = async p => {
    if (p.paymentId) {
      const { payments = [] } = this.props.context.contract;
      payments.push(p);
      this.props.context.handlePropertyChange('payments', payments);
    }
    this.setState({ conceptsToPay: [], openMakePayment: false });
  };

  onConceptsChange = values => {
    const conceptsToPay = values.map(v => {
      return {
        amount: v.amount,
        discount: 0,
        name: v.name,
        entityType: v.acronym || v.entityType,
        paymentTypeId: v.paymentTypeId,
        entityId: this.props.context.contract.contractId
      };
    });
    this.setState({
      conceptsToPay
    });
  };

  getAmountToPay = concept => {
    const { context = {} } = this.props;
    const { contract = {} } = context;
    if (concept === 'CCOST') {
      return contract.closingCost || 0;
    } else if (concept === 'DP') {
      return contract.requiredDownpayment || 0;
    }
  };

  render() {
    const { context = {} } = this.props;
    const { contract = {} } = context;
    const { promissoryNotes = [], totalADP, totalFinanced, payments = [] } = contract;

    const { promissoryNoteTypes, paymentFound, paymentTypes = [], conceptsToPay = [] } = this.state;
    const paymentDetails = payments.reduce((acc, elem) => {
      return acc.concat(elem.paymentDetails);
    }, []);
    const paymentTypesFiltered = paymentTypes
      .map(pt => {
        const paidAmount = paymentDetails
          .filter(pd => pd.paymentTypeId === pt.paymentTypeId)
          .reduce((acc, pd) => acc + parseFloat(pd.amount), 0);
        return { ...pt, paidAmount, amount: this.getAmountToPay(pt.acronym) - paidAmount };
      })
      .filter(pt => pt.amount > 0);
    const adpLeft = totalADP - promissoryNotes.reduce((acc, p) => parseFloat(p.amount || 0) + acc, 0);
    const totalClosingCostPaid = paymentDetails.reduce(
      (acc, pd) => (pd.paymentType.acronym === 'CCOST' ? acc + parseFloat(pd.amount) : acc),
      0
    );
    const totalDPPaid = paymentDetails.reduce(
      (acc, pd) => (pd.paymentType.acronym === 'DP' ? acc + parseFloat(pd.amount) : acc),
      0
    );

    const isCCPaid = totalClosingCostPaid >= parseFloat(contract.closingCost || 0);
    const isDPPaid = totalDPPaid >= parseFloat(contract.requiredDownpayment || 0);

    return (
      <Fragment>
        <ProductInfoContainer className="pt-1">
          <ProductForm context={context} />
        </ProductInfoContainer>
        <PriceForm contract={context.contract} handlePropertyChange={context.handlePropertyChange} />
        <div>
          <Subtitle style={{ marginTop: '10px' }}>
            <Header>
              <i className="fa fa-angle-right" />
              {` ${translate.t('payments')}`}
            </Header>
            <Total>
              <Label>{translate.t('total')}</Label>
              <p>{translate.l('currency', payments.reduce((acc, p) => parseFloat(p.amount) + acc, 0))}</p>
            </Total>
          </Subtitle>
          <div className="mt-3 row mx-0">
            {payments.map((payment, index) => (
              <PaymentListItem
                key={`PAY-${payment.paymentId}`}
                payment={payment}
                index={index}
                onClose={this.removePayment}
              />
            ))}
          </div>
          {(!isCCPaid || !isDPPaid) && (
            <SubContainer className="row" style={{ alignItems: 'center', fontFamily: 'exo-regular' }}>
              <div className="col-md-4">
                <Select
                  isMulti={false}
                  getOptionValue={p => p.paymentId}
                  getOptionLabel={p =>
                    p.paymentId
                      ? `${p.reference} | ${p.fullName} | ${translate.l('currency', p.amount)}`
                      : translate.t('paymentLookup')
                  }
                  defaultValue={paymentFound}
                  value={paymentFound}
                  placeholder={translate.t('paymentLookup')}
                  onChange={p => {
                    this.onPaymenSelect(p);
                  }}
                  loadOptions={this.findPayment}
                />
              </div>
              <div className="col-auto py-2" style={{ textAlign: 'center' }}>
                <span>{translate.t('or')}</span>
              </div>
              <div className="col-md-4">
                <Select
                  isMulti
                  options={paymentTypesFiltered}
                  getOptionValue={p => p.paymentTypeId}
                  value={conceptsToPay}
                  getOptionLabel={p => p.name + ' ' + translate.l('currency', p.amount)}
                  placeholder={translate.t('conceptsToPay')}
                  onChange={values => {
                    this.onConceptsChange(values);
                  }}
                />
              </div>

              <MakePaymentForm
                show={this.state.openMakePayment}
                close={this.close}
                onPay={this.onPay}
                contract={context.contract}
                details={conceptsToPay}
              />

              <div className="col-auto py-2">
                <Button
                  style={{ opacity: 0.5, marginLeft: 0 }}
                  backgroundColor={COLORS.MINT_GREEN}
                  textColor={COLORS.CHARCOAL_GRAY}
                  type="button"
                  onClick={this.makePayment}
                >
                  {`${translate.t('pay')}`}
                </Button>
              </div>
            </SubContainer>
          )}
        </div>
        {totalADP > 0 && (
          <div>
            <Subtitle style={{ marginTop: '10px' }}>
              <Header>
                <i className="fa fa-angle-right" />
                {` ${translate.t('adp')}`}
              </Header>
              <Total>
                <Label>{translate.t('total')}</Label>
                <p style={{ textAlign: 'end' }}>{translate.l('currency', totalADP)}</p>
                <Label>
                  {translate.t('awaitingDistribution') + ': '}
                  <span style={{ fontSize: 14, color: 'red' }}>
                    {translate.l('currency', adpLeft < 0 ? 0 : adpLeft)}
                  </span>
                </Label>
              </Total>
            </Subtitle>
            <SubContainer>
              <MarginedBiColorContainer>
                {promissoryNotes.map((promissoryNote, index) => {
                  return (
                    <div className="row pr-3 pt-2" key={promissoryNote.uiid || promissoryNote.promissoryNoteId}>
                      <div className="col-11">
                        <PromissoryNoteForm
                          promissoryNote={{ ...promissoryNote, ADPLeft: adpLeft }}
                          promissoryNoteTypes={promissoryNoteTypes}
                          index={index}
                          handleArrayChange={context.handleArrayChange}
                        />
                      </div>
                      <RemoveButtonContainer className="col-1 m-0 px-0">
                        <RemoveButton
                          className="fa fa-trash"
                          onClick={() => context.removeItem('promissoryNotes', index)}
                          type="button"
                        />
                      </RemoveButtonContainer>
                    </div>
                  );
                })}
              </MarginedBiColorContainer>

              {adpLeft > 0 && (
                <Button
                  style={{ opacity: 0.5 }}
                  backgroundColor={COLORS.MINT_GREEN}
                  textColor={COLORS.CHARCOAL_GRAY}
                  type="button"
                  onClick={() => context.addItem('promissoryNotes')}
                >
                  {`${translate.t('add')} ${translate.t('adp')}`}
                </Button>
              )}
            </SubContainer>
          </div>
        )}

        {totalFinanced > 0 && (
          <div>
            <Subtitle style={{ marginTop: '10px' }}>
              <Header>
                <i className="fa fa-angle-right" />
                {` ${translate.t('financing')}`}
              </Header>
              <Total>
                <Label>{translate.t('total')}</Label>
                <p>{translate.l('currency', totalFinanced)}</p>
              </Total>
            </Subtitle>
            <SubContainer>
              <LoanForm handlePropertyChange={context.handlePropertyChange} contract={context.contract} />
            </SubContainer>
          </div>
        )}
      </Fragment>
    );
  }
}
export default withLoader(ProductPriceForm);
