import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

const Text = styled.span`
  ${props => props.selected && `color: ${COLORS.BURPLE}`};
`;

const TableHeader = styled.th`
  font-size: 18px;
  font-family: 'roboto-medium';
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  vertical-align: middle;
  color: ${COLORS.INPUT_LABEL}
  display: flex;
  justify-content: center;
  align-items: center;
 
  & > span {
    position: relative;
  }

  & > span:hover > i { 
    display: inline !important;
    cursor: pointer;
  }

  & > span:hover { 
    cursor: pointer;
  }

  & > span.selected i {
    display: inline !important;
  }

  & > span i {
    display: none;
    position: absolute;
    top: 7px;
    left: -11px;
    font-size: 10px;
  }

  @media (max-width: 767.98px) {
    ${props => (props.hideOnSmallScreens ? 'display: none;' : '')}
  }
`;

const TableRow = styled.tr`
  height: 64px;
  border: 1px solid ${COLORS.TABLE_BORDER}
  display: flex;
  justify-content: space-between;
`;

const Header = ({ headers, onSort, sort, sortField }) => {
  if (Array.isArray(headers) && headers.length) {
    return (
      <thead>
        <TableRow>
          <TableHeader width="3%" />
          {headers.map((row, index) => {
            const selected = row.sort && row.sort === sortField;
            return (
              <TableHeader width={row.size} key={index} hideOnSmallScreens={row.hideOnSmallScreens}>
                <Text onClick={onSort(row)} selected={selected} className={`${selected ? 'selected' : ''}`}>
                  {row.sort && (
                    <i
                      className={`fa fa-${
                        selected && sort === 'asc' ? 'arrow-up' : sort === 'desc' ? 'arrow-down' : 'minus'
                      }`}
                    />
                  )}
                  {row.header}
                </Text>
              </TableHeader>
            );
          })}
          <TableHeader width="3%" />
        </TableRow>
      </thead>
    );
  }
  return null;
};

Header.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object).isRequired
};

Header.defaultProps = {
  headers: []
};

export default Header;
