import ColorRequest from './colorRequest';

class CommentService {
  static async get(entityType, entityId) {
    try {
      const { data: resp } = await ColorRequest.get(`/comments/${entityType}/${entityId}`);
      return resp;
    } catch (e) {
      return e;
    }
  }

  static async create(entityType, entityId, description) {
    try {
      const { data: resp } = await ColorRequest.post(`/comments`, { entityId, entityType, description });
      return resp;
    } catch (e) {
      return e;
    }
  }
}

export default CommentService;
