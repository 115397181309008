import React from 'react';
import PropTypes from 'prop-types';
import helpers from '../Helpers';
import styled from 'styled-components';
import { COLORS } from '../../vars/palette';

const TableRow = styled.tr`
  height: 64px;
  &:nth-child(even) {
    background-color: ${COLORS.PALE_GRAY_TWO};
  }
  ${props => (props.selected ? `background-color: #e8f7ff !important` : '&:focus { outline: none }')};
`;

const TableCell = styled.td`
  font-family: 'exo-regular';
  font-size: 14px;
  color: ${COLORS.INPUT_LABEL}
  vertical-align: middle;
  text-align: center;

  ${props =>
    props.maxWidth
      ? `
    max-width: ${props.maxWidth}px;
    overflow: hidden;
    text-overflow: ellipsis;`
      : `max-width: ${props.width}`}

  @media (max-width: 767.98px) {
    ${props => (props.hideOnSmallScreens ? 'display: none;' : '')}
  }
`;

const CheckDot = styled.span`
  width: 18px;
  height: 18px;
  mix-blend-mode: multiply;
  border: 1px solid ${props => (props.selected ? COLORS.AQUA_BLUE : COLORS.TABLE_CHECK_DOT_BORDER)};
  background-color: ${props => (props.selected ? COLORS.AQUA_BLUE : COLORS.TABLE_CHECK_DOT_FILL)};
  cursor: pointer;
  display: inline-block;
`;

const ThreeDottedMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const MenuDot = styled.span`
  width: 6px;
  height: 6px;
  display: inline-block;
  background-color: ${COLORS.TABLE_THREE_DOT_MENU_FILL};
  margin-bottom: 6px;
  border-radius: 50%;
`;

const Body = ({
  columns,
  data = [],
  onRowKeyUp,
  onRowClick,
  onRowDoubleClick,
  onMouseDown,
  selected,
  showRowCheck,
  showRowMenu
}) => {
  if (!columns.length) {
    throw new Error("Can't resolve columns, please add the Table column definition file");
  }

  return data.map((item, index) => {
    const isMobile = window.innerWidth < 768;
    return (
      <TableRow
        selected={selected === index}
        key={index}
        onDoubleClick={e =>
          onRowDoubleClick ? (isMobile && onRowClick ? onRowClick(e, item, index) : onRowDoubleClick(item, index)) : {}
        }
        onKeyUp={e => (onRowKeyUp ? onRowKeyUp(e, item, index) : {})}
        onClick={e => (onRowClick ? onRowClick(e, item, index) : {})}
        onMouseDown={e => (onMouseDown ? onMouseDown(e, item, index) : {})}
        tabIndex="0"
      >
        <TableCell width="3%">{showRowCheck && <CheckDot selected={selected === index} />}</TableCell>
        {columns.map((row, index) => {
          const value = helpers.setTableRowValue(item, row);
          return row.column !== undefined ? (
            <TableCell
              width={row.size}
              maxWidth={row.maxWidth}
              key={index}
              title={value}
              hideOnSmallScreens={row.hideOnSmallScreens}
            >
              {value}
            </TableCell>
          ) : (
            row.column === '' && <td />
          );
        })}
        <TableCell width="3%">
          {showRowMenu && (
            <ThreeDottedMenu>
              <MenuDot />
              <MenuDot />
              <MenuDot />
            </ThreeDottedMenu>
          )}
        </TableCell>
      </TableRow>
    );
  });
};

Body.defaultProps = {
  columns: '',
  data: []
};

Body.propTypes = {
  columns: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  maxWidth: PropTypes.number
};

export default Body;
