import React from 'react';
import CreditCardInput from './CreditCardInput';
import { TextInput } from '../../';
import { COLORS } from '../../vars/palette';
import i18n from '../../../translate';
import styled from 'styled-components';
import { PaymentContext } from '../context';

const Group = styled.div`
  padding: 5px;
`;
const CapitalizedInput = styled(TextInput)`
  text-transform: capitalize;
`;

class CreditCardForm extends React.PureComponent {
  state = {
    cardNumber: '',
    cardType: '',
    expirationMonth: '',
    expirationYear: '',
    cvc: '',
    valid: false,
    cardHolderName: ''
  };

  onCardChange = card => {
    this.setState({ ...card }, () => this.setCreditCardPaymentDetails());
  };

  onCardHolderNameChanged = e => {
    const {
      target: { value: cardHolderName }
    } = e;
    this.setState(
      {
        cardHolderName
      },
      () => this.setCreditCardPaymentDetails()
    );
  };

  setCreditCardPaymentDetails = () => {
    const { cardNumber, cardType, cardHolderName, cvc, expirationMonth, expirationYear } = this.state;
    const creditCard = {
      cardNumber,
      cardType,
      cardHolderName,
      cvc,
      expirationMonth,
      expirationYear
    };

    this.props.context.setPaymentMethodDetail('creditCard', creditCard);
  };

  render() {
    return (
      <div className="col-12 px-1 px-md-3" style={{ backgroundColor: COLORS.PALE_GRAY_TWO }}>
        <div className="row">
          <Group className="col-12 px-0 px-md-3">
            <CapitalizedInput
              label={i18n.t('cardHolderName')}
              onChange={this.onCardHolderNameChanged}
              value={this.state.cardHolderName}
            />
          </Group>
          <Group className="col-12 px-0 px-md-3">
            <CreditCardInput onChange={this.onCardChange} />
          </Group>
        </div>
      </div>
    );
  }
}

export default props => (
  <PaymentContext.Consumer>{context => <CreditCardForm {...props} context={context} />}</PaymentContext.Consumer>
);
