import ColorRequest from './colorRequest';
import moment from 'moment';

class InvitationService {
  static async prospectsByRoom(roomNumber) {
    try {
      const { data: resp } = await ColorRequest.post('/welcomes/prospects/autocomplete', {
        roomNumber,
        date: moment().format('YYYY-MM-DD')
      });
      return resp;
    } catch (e) {
      return e;
    }
  }
}

export default InvitationService;
