import React from 'react'
import { InputWrapper, Input} from './base'

 class NumberInput extends React.Component {
  onChange = (e) => {
    const { onChange } = this.props
    if(onChange){
      e.target.validity.valid && onChange(e)
    }
  }
  render() {
    return (
      <Input
        type="number"
        step={0.01}
        min={0}
        pattern="^0*[1-9]\d*$"
        placeholder='0.00'
        style={{ textAlign: 'right' }}
        {...this.props}
        onFocus={(e) => e.target.select()}
        onChange={this.onChange} />
    )
  }
}


export default InputWrapper(NumberInput)
