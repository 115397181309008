import React, { Component, Fragment } from 'react';
import AsyncSelect from 'react-select/lib/Async';
import Select from 'react-select';

export default class ASelect extends Component {
  onChange = o => {
    this.props.onChange(o || {}, this.select);
  };

  render() {
    const props = this.props;
    const value = props.isMulti
      ? (props.defaultValue || []).length
      : props.getOptionValue(props.defaultValue || props.value || {});
    const ReactSelect = props.loadOptions ? AsyncSelect : Select;
    return (
      <Fragment>
        <div className="async-select-container">
          <span className="label-container">{props.placeholder || ''}</span>
          <ReactSelect
            className="async-select"
            styles={{
              control: (base, state) => ({
                ...base,
                border: state.isFocused ? 0 : 0,
                // This line disable the blue border
                boxShadow: state.isFocused ? 0 : 0,
                '&:hover': {
                  border: state.isFocused ? 0 : 0
                }
              })
            }}
            {...props}
            onChange={this.onChange}
            ref={ref => (this.select = ref)}
          />
          <input
            tabIndex={-1}
            autoComplete={props.autoComplete}
            value={value ? value : ''}
            required={props.required}
            onChange={this.onChange}
            style={{
              opacity: 0,
              width: 1,
              position: 'absolute',
              marginTop: -19
            }}
            onFocus={() => this.select.focus()}
          />
        </div>
      </Fragment>
    );
  }
}
