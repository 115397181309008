import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import Loading from 'react-loading';
import { COLORS } from '../vars/palette';

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  outline: 0;
  overflow-y: auto;
  background-color: rgba(255, 255, 255, .5);
`;

const CenteredDiv = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const withLoader = (WrappedComponent) => {
  return class extends Component {

    constructor(props) {
      super(props);
      this.state = {
        loading: true
      }
    }

    onRequestEnd = (e) => {
      if (!e.detail.pendingRequests.length) {
        this.setState({ loading: false });
      }
    }

    componentDidMount() {
      window.addEventListener('x-custom-load', this.onRequestEnd);
    }

    componenWillUnmount() {
      window.removeListener('x-custom-load', this.onRequestEnd);
    }

    render() {
      const { loading } = this.state;
      const { loadingType = 'bars' } = this.props;
      return (
        <Fragment>
          <WrappedComponent {...this.props} />
          { loading && 
              <Backdrop> 
                <CenteredDiv className="row justify-content-center align-items-center">
                  <Loading type={loadingType} color={COLORS.AQUA_BLUE}  />
                </CenteredDiv>
              </Backdrop>
          }
        </Fragment>
      );
    }
  }
 
}

export default withLoader;