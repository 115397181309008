import { COLORS } from './vars/palette';
import styled from 'styled-components';

export const Hr = styled.div`
  border-top: 1px solid ${COLORS.PALE_GRAY};
  color: ${COLORS.PALE_GRAY};
  margin: 20px 20px 20px 0px;
`;

export const Label = styled.span`
  color: #7a838f;
  font-family: 'exo-light';
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
`;

export const Paragraph = styled.p`
  color: #4a4a4a;
  font-family: 'exo-semibold';
  font-size: 14px;
  font-weight: 500;
`;

export const Total = styled.div`
  display: inline-block;
  float: right;
  & > p {
    font-size: 36px;
    font-family: 'exo-semibold';
  }
`;

export const Header = styled.div`
  display: inline-block;
  float: left;
  padding: 35px 0 0 0;
`;

export const Subtitle = styled.div`
  font-family: 'roboto-regular';
  font-size: 20px;
  margin-top: 45px;
  padding-bottom: 10px;
  border-bottom: 1px solid ${COLORS.PALE_GRAY};
  overflow: auto;
`;

export const BiColorContainer = styled.div`
  & > div:nth-child(even) {
    background-color: ${COLORS.PALE_GRAY_TWO};
    padding-left: 5px;
    margin-right: 10px;
  }
  & > div:nth-child(odd) {
    background-color: white;
    padding-left: 5px;
    margin-right: 10px;
  }
`;

export const RemoveButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 20px;
  color: #ef5350;
  margin: 5px;
`;

export const RemoveButton = styled.i`
  cursor: pointer;
`;

export const SubContainer = styled.div`
  margin-top: 20px;
`;

export const BorderedDiv = styled.div`
  border: 1px solid #c7c7c7;

  &.no-border-left {
    border-left: none;
  }

  &.no-border-top {
    border-top: none;
  }

  & .regular-paragraph {
    color: #394a51;
    font-family: 'exo-regular';
    font-size: 16px;
  }
`;

export const InfoButton = styled.i`
  cursor: pointer;
  color: ${COLORS.INFO};
  font-size: 24px !important;

  &:hover {
    opacity: 0.7;
  }
`;

export const Badge = styled.span`
  display: inline-block;
  padding: 3px;
  border-radius: 2px;
  background-color: #f2f2f2;
  margin: 2px;
  font-family: 'exo-regular';
  color: #3e3e3e;
  text-overflow: ellipsis;
`;

export const SpanLink = styled.span`
  color: ${COLORS.AQUA_BLUE};
  text-decoration: none;
  font-family: 'exo-light';
  cursor: pointer;
`;

export const Legend = styled.div`
  & > fieldset {
    border: 2px solid ${COLORS.PALE_GRAY};
  }

  & > fieldset > legend {
    margin: 0 0 0 15px;
    color: ${COLORS.INPUT_LABEL};
    font-family: 'exo-light';
    font-size: 11px;
    font-weight: 400;
    padding: 0 10px;
  }
`;
