import React from 'react'
import { COLORS } from '../../vars/palette'
import { TextInput, DatePicker } from '../../'
import i18n from '../../../translate'
import styled from 'styled-components'
import { PaymentContext } from '../context'
import moment from 'moment';

const Group = styled.div`
  padding-bottom: 10px

  &.left {
    padding-right: 2.5px
  }

  &.right {
    padding-left: 2.5px
  }
`

const GridForm = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr)
`

const CapitalizedInput = styled(TextInput)`
  text-transform: capitalize
`



class WireTransferForm extends React.Component {


  state = {
    senderName: '',
    issuingBank: '',
    receivingAccount: '',
    sendDate: '',
    folioNumber: '',
    receivingBank: '',
  }

  handleChanges = (e) => {
    const { target: { name, value } } = e
    this.handlePropChange(name, value)
  }

  handlePropChange = (name, value) => {
    this.setState({
      [name] : value
    }, () => this.setCashPaymentDetails())
  }

  setCashPaymentDetails = () => {
    const { senderName, folioNumber, issuingBank, receivingAccount, receivingBank, sendDate }  = this.state;

    this.props.context.setPaymentMethodDetail('wireTransferPaymentDetail', { senderName, folioNumber, issuingBank, receivingAccount, receivingBank, sendDate });

  }

  render() {
    return (
      <div className='col-12' style={{ backgroundColor: COLORS.PALE_GRAY_TWO }}>
        <form>
          <GridForm>
            <Group className='left'>
              <CapitalizedInput label={i18n.t('senderName')} name='senderName' value={this.state.senderName} onChange={this.handleChanges} />
            </Group>
            <Group className='right'>
              <TextInput label={i18n.t('issuingBank')} name='issuingBank' value={this.state.issuingBank} onChange={this.handleChanges} />
            </Group>
            <Group className='left'>
              <TextInput label={i18n.t('receivingAccount')} name='receivingAccount' value={this.state.receivingAccount} onChange={this.handleChanges} />
            </Group>
            <Group className='right'>
              <DatePicker label={i18n.t('sendDate')} name='sendDate' 
               onSelect={date => this.setState({ sendDate: moment(date).format('YYYY-MM-DD') }, () => this.setCashPaymentDetails())}
               value={this.state.sendDate}
              />
            </Group>
            <Group className='left' style={{paddingBottom: 5}}>
              <TextInput label={i18n.t('folioNumber')} name='folioNumber' value={this.state.folioNumber} onChange={this.handleChanges} />
            </Group>
            <Group className='right' style={{paddingBottom: 5}}>
              <TextInput label={i18n.t('receivingBank')} name='receivingBank' value={this.state.receivingBank} onChange={this.handleChanges} />
            </Group>
          </GridForm>
        </form>
      </div>
    )
  }
}

export default (props) => (
  <PaymentContext.Consumer>
    {
      context => <WireTransferForm {...props} context={context} />
    }
  </PaymentContext.Consumer>
)