import React, { Fragment, Component } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { COLORS } from '../../reusable-components/vars/palette';
import translate from '../../translate';
import ReactToPrint from 'react-to-print';

const Container = styled.div`
  background-color: #edf2f6;
  margin: 25px;
`;

const PaymentTitle = styled.div`
  & > div {
    flex: 1;
  }
  font-family: 'exo-extralight';
  font-size: 36px;
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${COLORS.HEADER_COLOR};
  display: flex;
`;

const ConfirmationNumber = styled.div`
  font-family: 'exo-semibold';
  font-size: 36px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #394a51;
`;

const CloseButton = styled.span`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  font-size: 22px;
  text-align: end;
  cursor: pointer;
`;

const Details = styled.div`
  font-family: 'exo-regular';
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 30px;
`;

const Prop = styled.div`
  font-size: 20px;
  color: #394a51;
  margin: 2px;
`;
const PropValue = styled.div`
  font-size: 14px;
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  margin: 2px;
  margin-bottom: 20px;
`;

const Detail = styled.div`
  background-color: #ffffff;
  font-family: 'exo-regular';
  margin-top: 24px;
  padding: 15px;
`;
const DetailHeader = styled.div`
  color: ${COLORS.TABLE_CHECK_DOT_BORDER};
  font-size: 13px;
  padding-top: 5px;
  display: flex;
  justify-content: space-between;
`;

const EMPTY = '-----';
class PaymentQuickView extends Component {
  state = { printing: false };
  render() {
    const { onClose, payment, onCancelClick, onRefundClick } = this.props;
    const { details = [] } = payment;
    return (
      <Fragment>
        <Container>
          <PaymentTitle>
            <div />
            <CloseButton onClick={onClose}>X</CloseButton>
          </PaymentTitle>
        </Container>
        <Container ref={el => (this.componentRef = el)} style={{ padding: 20 }}>
          <PaymentTitle>
            <div>{translate.t('payment')}</div>
          </PaymentTitle>
          <ConfirmationNumber>
            {payment.authorization} - {translate.l('dateFormats.date', moment(payment.paymentDate).toDate())}
          </ConfirmationNumber>
          <ConfirmationNumber style={{ fontSize: '24px' }}> {payment.status.name}</ConfirmationNumber>
          <DetailHeader>{payment.transaction || EMPTY}</DetailHeader>

          <Details>
            <div>
              <Prop>{payment.clientType.name}</Prop>
              <PropValue>{translate.t('clientType')}</PropValue>
            </div>
            <div>
              <Prop>{moment(payment.date).format('DD-MM-YYYY HH:mm a')}</Prop>
              <PropValue>{translate.t('date')}</PropValue>
            </div>
            <div>
              <Prop>{payment.fullName}</Prop>
              <PropValue>{translate.t('fullName')}</PropValue>
            </div>
            <div>
              <Prop>{payment.identificationNumber}</Prop>
              <PropValue>{translate.t('idNo')}</PropValue>
            </div>
            {/* <div>
              <Prop>{payment.reference}</Prop>
              <PropValue>{translate.t('reference')}</PropValue>
            </div> */}
            <div>
              <Prop style={{ fontSize: 16 }}>{payment.creditCard || EMPTY}</Prop>
              <PropValue>{translate.t('creditCard')}</PropValue>
            </div>
            <div>
              <Prop>{payment.authorization || EMPTY}</Prop>
              <PropValue>{translate.t('confirmationNumber')}</PropValue>
            </div>

            {payment.cancellationReference && (
              <div>
                <Prop>{payment.cancellationReference || EMPTY}</Prop>
                <PropValue>{translate.t('cancellationReference')}</PropValue>
              </div>
            )}
            {payment.refundReference && (
              <div>
                <Prop>{payment.refundReference || EMPTY}</Prop>
                <PropValue>{translate.t('refundReference')}</PropValue>
              </div>
            )}

            {/* <div>
              <Prop>{payment.paymentMethod.name}</Prop>
              <PropValue>{translate.t('paymentMethod')}</PropValue>
            </div> */}
          </Details>
          <Detail>
            <div style={{ border: `1px ${COLORS.PALE_GRAY} solid`, padding: '15px' }}>
              <DetailHeader>
                <div>{translate.t('concept')}</div>
                <div>{translate.t('description')}</div>
                <div>{translate.t('amount')}</div>
              </DetailHeader>
              {details.map(p => {
                return (
                  <div key={p.id} style={{ marginTop: 25, display: 'flex', justifyContent: 'space-between' }}>
                    <div>{p.productType.name}</div>
                    <div>{p.description}</div>
                    <div>{translate.l('currency', p.amount)}</div>
                  </div>
                );
              })}
              <div
                style={{
                  marginTop: 25,
                  padding: 5,
                  display: 'flex',
                  justifyContent: 'space-between',
                  borderTop: `1px solid ${COLORS.PALE_GRAY}`
                }}
              >
                <div>{translate.t('total')}</div>
                <div>{translate.l('currency', payment.total)}</div>
              </div>
            </div>
          </Detail>
        </Container>
        <Container>
          <Container
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
              fontWeight: 'bolder',
              padding: 20
            }}
          >
            <div style={{ flex: 1, textAlign: 'center' }}>
              <ReactToPrint
                trigger={() => {
                  return <a href="#">{translate.t('print')}</a>;
                }}
                content={() => this.componentRef}
              />
            </div>
            {payment.status.acronym === 'PAYMENT_SUCCESSFUL' && (
              <div style={{ flex: 1, textAlign: 'center' }}>
                <a style={{ color: COLORS.WARNING }} onClick={onRefundClick} href="#">
                  {translate.t('refund')}
                </a>
              </div>
            )}
            {payment.status.acronym === 'PAYMENT_SUCCESSFUL' && (
              <div style={{ flex: 1, textAlign: 'center' }}>
                <a style={{ color: COLORS.CORAL }} onClick={onCancelClick} href="#">
                  {translate.t('cancel')}
                </a>
              </div>
            )}
          </Container>
        </Container>
      </Fragment>
    );
  }
}

export default PaymentQuickView;
